import React from 'react';
import MainLayout from '../../layout/MainLayout';

export default () => {
    return (
        <MainLayout>
            <h1>Help</h1>
        </MainLayout>
    );
};

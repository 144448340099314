import { getItem, setItem } from '../../utils/localStorage';
import {
    APP_KEY,
    TOGGLE_INTERNATIONALIZATION,
    TOGGLE_LEFT_SIDE_DRAWER,
    CHANGE_LANGUAGE,
    SHOW_INFO,
    ActionType,
    HARMONIZING,
} from './types';

const storedAppState = getItem(APP_KEY) || {};
const initialState = {
    drawerIsOpen: false,
    internationalizationMenuIsOpen: false,
    language: 'en',
    isShowingInfo: false,
    error: {},
    harmonizing: false,
    ...storedAppState,
};

export default function HeaderReducer(state = initialState, action: ActionType) {
    let localState;
    switch (action.type) {
        case HARMONIZING:
            localState = {
                ...state,
                harmonizing: action.state,
            };
            break;
        case TOGGLE_INTERNATIONALIZATION:
            localState = {
                ...state,
                internationalizationMenuIsOpen: !action.state,
            };
            break;
        case TOGGLE_LEFT_SIDE_DRAWER:
            localState = {
                ...state,
                drawerIsOpen: !action.state,
            };
            break;
        case CHANGE_LANGUAGE:
            localState = {
                ...state,
                language: action.language,
            };
            break;
        case SHOW_INFO:
            localState = {
                ...state,
                isShowingInfo: action.state === false ? false : !state.isShowingInfo,
            };
            break;
        default:
            localState = state;
            break;
    }
    setItem(APP_KEY, localState);
    return localState;
}

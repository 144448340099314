export const TOGGLE_INTERNATIONALIZATION = 'TOGGLE_INTERNATIONALIZATION_BUTTON';
export const TOGGLE_LEFT_SIDE_DRAWER = 'TOGGLE_LEFT_SIDE_DRAWER';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SHOW_INFO = 'SHOW_INFO';
export const APP_KEY = 'APP_KEY';
export const HARMONIZING = 'HARMONIZING';

export interface ActionType {
    type: string;
    state: boolean;
    language: string;
}

export interface ErrorType {
    code?: number;
    message?: string;
}

export interface LanguageType {
    key: string;
    flag: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BILLING_FLOW_STATUS: { [key: string]: any } = {
    PAYMENT: {
        paid: 'PAID',
        unpaid: 'UNPAID',
        fail: 'FAIL',
    },
    SUBSCRIPTION: {
        pending: 'PENDING',
        active: 'PAID',
        unpaid: 'UNPAID',
        canceled: 'CANCELED',
        fail: 'UNPAID',
    },
    CODE: {
        active: 'ACTIVE',
        inactive: 'INACTIVE',
    },
};

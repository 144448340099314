import {
    GET_PRODUCT,
    GET_PRODUCT_SUCCEEDED,
    GET_PRODUCT_FAILED,
    ErrorType,
    ProductType,
    ProductsType,
    LIST_PRODUCT_SUCCEEDED,
    LIST_PRODUCT_FAILED,
    LIST_PRODUCT,
    LOADING_PRODUCT,
} from './types';

export const getProductAction = () => ({
    type: GET_PRODUCT,
});

export const getProductSuccessAction = (payload: { product: ProductType }) => ({
    type: GET_PRODUCT_SUCCEEDED,
    payload,
});

export const getProductFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_PRODUCT_FAILED,
    payload,
});

export const listProductAction = () => ({
    type: LIST_PRODUCT,
});

export const listProductSuccessAction = (payload: ProductsType) => ({
    type: LIST_PRODUCT_SUCCEEDED,
    payload,
});

export const listProductFailedAction = (payload: { error: ErrorType }) => ({
    type: LIST_PRODUCT_FAILED,
    payload,
});

export const productLoadingAction = (payload: boolean) => ({
    type: LOADING_PRODUCT,
    payload,
});

import { UserType } from '../user/types';
import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_SUCCEEDED,
    GET_SUBSCRIPTION_FAILED,
    ErrorType,
    SubscriptionType,
    LOADING_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCEEDED,
    CANCEL_SUBSCRIPTION_FAILED,
    CREATE_SUBSCRIPTION,
    CREATE_SUBSCRIPTION_FAILED,
    CREATE_SUBSCRIPTION_SUCCEEDED,
    UPGRADE_SUBSCRIPTION_SUCCEEDED,
    UPGRADE_SUBSCRIPTION_FAILED,
    UPGRADE_SUBSCRIPTION,
    PAUSE_SUBSCRIPTION_SUCCEEDED,
    PAUSE_SUBSCRIPTION_FAILED,
    PAUSE_SUBSCRIPTION,
} from './types';

export const getSubscriptionAction = (payload: {
    subscriptionID?: string;
    customerID?: string;
}) => ({
    type: GET_SUBSCRIPTION,
    payload,
});

export const getSubscriptionSuccessAction = (payload: { subscription: SubscriptionType }) => ({
    type: GET_SUBSCRIPTION_SUCCEEDED,
    payload,
});

export const getSubscriptionFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_SUBSCRIPTION_FAILED,
    payload,
});

export const subscriptionLoadingAction = (payload: boolean) => ({
    type: LOADING_SUBSCRIPTION,
    payload,
});

export const cancelSubscriptionAction = (payload: { subscriptionID: string }) => ({
    type: CANCEL_SUBSCRIPTION,
    payload,
});

export const cancelSubscriptionSuccessAction = () => ({
    type: CANCEL_SUBSCRIPTION_SUCCEEDED,
});

export const cancelSubscriptionFailedAction = (payload: { error: ErrorType }) => ({
    type: CANCEL_SUBSCRIPTION_FAILED,
    payload,
});

export const createSubscriptionSuccessAction = (payload: { subscription: SubscriptionType }) => ({
    type: CREATE_SUBSCRIPTION_SUCCEEDED,
    payload,
});

export const createSubscriptionFailedAction = (payload: { error: ErrorType }) => ({
    type: CREATE_SUBSCRIPTION_FAILED,
    payload,
});

export const createSubscriptionAction = (payload: {
    customerID: string;
    priceID: string;
    paymentMethodID: string;
    user: UserType;
}) => ({
    type: CREATE_SUBSCRIPTION,
    payload,
});

export const upgradeSubscriptionSuccessAction = (payload: { subscription: SubscriptionType }) => ({
    type: UPGRADE_SUBSCRIPTION_SUCCEEDED,
    payload,
});

export const upgradeSubscriptionFailedAction = (payload: { error: ErrorType }) => ({
    type: UPGRADE_SUBSCRIPTION_FAILED,
    payload,
});

export const upgradeSubscriptionAction = (payload: {
    priceID: string;
    subscriptionID: string;
    subscription: SubscriptionType;
}) => ({
    type: UPGRADE_SUBSCRIPTION,
    payload,
});

export const pauseSubscriptionSuccessAction = (payload: { subscription: SubscriptionType }) => ({
    type: PAUSE_SUBSCRIPTION_SUCCEEDED,
    payload,
});

export const pauseSubscriptionFailedAction = (payload: { error: ErrorType }) => ({
    type: PAUSE_SUBSCRIPTION_FAILED,
    payload,
});

export const pauseSubscriptionAction = (payload: { subscriptionID: string }) => ({
    type: PAUSE_SUBSCRIPTION,
    payload,
});

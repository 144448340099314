import { API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';

API.configure(awsexports);

const apiName = 'reCaptcha';
const path = '/';
const myInit: {
    headers: string;
    response: true;
    queryStringParameters: { sub?: string };
    body: { token: string };
} = {
    headers: '',
    response: true,
    queryStringParameters: {},
    body: { token: '' },
};

export default async function validateGoogleToken(token: string) {
    try {
        myInit.headers = await getHeader(myInit.headers);
        myInit.body = { token };
        // eslint-disable-next-line no-console
        const response = await API.post(apiName, path, myInit).catch((e) => console.warn(e));
        return response;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
        return { error };
    }
}

import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import {
    Button,
    Grid,
    makeStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme,
    TextareaAutosize,
} from '@material-ui/core';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import axios from 'axios';
import MainLayout from '../../layout/MainLayout';
import './modal.css';

import awsconfig from '../../aws-exports';
import { getItem, setItem, removeItem } from '../../utils/localStorage';
import { USER_KEY } from '../../redux/user/types';
import { ColorCircularProgress } from '../../components/Loader';
// import useOnlineDispatch from '../OnlineDispatch.js';
Storage.configure(awsconfig);

const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',

        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    wrap: {
        margin: '0px auto',
        width: '50%',
        '@media (max-width: 575px)': {
            width: '100%',
        },
    },
    nameField: {
        backgroundColor: '#0d3c61 !important',
        color: '#fff !important',
    },
    button: {
        backgroundColor: '#0468BF',
        minWidth: '302px',
        margin: '20px auto',
        display: 'block',
        color: '#fff',
        height: 'auto',
        borderRadius: '10px',
        padding: '10px 20px 10px 20px !important',
        border: '1px solid #747474',
        '& .MuiButton-label': {
            fontSize: '20px',
            fontWeight: 'bolder',
            textTransform: 'capitalize',
        },
        '&.customBtn': {
            width: '100%',
        },
        '&.customStartBtn': {
            background: '#f10',
        },
        '&:hover': {
            backgroundColor: '#0468BF',
            opacity: '.9',
        },
    },
    scanImg: {
        width: '100%',
        height: '98%',
    },
}));
function setCookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
};
function getCookie(name) {
    const cookieValue = document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith(`${name}=`));
    return cookieValue;

};

const Home = () => {

    const classes = useStyle();
    const historty = useHistory();
    const [clientImage, setClientImage] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [textValue, setTextValue] = useState('');
    const [showloader, setShowLoader] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phonenumber: '',
        dob: '',
        description: '',
        client_image: '',
    });
    const [validationErrors, setValidationErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phonenumber: '',
        dob: '',
        textValue: '',
    });

    const handleChange = (e: any) => {
        // setFormData({
        //     ...formData,
        //     [e.target.name]: e.target.value,
        // });
        // setValidationErrors({
        //     ...validationErrors,
        //     [e.target.name]: '', // Clear validation error when user types
        // });
    };

    const handleTextChange = (e: any) => {
        setTextValue(e.target.value);
        setValidationErrors({
            ...validationErrors,
            [e.target.name]: '', // Clear validation error when user types
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Handle form submission logic here
        // console.log('Form submitted:', formData);
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    // ================================= SCAN MODAL =================================

    const [open, setOpen] = useState(false);
    const [scanAnimation, setScanAnimation] = useState(false);
    const [scanImage, setScanImage] = useState<string | undefined>();
    const [openCamera, setOpenCamera] = useState(false);
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const videoRef = useRef(null);
    const handleClickOpen = () => {
        setOpen(true);
        setScanAnimation(false);
    };
    const handleClose = () => {
        setOpen(false);
        setScanAnimation(false);
    };
    const openCameraHandler = () => {
        setOpenCamera(true);
    };
    const closeCameraHandler = () => {
        setOpenCamera(false);
    };
    const user = useSelector((state: GlobalStateType) => state.user);
    if (user) {
        // user.sub = '4b78651c-2167-4589-abcd-282fd00194d7';
    }
    const [auraLen, setAuraLen] = useState(0);
    useEffect(() => {
        const getUserAvatar = async () => {
            const localUser = getItem(USER_KEY);
            try {
                const avatarList = await Storage.list(`aura/${user.sub}`);
                setAuraLen(avatarList.length);
            } catch (error) {
                // console.info('Not avatar found yet', error);
            }
        };
        getUserAvatar();
    }, [user]);
    // console.log('aura len:', auraLen);

    const fetchImages = async (itemKey) => {
        const cameraRoll = await Storage.get(itemKey);
        console.group('========:', cameraRoll)
        setCookie('ScanImage', cameraRoll, 7);
        setShowLoader(false);
    };

    const [image, setImage] = useState<any>(null);
    const uploadCanvasImage = async (canvas) => {
        setShowLoader(true);
        try {
            const dataURL = canvas.toDataURL('image/png');
            const key = `aura/${user.sub}/${auraLen}`;
            const blob = await (await fetch(dataURL)).blob();
            const { type: mimeType } = blob;
            const subdirectory = `aura/${user.sub}`;
            const imageName = `${auraLen}.jpg`;
            const fullImageName = `${subdirectory}/${imageName}`;
            setClientImage(fullImageName);
            const result = Storage.put(fullImageName, blob, {
                level: 'public',
                contentType: mimeType,
            }).then((data) => {
                console.log('Image uploaded successfully.', data.key);
                fetchImages(data.key);
            }).catch(error => {
                // console.error('Error uploading image:', error);
                setShowLoader(false);
            });
            // console.log('*********', result);
            // delay(500);
            // console.log('File uploaded successfully:');
        } catch (error) {
            // console.error('Error uploading file:', error);
        }
    };

    const captureImageHandler = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
        const capturedImage = canvas.toDataURL('image/png');
        uploadCanvasImage(canvas);
        setScanImage(capturedImage);
        setImage(capturedImage);
        closeCameraHandler();
    };

    const handleChangeFile = (e: any) => {
        setShowLoader(true);
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();
        try {
            reader.readAsDataURL(file);
            // Assuming you have a file input element in your HTML where the user selects a file
            const fileInput = document.getElementById('fileInput');
            const files = fileInput.files[0];
            const mimeType = files.type;
            const subdirectory = `aura/${user.sub}`; // replace with your desired subdirectory
            const imageName = `${auraLen}.jpg`;
            const fullImageName = `${subdirectory}/${imageName}`;
            setClientImage(fullImageName);
            Storage.put(fullImageName, file, {
                level: 'public',
                contentType: mimeType,
            }).then((data) => {
                console.log('Image uploaded successfully.', data.key);
                fetchImages(data.key);
            }).catch(error => {
                setShowLoader(false);
                // console.error('Error uploading image:', error);
            });
            // delay(500);
        } catch (err) {
            console.error(err);
        }
        reader.onloadend = () => {
            const avatar = reader.result;
            // setItem(user.sub, { ...user, avatar });
            // const localUser = getItem(USER_KEY);
            // const updatedUser = { ...localUser, ScanImage: avatar };
            // setItem(USER_KEY, updatedUser);
            setImage(avatar);
            setScanImage(avatar);
        };
    };

    useEffect(() => {
        if (openCamera) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                    }
                })
                .catch((error) => {
                    // console.error('Error accessing camera:', error);
                    closeCameraHandler();
                });
        }
    }, [openCamera]);

    const [openLoader, setOpenLoader] = React.useState(false);
    const [fname, setFname] = React.useState('');
    const [lname, setLname] = React.useState('');
    const [useremail, setUseremail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [birthdate, setBirthdate] = React.useState('');
    const [dec, setDec] = React.useState('');
    const [userData, setUserData] = useState([]);
    const [ksData, setKsData] = useState([]);
    useEffect(() => {
        // Get an item from local storage
        const localUser = getItem(USER_KEY);
        // console.log('======:', localUser.avatar)
        const fetchData = async () => {
            const avatars = await Storage.list(`aura/${user.sub}`);
            let urls = [];
            if (avatars) {
                urls = await Promise.all(
                    avatars.map(async (item) => {
                        const imageUrl = await Storage.get(item.key);
                        return { key: item.key, value: imageUrl };
                    })
                );
                setKsData(urls);
            }
            // console.log('=====', urls);
            const url = "https://hvz6syv857.execute-api.us-east-1.amazonaws.com/testing/listSavedUsers";
            const requestData = {
                user_id: localUser.sub,
            };
            try {
                const response = await axios.post(url, requestData);
                if (response.data.body.data[0] && response.data.body.data[0]) {
                    const kkss = response.data.body.data;
                    const processed = kkss.map((item, index) => {
                        return item;
                    });
                    setUserData(processed);
                }
            } catch (error) {
                // console.error('Error calling API:', error);
                // Handle errors as needed
            }
        };
        fetchData();
    }, [user]);
    // console.log('======ksData:', userData);
    const { search, hash } = window.location;
    const hashValues = hash.substring(1).split('#');
    const hashNumber = hashValues[0] ? hashValues[0] : null;
    if (userData && !fname && hashValues && hashValues[0]) {
        // setShowLoader(true);
        userData.forEach((element) => {
            if (element.id === hashNumber) {
                const matchingUrl = ksData.find((img) => img.key === element.client_image);
                const clientAvt = matchingUrl ? matchingUrl.value : '';
                console.log(clientAvt);
                removeItem('ScanImage');
                // console.log('======1:');
                delay(500);
                setFname(element.fname);
                setLname(element.lname);
                setUseremail(element.user_email);
                setPhone(element.user_phone);
                setBirthdate(element.fname);
                const currentDate = new Date(element.birth_date);
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1; // Adding 1 to convert from zero-based to one-based
                const day = currentDate.getDate();
                setBirthdate(`${month}/${day}/${year}`);
                setDec(element.user_text);
                const localUser = getItem(USER_KEY);
                const updatedUser = { ...localUser, ScanImage: clientAvt };
                setItem(USER_KEY, updatedUser);
                setImage(clientAvt);
                setScanImage(clientAvt);
                setClientImage(element.client_image);
                setCookie('ScanImage', clientAvt, 7);

            }
        });
    }

    // console.log("*******", image);
    // console.log("======", scanImage);

    if (fname) {
        validationErrors.firstName = '';
    }
    if (lname) {
        validationErrors.lastName = '';
    }
    if (useremail) {
        validationErrors.email = '';
    }
    if (phone) {
        validationErrors.phonenumber = '';
    }
    if (birthdate) {
        validationErrors.dob = '';
    }
    if (dec) {
        validationErrors.textValue = '';
    }
    const handleDatePickerChange = (newDate) => {
        const currentDate = new Date(newDate);
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Adding 1 to convert from zero-based to one-based
        const day = currentDate.getDate();
        setBirthdate(`${month}/${day}/${year}`);
    };
    const handleClickScan = async () => {
        const newValidationErrors = {
            firstName: '',
            lastName: '',
            email: '',
            phonenumber: '',
            dob: '',
            textValue: '',
        };
        if (!fname) {
            newValidationErrors.firstName = 'First Name is required';
        }
        if (!lname) {
            newValidationErrors.lastName = 'Last Name is required';
        }
        if (!useremail) {
            newValidationErrors.email = 'Email is required';
        }
        if (!phone) {
            newValidationErrors.phonenumber = 'Phone Number is required';
        }
        if (!birthdate) {
            // newValidationErrors.dob = 'Date of Birth is required';
        }
        if (!dec) {
            newValidationErrors.textValue = 'Affirmation is required';
        }
        if (!scanImage) {
            newValidationErrors.scanImage = 'Please select Aura Image';
        }
        // Add more validations as needed
        setValidationErrors(newValidationErrors);
        if (Object.values(newValidationErrors).some((error) => error !== '')) {
            return;
        }
        const localUser = getItem(USER_KEY);
        const url = "https://hvz6syv857.execute-api.us-east-1.amazonaws.com/testing/test"
        const requestData = {
            user_id: localUser.sub,
            first_name: fname,
            last_name: lname,
            birth_date: birthdate,
            user_text: dec,
            user_email: useremail,
            user_phone: phone,
            client_image: clientImage,
            hash_number: hashNumber
        };
        try {
            const response = await axios.post(url, requestData);
            historty.push('/aura-scan');
            setApiResponse(response.data);
        } catch (error) {
            // console.error('Error calling API:', error);
            // Handle errors as needed
        }
    };
    const handleScan = () => {
        setScanAnimation(true);
        setTimeout(() => {
            setOpenLoader(true);
            setScanAnimation(false);
        }, 6000);
    };
    const handleSuccessClose = () => {
        setOpenLoader(false);
    };
    const handleOpenUserRecorde = () => {
        historty.push('/aura-user-record');
    }

    // console.log(scanImage);

    const myCookieValue = getCookie('ScanImage');
    if (myCookieValue) {
        console.log('myCookie value:', myCookieValue);
    }

    return (
        <>
            <MainLayout>
                <div className={classes.bg}>
                    <div className={classes.wrap}>
                        <div className="userRecoderORCon">
                            <Button className={classes.button}
                                onClick={handleOpenUserRecorde}
                            > User Records
                            </Button>
                            {/* <Button className={`${classes.button}`} type="button">
                                User Information
                            </Button> */}
                            {/* <BigButton title='Send Email' type='primary' /> */}
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={fname}
                                    onChange={(e) => {
                                        setFname(e.target.value);
                                    }}
                                    className={classes.nameField}
                                />
                                {validationErrors.firstName && (
                                    <div style={{ color: 'red' }}>{validationErrors.firstName}</div>
                                )}
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={lname}
                                    onChange={(e) => {
                                        setLname(e.target.value);
                                    }}
                                    className={classes.nameField}
                                />
                                {validationErrors.lastName && (
                                    <div style={{ color: 'red' }}>{validationErrors.lastName}</div>
                                )}
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="email"
                                    placeholder="enter your Email address"
                                    value={useremail}
                                    onChange={(e) => {
                                        setUseremail(e.target.value);
                                    }}
                                    className={classes.nameField}
                                />
                                {validationErrors.email && (
                                    <div style={{ color: 'red' }}>{validationErrors.email}</div>
                                )}
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="phonenumber"
                                    placeholder="enter your Phone number"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                    }}
                                    className={classes.nameField}
                                />
                                {validationErrors.phonenumber && (
                                    <div style={{ color: 'red' }}>{validationErrors.phonenumber}</div>
                                )}
                                <div id="birth-date-area" style={{ margin: '20px 0px' }}>
                                    <div>Birth Date
                                        <span> *</span></div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            onChange={handleDatePickerChange}
                                            label={birthdate}
                                        />
                                    </LocalizationProvider>
                                    {validationErrors.dob && (
                                        <div style={{ color: 'red' }}>{validationErrors.dob}</div>
                                    )}
                                </div>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    placeholder="Please enter your positive affirmation here"
                                    style={{ width: '100%', height: '100px' }}
                                    value={dec}
                                    onChange={(e) => {
                                        setDec(e.target.value);
                                    }}
                                />
                                {validationErrors.textValue && (
                                    <div style={{ color: 'red' }}>{validationErrors.textValue}</div>
                                )}
                            </form>
                            <div className="">
                                {/* <Button> Analyze Client Image </Button> */}
                                <Button
                                    className={`${classes.button} customBtn`}
                                    onClick={handleClickOpen}
                                >
                                    Analyze Client Image
                                </Button>
                                {validationErrors.scanImage && (
                                    <div style={{ color: 'red' }}>{validationErrors.scanImage}</div>
                                )}
                            </div>
                            <div className="">
                                {/* <Button> Spectral Voice Analtysis </Button> */}
                                {/* <Button className={`${classes.button} customBtn`}>
                                    {' '}
                                    Spectral Voice Analtysis{' '}
                                </Button> */}
                            </div>
                            <div className="analyzeButton">
                                {/* <Button> Start </Button> */}
                                <Button
                                    className={`${classes.button} customBtn customStartBtn`}
                                    onClick={handleClickScan}
                                >
                                    {' '}
                                    Start{' '}
                                    {(!showloader
                                        ? ''
                                        : <ColorCircularProgress />
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
            <Dialog open={open} onClose={handleClose} class="ks">
                <DialogContent className="modal_dialog">
                    {openCamera ? (
                        <div className="videoContainer">
                            <video ref={videoRef} autoPlay playsInline>
                                {/* Add an empty track element to satisfy accessibility requirements */}
                                <track kind="captions" src="" label="" />
                            </video>
                            <Button onClick={captureImageHandler} variant="contained" autoFocus>
                                Capture
                            </Button>
                            <Button onClick={closeCameraHandler} variant="contained">
                                Close Camera
                            </Button>
                        </div>
                    ) : (
                        <>
                            <input id="fileInput" className="" type={`${(scanImage) ? 'hidden' : 'file'}`} accept="image/*" onChange={handleChangeFile} />
                            {scanImage && (
                                <img
                                    src={scanImage}
                                    alt="demo"
                                    className={`${scanAnimation && 'fadeinout'}`}
                                />
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                    <Button onClick={openCameraHandler} variant="contained">
                        Open Camera
                    </Button>
                    <Button onClick={handleScan} variant="contained">
                        Scan
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ============================== LOADER  ============================== */}
            <Dialog
                open={openLoader}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description1"
            >
                <DialogContent style={{ backgroundColor: '#0d3c61' }}>
                    <DialogContentText id="alert-dialog-description1" style={{ color: '#fff' }}>
                        Energy Scan Complete
                    </DialogContentText>
                </DialogContent>
                <Button onClick={handleSuccessClose || handleScan} variant="contained" autoFocus>
                    Ok
                </Button>
            </Dialog>
        </>
    );
};

export default Home;

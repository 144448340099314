import { call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getInvoices, payInvoice } from './apis';
import { GET_INVOICES, InvoiceType, PAY_INVOICES } from './types';
import {
    getInvoicesSuccessAction,
    getInvoicesFailedAction,
    invoiceLoadingAction,
    payInvoicesSuccessAction,
    payInvoicesFailedAction,
} from './actions';

export function* fetchInvoice(action: AnyAction) {
    try {
        yield put(invoiceLoadingAction(true));
        const response: { invoices: InvoiceType[] } = yield call(getInvoices, action.payload);
        yield put(invoiceLoadingAction(false));
        yield put(getInvoicesSuccessAction(response));
    } catch (error) {
        yield put(getInvoicesFailedAction(error));
    }
}

export function* getInvoiceWatcher() {
    yield takeLatest(GET_INVOICES, fetchInvoice);
}

export function* payInvoiceSaga(action: AnyAction) {
    try {
        yield put(invoiceLoadingAction(true));
        const response: { invoice: InvoiceType } = yield call(payInvoice, action.payload);
        yield put(invoiceLoadingAction(false));
        yield put(payInvoicesSuccessAction(response));
    } catch (error) {
        yield put(payInvoicesFailedAction(error));
    }
}

export function* payInvoiceWatcher() {
    yield takeLatest(PAY_INVOICES, payInvoiceSaga);
}

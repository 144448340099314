import {
    ActionType,
    GET_SUBSCRIPTION_SUCCEEDED,
    GET_SUBSCRIPTION_FAILED,
    SubscriptionType,
    ErrorType,
    LOADING_SUBSCRIPTION,
    SUBSCRIPTION_KEY,
    CANCEL_SUBSCRIPTION_SUCCEEDED,
    CANCEL_SUBSCRIPTION_FAILED,
    CREATE_SUBSCRIPTION_SUCCEEDED,
    CREATE_SUBSCRIPTION_FAILED,
    UPGRADE_SUBSCRIPTION_SUCCEEDED,
    UPGRADE_SUBSCRIPTION_FAILED,
    PAUSE_SUBSCRIPTION_SUCCEEDED,
    PAUSE_SUBSCRIPTION_FAILED,
} from './types';
import { getItem, setItem } from '../../utils/localStorage';

const storedSubscription = getItem(SUBSCRIPTION_KEY);
const initialState: { data: SubscriptionType; error: ErrorType; loading: boolean } = {
    data: {
        customer: '',
        subscriptionStatus: null,
    },
    ...storedSubscription,
    error: {},
    loading: false,
};

export default function reducer(state = initialState, action: ActionType) {
    let localState;
    switch (action.type) {
        case LOADING_SUBSCRIPTION:
            localState = {
                ...state,
                loading: action.payload,
            };
            break;
        case PAUSE_SUBSCRIPTION_SUCCEEDED:
        case UPGRADE_SUBSCRIPTION_SUCCEEDED:
        case GET_SUBSCRIPTION_SUCCEEDED:
        case CREATE_SUBSCRIPTION_SUCCEEDED:
            localState = {
                ...state,
                data: action.payload?.subscription || state.data,
            };
            break;
        case PAUSE_SUBSCRIPTION_FAILED:
        case UPGRADE_SUBSCRIPTION_FAILED:
        case CREATE_SUBSCRIPTION_FAILED:
        case GET_SUBSCRIPTION_FAILED:
        case CANCEL_SUBSCRIPTION_FAILED:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            };
        case CANCEL_SUBSCRIPTION_SUCCEEDED:
            localState = {
                ...state,
                loading: false,
                data: null,
            };
            break;
        default:
            localState = state;
    }
    // console.log(localState);
    setItem(SUBSCRIPTION_KEY, localState);
    return localState;
}

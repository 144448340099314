import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#33A4FF',
    },
}));

function Loading() {
    const classes = useStyles();

    return (
        <div
            style={{
                height: '100vh',
                backgroundColor: '#33A4FF',
            }}
        >
            <Backdrop className={classes.backdrop} open>
                <CircularProgress color="primary" />
            </Backdrop>
        </div>
    );
}

export default Loading;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { GlobalStateType } from '../../redux/rootTypes';
import CardInfo from '../ModifyBillingInfo/LinkedCards/LinkedCards';
import CreatePaymentMethod from '../ModifyBillingInfo/createPaymentMethod/CreatePaymentMethod';
import { ColorCircularProgress } from '../Loader';
import Line from '../Line';
import CancelSubscription from './CancelSubscription';
import UpgradeSubscription from './UpgradeSubscription';
import StartSubscription from './StartSubscription';
import BigButton from '../utils/Button/BigButton';
import PayInvoice from '../Invoice/PayInvoice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textContainer: {
            '& p': {
                padding: '6px',
            },
            '& #title': {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            '& #small': {
                textTransform: 'uppercase',
                fontSize: '10px',
            },
        },
        btnContainer: {
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                textAlign: 'center',
                padding: '25px',
            },
        },
        priceContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
        price: {
            width: '300px',
            border: '2px solid #0468BF',
            textAlign: 'center',
            margin: '5px',
            cursor: 'pointer',
            transition: 'all 120ms',
            outline: 'none',
            '&:hover': {
                border: '2px solid #1cc00a',
                boxShadow: '0px 0px 5px #4a4a4a',
            },
            '&:focus': {
                border: '2px solid #1cc00a !important                   ',
                boxShadow: '0px 0px 5px #4a4a4a',
            },
            [theme.breakpoints.down('sm')]: {
                minwidth: '220',
                margin: '25px',
            },
            '& p': {
                fontSize: '28px',
                margin: '16px auto',
                fontWeight: 'bolder',
            },
            '& span:nth-child(1)': {
                color: '#1cc00a',
            },
        },
        selectedPrice: {
            border: '2px solid #1cc00a',
            boxShadow: '0px 0px 5px #4a4a4a',
        },
        mobileTitle: {
            textAlign: 'center',
            color: '#0468BF',
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline',
                visibility: 'visible',
            },
        },
    }),
);

const Subscription = () => {
    const classes = useStyles();
    const { subscription } = useSelector((state: GlobalStateType) => state.user);
    const { loading, error: subscriptionError } = useSelector(
        (state: GlobalStateType) => state.subscription,
    );
    const {
        data: { paymentMethodsList, defaultPaymentMethod },
        loading: paymentLoading,
    } = useSelector((state: GlobalStateType) => state.payments);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);

    return (
        <Grid>
            <Line />
            <Grid>{subscriptionError && <div>{subscriptionError.message}</div>}</Grid>
            {paymentMethodsList?.length > 0 ? (
                <Grid>
                    <p style={{ color: 'black' }}>Your Payment Methods:</p>
                    {paymentMethodsList.map((pm) => (
                        <>
                            <CardInfo
                                paymentMethod={pm}
                                active={pm.id === defaultPaymentMethod?.id}
                            />
                        </>
                    ))}
                    {showAddPaymentMethod && <CreatePaymentMethod />}
                    {!showAddPaymentMethod && (
                        <div style={{ width: '332px', padding: '20px 0', textAlign: 'center' }}>
                            <BigButton
                                title="Add New Payment Method"
                                type="primary"
                                onClick={() => setShowAddPaymentMethod(true)}
                            />
                        </div>
                    )}
                </Grid>
            ) : (
                <Grid>
                    {!paymentLoading ? (
                        <CreatePaymentMethod />
                    ) : (
                        <div className={classes.btnContainer} style={{ textAlign: 'center' }}>
                            <ColorCircularProgress />
                        </div>
                    )}
                </Grid>
            )}

            <Grid>
                <div style={{ width: '332px', padding: '20px 0', textAlign: 'center' }}>
                    {!subscription?.subscriptionStatus && <StartSubscription />}
                    {!subscription?.pauseCollection && <PayInvoice />}
                    {subscription?.subscriptionStatus === 'canceled' && <UpgradeSubscription />}
                    {subscription?.subscriptionStatus === 'active' &&
                        !subscription?.cancelAtPeriodEnd &&
                        !subscription?.pauseCollection && <CancelSubscription />}
                </div>
                {loading ? (
                    <div className={classes.btnContainer} style={{ textAlign: 'center' }}>
                        <ColorCircularProgress />
                    </div>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default Subscription;

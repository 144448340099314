import { AnyAction } from 'redux';
import { getItem } from '../../utils/localStorage';
import {
    SET_ERRORS,
    LOADING_UI,
    CLEAR_ERRORS,
    SET_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    REMEMBER_CREDENTIALS,
    LOGIN_SUCCEEDED,
    LOGIN_FAILED,
    LOGOUT_SUCCEEDED,
    LOGOUT_FAILED,
    CLEAR_MESSAGES,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_RESPONSE,
    CHANGE_PASSWORD_LOADER,
    UPDATE_USER_SUCCEED,
    UPDATE_USER_FAILED,
    GET_USER_FAILED,
    GET_USER_SUCCEEDED,
    UPDATE_PAYMENT_FLOW_STATUS_SUCCESS,
    UPDATE_PAYMENT_FLOW_STATUS_FAILED,
    USER_KEY,
} from './types';

const user = getItem(USER_KEY) || {};
const initialState = {
    changePassword: {
        loading: false,
        success: null,
        error: null,
    },
    userLanguage: 'en',
    authenticated: null,
    paymentFlow: null,
    hasAvatar: false,
    ...user,
};

export default function reducer(state = initialState, action: AnyAction) {
    // console.log('user reducer: ', action);
    switch (action.type) {
        case UPDATE_PAYMENT_FLOW_STATUS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: action.payload === 'pending',
            };
        case UPDATE_PAYMENT_FLOW_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload.error,
            };
        case GET_USER_SUCCEEDED:
            return {
                ...state,
                ...action.payload.user,
                loading: false,
            };
        case GET_USER_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload.error,
            };
        case UPDATE_USER_SUCCEED:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case UPDATE_USER_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload.error,
            };
        case LOGIN_SUCCEEDED:
            return {
                ...state,
                ...action.payload,
                authenticated: new Date().toString(),
                signing: false,
                loading: false,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            };
        case LOGOUT_SUCCEEDED:
            return initialState;
        case LOGOUT_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            };
        case SET_ERRORS:
            return {
                ...state,
                loading: false,
                errors: action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                errors: null,
            };
        case LOADING_UI:
            return {
                ...state,
                loading: true,
            };
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: new Date().toString(),
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER:
            return {
                ...state,
                ...action.payload,
                authenticated: new Date().toString(),
                signing: false,
            };
        case LOADING_USER:
            return {
                ...state,
                loading: action.payload,
            };
        case REMEMBER_CREDENTIALS:
            return {
                ...state,
                remember: action.payload,
            };
        case CHANGE_PASSWORD_LOADER:
            return {
                ...state,
                changePassword: {
                    loading: true,
                    success: null,
                    error: null,
                },
            };
        case CHANGE_PASSWORD_RESPONSE:
            return {
                ...state,
                changePassword: {
                    loading: false,
                    success: action.success,
                    error: null,
                },
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePassword: {
                    loading: false,
                    success: null,
                    error: action.error,
                },
            };
        case CLEAR_MESSAGES: {
            return {
                ...state,
                changePassword: {
                    loading: false,
                    success: null,
                    errors: null,
                },
            };
        }
        default:
            return state;
    }
}

import { Auth, API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';
import { setItem } from '../../utils/localStorage';

Auth.configure(awsexports);
API.configure(awsexports);

const apiName = 'BillingProduct';
const path = '/';
const myInit: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers: any;
    response: true;
    queryStringParameters: Record<string, unknown>;
} = {
    headers: {},
    response: true,
    queryStringParameters: {},
};

const getElements = async (queryStringParameters: Record<string, unknown>) => {
    const options = {
        ...myInit,
        queryStringParameters,
        headers: await getHeader({}),
    };
    const { data } = await API.get(apiName, path, options);
    setItem('product', data[0]);
    return data[0];
};

export const getProduct = async () => {
    return { product: await getElements({}) };
};

export const listProducts = async () => {
    return { product: await getElements({}) };
};

/* eslint-disable */
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Line from '../Line';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textContainer: {
            maxWidth: '300px',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '& h1': {
                margin: '5px',
            },
            '& p': {
                margin: '5px',
            },
        },
    }),
);

const MemberInfo = () => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.textContainer}>
                <Grid item>
                    <p>
                        <strong>Payment Status: </strong> in progress...
                    </p>
                </Grid>
            </Grid>
        </>
    );
};

export default MemberInfo;

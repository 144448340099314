import {
    CircularProgress,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    withStyles,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../utils/Alert/Alert';
import BigButton from '../utils/Button/BigButton';
import useGoTo from '../../utils/useGoTo';
import { GlobalStateType } from '../../redux/rootTypes';
import { getInvoicesAction } from '../../redux/invoices/actions';
import useOnlineDispatch from '../OnlineDispatch';

const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            backgroundColor: 'transparent',
            borderTop: '1px solid white',
            borderBottom: '1px solid white',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '12',
            textAlign: 'left',
        },
        body: {
            fontSize: 10,
            textAlign: 'left',
            color: 'white',
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
            color: 'white',
        },
    }),
)(TableRow);

interface Data {
    product: string;
    reference: string;
    date: string;
    amount: string;
    status: string;
    url: string;
}

function createData(
    product: string,
    reference: string,
    date: string,
    amount: string,
    status: string,
    url: string,
): Data {
    return { product, reference, date, amount, status, url };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '600px',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '& h2': {
                color: '#428cd0',
            },
        },
        btnContainer: {
            paddingTop: '50px',
            width: '100%',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
            '& div': {
                width: '125px',
            },
        },
        scroll: {
            [theme.breakpoints.down('xs')]: {
                overflow: 'scroll',
            },
        },
        header: {
            backgroundColor: 'transparent',
        },
    }),
);

const BillingHistory = () => {
    const classes = useStyles();
    const goTo = useGoTo();
    const onlineDispatch = useOnlineDispatch();
    const { data: invoices, loading } = useSelector((state: GlobalStateType) => state.invoices);
    const { data: subscription } = useSelector((state: GlobalStateType) => state.subscription);
    const [rows, setRows] = useState<Data[]>([]);

    useEffect(() => {
        if (subscription.customer && subscription.subscriptionID)
            onlineDispatch(
                getInvoicesAction({
                    customer: subscription.customer,
                    subscription: subscription.subscriptionID,
                }),
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    useEffect(() => {
        setRows(
            invoices.map((inv) =>
                createData(
                    'Subscription',
                    inv.number || 'NONE',
                    (inv.created && new Date(inv.created * 1000).toLocaleString()) || 'NONE',
                    `${inv.total} ${inv.currency.toUpperCase()}`,
                    inv.status,
                    inv.invoice_pdf || 'NONE',
                ),
            ),
        );
    }, [invoices]);

    const downloadPDF = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <Grid container className={classes.root} direction="column">
            <Grid style={{ width: '330px', margin: '22px 0' }}>
                <Alert msg="The invoice will be generated about 24 hours after we have received your payment." />
            </Grid>
            <Grid item style={{ textAlign: 'left', width: '100%' }}>
                <h3>Invoices</h3>
            </Grid>
            {loading ? (
                <CircularProgress />
            ) : (
                <Grid item xs={12} className={classes.scroll}>
                    <Table aria-label="customized table">
                        <TableHead className={classes.header}>
                            <TableRow>
                                <StyledTableCell>Product</StyledTableCell>
                                <StyledTableCell align="right">Reference</StyledTableCell>
                                <StyledTableCell align="right">Date</StyledTableCell>
                                <StyledTableCell align="right">Amount</StyledTableCell>
                                <StyledTableCell align="right">Status</StyledTableCell>
                                <StyledTableCell align="right">Download</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.reference}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.product}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.reference}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        {new Date(row.date).toISOString().split('T')[0]}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.amount}</StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.status.toUpperCase()}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <IconButton
                                            onClick={() => {
                                                downloadPDF(row.url);
                                            }}
                                        >
                                            <img
                                                alt="download"
                                                src="/assets/icons/download.svg"
                                                style={{ color: 'white' }}
                                            />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            )}
            <Grid className={classes.btnContainer}>
                <div>
                    <BigButton
                        title="go back"
                        type="primary"
                        onClick={() => goTo('subscription')}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default BillingHistory;

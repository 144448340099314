export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    mediaURI?: string;
    error: ErrorType;
}

export interface MediaType {
    content?: string;
    uri?: string;
    panelId?: string;
    key?: string;
    isDownloading?: boolean;
    error?: ErrorType;
    progress?: number | undefined;
    blob?: string;
    playing?: boolean;
}

export const GET_MEDIA = 'GET_MEDIA';
export const SET_MEDIA_DOWNLOADING = 'SET_MEDIA_DOWNLOADING';
export const GET_MEDIA_SUCCEEDED = 'GET_MEDIA_SUCCEEDED';
export const GET_MEDIA_FAILED = 'GET_MEDIA_FAILED';

export const LIST_MEDIA = 'LIST_MEDIA';
export const LIST_MEDIA_SUCCEEDED = 'LIST_MEDIA_SUCCEEDED';
export const LIST_MEDIA_FAILED = 'LIST_MEDIA_FAILED';

export const GET_MEDIA_URL = 'GET_MEDIA_URL';
export const GET_MEDIA_URL_SUCCEEDED = 'GET_MEDIA_URL_SUCCEEDED';
export const GET_MEDIA_URL_FAILED = 'GET_MEDIA_URL_FAILED';

export const MEDIA_INDEXED_KEY = 'MEDIA_INDEXED';
export const BACKGROUND_NAME = 'medias/WH_BackingTrack.mp3';

import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import useOnlineDispatch from '../OnlineDispatch';
import MemberInfo from './MemberInfo';
import ModifyBillingInfo from '../ModifyBillingInfo/ModifyBillingInfo';
import AddCreditCard from '../AddCreditCard/AddCreditCard';
import { GlobalStateType } from '../../redux/rootTypes';
import { listPriceAction } from '../../redux/prices/actions';
import BillingHistory from '../BillingHistory/BillingHistory';
import SinglePayment from '../SinglePayment';
import StartPay from './StartPay';
import { getProductAction } from '../../redux/product/actions';
import { listPaymentMethodAction } from '../../redux/payments/actions';
import { getUserAction } from '../../redux/user/actions';
import { getInvoicesAction } from '../../redux/invoices/actions';

const useStyles = makeStyles(() => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: 20,
        width: '100%',
        height: 'auto',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
    },
}));

const BillinDetails = () => {
    const { path } = useRouteMatch();
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();

    const {
        product: { data: products },
        user: { sub, stripeCustomerID, subscription },
    } = useSelector((state: GlobalStateType) => state);

    useEffect(() => {
        if (products.length === 0) onlineDispatch(getProductAction());
        if (products.length > 0) onlineDispatch(listPriceAction({ productID: products[0]?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    useEffect(() => {
        onlineDispatch(getUserAction({ sub }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stripeCustomerID)
            onlineDispatch(listPaymentMethodAction({ customerID: stripeCustomerID }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripeCustomerID]);

    useEffect(() => {
        if (stripeCustomerID && subscription?.subscriptionID)
            onlineDispatch(
                getInvoicesAction({
                    customer: stripeCustomerID,
                    subscription: subscription?.subscriptionID,
                }),
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    return (
        <div className={classes.bg}>
            <Grid container justify="flex-start" alignItems="center" direction="column">
                <MemberInfo />
                <Switch>
                    <Route exact path={path}>
                        <StartPay />
                    </Route>
                    <Route exact path={`${path}/single-payment`}>
                        <SinglePayment />
                    </Route>
                    <Route exact path={`${path}/subscribe-payment`}>
                        <SinglePayment />
                    </Route>
                    <Route exact path={`${path}/modify-billing-info`}>
                        <ModifyBillingInfo />
                    </Route>
                    <Route exact path={`${path}/add-credit-card`}>
                        <AddCreditCard />
                    </Route>
                    <Route exact path={`${path}/billing-history`}>
                        <BillingHistory />
                    </Route>
                </Switch>
            </Grid>
        </div>
    );
};

export default BillinDetails;

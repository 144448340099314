import * as types from './types';

export function newError(payload: types.NotificationType) {
    return {
        type: types.NEW_ERROR_NOTIFICATION,
        payload,
    };
}

export function clearError() {
    return {
        type: types.CLEAR_ERROR_NOTIFICATION,
    };
}

export const NEW_ERROR_NOTIFICATION = 'NEW_ERROR_NOTIFICATION';
export const CLEAR_ERROR_NOTIFICATION = 'CLEAR_ERROR_NOTIFICATION';

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export interface NotificationType {
    severity?: 'error' | 'success' | 'info' | 'warning' | undefined;
    message?: string;
}

export interface ActionType {
    type: string;
    payload?: NotificationType;
}

export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    payload: {
        products?: ProductType[];
        product?: ProductType;
        error?: ErrorType;
        loading: boolean;
    };
}

export interface Recurring {
    aggregate_usage: string | null;
    interval: string;
    interval_count: number;
    usage_type: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProductType {
    id: string;
    object: string;
    active: boolean;
    billing_scheme: string;
    created: number;
    currency: string;
    livemode: boolean;
    lookup_key: string | null;
    metadata: Record<string, unknown>;
    nickname: string | null;
    product: string;
    recurring: Recurring;
    tiers_mode: string | null;
    transform_quantity: string | null;
    type: string;
    unit_amount: number;
    unit_amount_decimal: string;
}

export interface ProductsType {
    products: ProductType[];
}
// Replace with your PRODUCT types
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCEEDED = 'GET_PRODUCT_SUCCEEDED';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

export const LIST_PRODUCT = 'LIST_PRODUCT';
export const LIST_PRODUCT_SUCCEEDED = 'LIST_PRODUCT_SUCCEEDED';
export const LIST_PRODUCT_FAILED = 'LIST_PRODUCT_FAILED';
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';

export const LOADING_PRODUCT = 'LOADING_PRODUCT';

export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    payload: {
        invoice?: InvoiceType;
        invoices?: InvoiceType[];
        loading?: boolean;
        error?: ErrorType;
    };
}

export interface InvoiceType {
    id: string; // in_1IhWCFJ113C8jlhWHqS72wKm
    account_name: string; // 'WaterHarmonizer';
    amount_due: number; // 500;
    amount_paid: number; // 0;
    created: number; // 1618735299;
    currency: string; // 'usd';
    customer: string; // 'cus_JJe64NyGAYZpqf';
    customer_email: string; // 'adrian.flda@gmail.com';
    customer_name: string; // 'Adrian Moreno';
    default_source: string | null; // null;
    description: string | null; // null;
    due_date: number | null; // null;
    hosted_invoice_url: string | null; // null;
    invoice_pdf: string | null; // null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    last_finalization_error: any; // null;
    next_payment_attempt: number; // 1618738899;
    number: string | null; // null;
    paid: boolean; // false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payment_intent: any; // null;
    period_end: number; // 1618735299;
    period_start: number; // 1618735299;
    status: string; // 'draft';
    subscription: string | null; // null;
    subtotal: number; // 500;
    total: number; // 500;
}

// Replace with your INVOICE types
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCEEDED = 'GET_INVOICES_SUCCEEDED';
export const GET_INVOICES_FAILED = 'GET_INVOICES_FAILED';

export const PAY_INVOICES = 'PAY_INVOICES';
export const PAY_INVOICES_SUCCEEDED = 'PAY_INVOICES_SUCCEEDED';
export const PAY_INVOICES_FAILED = 'PAY_INVOICES_FAILED';

export const LOADING_INVOICE = 'LOADING_INVOICE';
export const INVOICE_KEY = 'INVOICE_KEY';

export interface StripeInvoiceType {
    id: string;
    account_name: string;
    amount_due: number;
    amount_paid: number;
    amount_remaining: number;
    application_fee_amount: null;
    attempt_count: number;
    attempted: false;
    auto_advance: true;
    billing_reason: string;
    charge: null;
    collection_method: string;
    created: number;
    currency: string;
    custom_fields: null;
    customer: string;
    customer_email: string;
    customer_name: string;
    customer_tax_exempt: string;
    customer_tax_ids: [];
    default_payment_method: null;
    default_source: null;
    default_tax_rates: [];
    description: null;
    discount: null;
    discounts: [];
    due_date: null;
    ending_balance: null;
    footer: null;
    hosted_invoice_url: null;
    invoice_pdf: null;
    last_finalization_error: null;
    livemode: false;
    next_payment_attempt: number;
    number: null;
    paid: false;
    payment_intent: null;
    payment_settings: {
        payment_method_options: null;
        payment_method_types: null;
    };
    period_end: number;
    period_start: number;
    status: string;
    subscription: null;
    subtotal: number;
    tax: null;
    total: number;
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import PaymentInfo from './PaymentInfo';
import PriceList from './PriceList';
import Subscription from '../Subscription/Subscription';
import SinglePayment from '../SinglePayment';
import { ColorCircularProgress } from '../Loader';
import SubscriptionInfo from './SubscriptionInfo';
import useBillingStatus from '../BillingStatus/useBillingStatus';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flow: { [key: string]: any } = {
    PAYMENT: {
        UNPAID: {
            info: <PaymentInfo />,
            type: <SinglePayment />,
        },
        PAID: {
            info: <PaymentInfo />,
            type: null,
        },
    },
    SUBSCRIPTION: {
        UNPAID: {
            info: <SubscriptionInfo />,
            type: <Subscription />,
        },
        PAID: {
            info: <SubscriptionInfo />,
            type: <Subscription />,
        },
        CANCELED: {
            info: <SubscriptionInfo />,
            type: <Subscription />,
        },
    },
    CODE: {
        DEFAULT: {
            info: null,
            type: null,
        },
    },
    DEFAULT: {
        ONE: {
            info: <PaymentInfo />,
            type: <SinglePayment />,
        },
        FOUR: {
            info: <SubscriptionInfo />,
            type: <Subscription />,
        },
        DEFAULT: {
            info: <ColorCircularProgress />,
            type: <ColorCircularProgress />,
        },
    },
};

const BillingFlow = () => {
    const { selectedPrice } = useSelector((state: GlobalStateType) => state.prices);
    const [paymentFlow, setPaymentFlow] = useState(flow.DEFAULT.DEFAULT);
    const { FLOW, STATUS } = useBillingStatus();

    useEffect(() => {
        const price =
            selectedPrice?.nickname && selectedPrice.nickname.indexOf('ONE') > -1 ? 'ONE' : 'FOUR';
        // console.log(FLOW, STATUS, price);
        const firstStep = flow[FLOW] || flow.DEFAULT;
        const secondStep = firstStep[STATUS || price] || firstStep.DEFAULT;
        setPaymentFlow(secondStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPrice, FLOW, STATUS]);
    // console.log('kkkkkk: ', paymentFlow);
    return (
        <>
            {paymentFlow ? paymentFlow.info : ''}
            {FLOW === 'DEFAULT' && <PriceList />}
            {paymentFlow ? paymentFlow.type : ''}
        </>
    );
};

export default BillingFlow;

import {
    Button,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import DrawerIcon from '../../icons/DrawerIcon';
import { GlobalStateType } from '../../redux/rootTypes';
import InternationalizationButton from '../InternationalizationButton';
import SideMenu from '../SideMenu';
import { showInfoClicked, toggleLeftSideMenu } from '../../redux/app/actions';
import HarmonizeCounter from '../InfoPanel/HarmonizeCounter';

const useStyle = makeStyles<Theme>((theme) => ({
    rootStyles: {
        backgroundColor: '#0468BF',

        height: '200px',
        overflow: 'hidden',
    },
    Grid: {
        display: 'flex',
        height: '100%',
    },
    rightGrid: {
        marginTop: '-46.273193359375px',
        [theme.breakpoints.down('sm')]: {
            background: 'none',
            height: '200px',
        },
        background:
            "transparent url('assets/layout/Header%20Background.svg') 0% 0% no-repeat padding-box",
        opacity: 1,
        mixBlendMode: 'normal',
    },
    logo: {
        marginTop: '30px',
        width: '321px',
        height: '157px',
        background: "transparent url('assets/layout/Logo.png') 0% 0% no-repeat padding-box",
        opacity: 1,
    },
    drawer: {
        marginTop: '34px',
        marginLeft: '38px',
        width: '24px',
        height: '22px',
        [theme.breakpoints.down(800)]: {
            marginTop: '84px',
            marginLeft: '20px',
        },
    },
    content: {
        marginTop: '40.273193359375px',
    },
    internationalization: {
        marginTop: '84px',
        [theme.breakpoints.down(800)]: {
            marginTop: '80px',
            marginRight: '20px',
        },
    },
    text: {
        color: 'white',
        marginTop: '20px',
        fontSize: 30,
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    textActivity: {
        color: 'white',
        marginTop: '-5px',
        fontSize: 24,
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    timer: {
        color: 'white',
        marginTop: '-5px',
        fontSize: 14,
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    textSubtitle: {
        color: 'white',
        fontSize: 12,
        marginLeft: '20px',
        marginRight: '20px',
        paddingBottom: '15px',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    textContainer: {
        height: '60px',
        [theme.breakpoints.down(800)]: {
            height: '150px',
        },
    },
    infoToggle: {
        fontSize: '12px',
        height: '25px',
        width: '120px',
        borderRadius: '13px',
        backgroundColor: 'white',
        color: 'black',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: 'none',
        },
        [theme.breakpoints.down(800)]: {
            marginLeft: '20px',
        },
    },
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeaderDesktop = ({ setHeaderText }: { setHeaderText: any }) => {
    const classes = useStyle();
    const { t } = useTranslation(['header']);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    const history = useHistory();
    const dispatch = useDispatch();
    const { drawerIsOpen, internationalizationMenuIsOpen, language, isShowingInfo } = useSelector(
        (state: GlobalStateType) => state.header,
    );
    const { activity } = useSelector((state: GlobalStateType) => state.panels);

    return (
        <Grid container direction="row" className={classes.rootStyles}>
            <Grid item container xs={1} sm={1} alignContent="flex-start">
                <IconButton
                    onClick={() => dispatch(toggleLeftSideMenu(drawerIsOpen))}
                    onMouseEnter={() => {
                        if (!isMobile) dispatch(toggleLeftSideMenu(drawerIsOpen));
                    }}
                    className={classes.drawer}
                >
                    <DrawerIcon />
                </IconButton>

                <SideMenu isOpen={drawerIsOpen} />
            </Grid>

            <Grid
                item
                container
                sm={4}
                xs={1}
                justify="flex-end"
                className={classes.Grid}
                alignItems="center"
                direction="column"
            >
                <span className={classes.logo} />
            </Grid>
            <Grid item xs={6} sm={7} className={classes.rightGrid}>
                <Grid
                    item
                    xs={11}
                    sm={11}
                    className={classes.internationalization}
                    direction="row"
                    container
                    justify="flex-end"
                >
                    <InternationalizationButton
                        isOpen={internationalizationMenuIsOpen}
                        language={language}
                    />
                </Grid>
                <Grid item xs={11} sm={11} container justify="flex-end">
                    <Typography className={classes.text} variant="h5" color="initial">
                        {setHeaderText()}
                    </Typography>
                </Grid>

                <Grid item xs={11} sm={11} container justify="flex-end" direction="row">
                    {history.location.pathname.split('/').includes('harmonizing-screen') && (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.textActivity}
                                    variant="body1"
                                    color="initial"
                                    align="right"
                                >
                                    {activity?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <HarmonizeCounter />
                            </Grid>
                        </>
                    )}
                    <Typography className={classes.text} variant="h5" color="initial">
                        {history.location.pathname.split('/').includes('panel-info') && (
                            <Button
                                disableFocusRipple
                                disableRipple
                                className={classes.infoToggle}
                                onClick={() => dispatch(showInfoClicked())}
                                endIcon={
                                    isShowingInfo ? (
                                        <ArrowBackIosIcon
                                            style={{ color: '#ffe000', fontSize: '14px' }}
                                        />
                                    ) : (
                                        <ArrowForwardIosIcon
                                            style={{ color: '#ffe000', fontSize: '14px' }}
                                        />
                                    )
                                }
                            >
                                {' '}
                                {isShowingInfo ? (
                                    <Typography variant="caption">
                                        {t(`header:panel-info.hide-info`)}
                                    </Typography>
                                ) : (
                                    <Typography variant="caption">
                                        {t(`header:panel-info.more-info`)}
                                    </Typography>
                                )}{' '}
                            </Button>
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HeaderDesktop;

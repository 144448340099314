import {
    GET_INVOICES,
    GET_INVOICES_SUCCEEDED,
    GET_INVOICES_FAILED,
    ErrorType,
    InvoiceType,
    LOADING_INVOICE,
    PAY_INVOICES,
    PAY_INVOICES_SUCCEEDED,
    PAY_INVOICES_FAILED,
} from './types';

export const getInvoicesAction = (payload: { customer: string; subscription: string }) => ({
    type: GET_INVOICES,
    payload,
});

export const getInvoicesSuccessAction = (payload: { invoices: InvoiceType[] }) => ({
    type: GET_INVOICES_SUCCEEDED,
    payload,
});

export const getInvoicesFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_INVOICES_FAILED,
    payload,
});

export const invoiceLoadingAction = (payload: boolean) => ({
    type: LOADING_INVOICE,
    payload,
});

export const payInvoicesAction = (payload: { invoiceId: string }) => ({
    type: PAY_INVOICES,
    payload,
});

export const payInvoicesSuccessAction = (payload: { invoice: InvoiceType }) => ({
    type: PAY_INVOICES_SUCCEEDED,
    payload,
});

export const payInvoicesFailedAction = (payload: { error: ErrorType }) => ({
    type: PAY_INVOICES_FAILED,
    payload,
});

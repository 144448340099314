/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BigButton from '../../components/utils/Button/BigButton';
import MainLayout from '../../layout/MainLayout';

const useStyles = makeStyles(() => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: 'auto',
        display: 'flex',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        padding: 20,
    },
    button: {
        backgroundColor: '#FFD701',
        color: 'white',
    },
    title: {
        color: '#0468BF',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    subTitle: {
        color: '#0468BF',

        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
    },
    subTitle1: {
        color: 'black',

        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
        fontStyle: 'bold',
    },
    text: {
        color: '#383838',
        fontSize: '16px',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    point: {
        color: '#0468BF',
    },
    white: {
        color: 'white !important',
    },
}));

export default () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    direction="column"
                    style={
                        !isMobile
                            ? { marginLeft: 300, marginTop: 50, marginBottom: 50 }
                            : { marginTop: 50, marginBottom: 50 }
                    }
                >
                    <Grid container justify="flex-end" alignItems="center">
                        <Grid item lg={12} md={12} sm={12}>
                            <Grid item>
                                <Typography className={classes.title} variant="h4" color="initial">
                                    Privacy Policy
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography className={classes.text} variant="h6">
                                    Last updated: Feb 8th, 2020 This Privacy Policy describes Our
                                    policies and procedures on the collection, use and disclosure of
                                    Your information when You use the Service and tells You about
                                    Your privacy rights and how the law protects You. We use Your
                                    Personal data to provide and improve the Service. By using the
                                    Service, You agree to the collection and use of information in
                                    accordance with this Privacy Policy.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        style={{ marginTop: 15 }}
                    >
                        <Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subTitle}
                                    variant="h4"
                                    color="initial"
                                >
                                    Interpretation and Definition
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong> Interpretation </strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className={classes.text} variant="h6">
                                    The words of which the initial letter is capitalized have
                                    meanings defined under the following conditions. The following
                                    definitions shall have the same meaning regardless of whether
                                    they appear in singular or in plural. Definitions For the
                                    purposes of this Privacy Policy:
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        style={{ marginTop: 15 }}
                    >
                        <Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong>Definitions</strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className={classes.text} variant="h6">
                                    For the purposes of this Privacy Policy:
                                </Typography>
                            </Grid>

                            <Grid item>
                                <ul className="ListPrivacyPolicy">
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>You</strong> means the individual accessing or
                                            using the Service, or the company, or other legal entity
                                            on behalf of which such individual is accessing or using
                                            the Service, as applicable.
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong> Company</strong> (referred to as either "the
                                            Company", "We", "Us" or "Our" in this Agreement) refers
                                            to Avalon Apps
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Website</strong> refers to this website.
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Service</strong> refers to the Website.
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Country</strong> refers to: United States of
                                            America
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Service</strong> Provider means any natural or
                                            legal person who processes the data on behalf of the
                                            Company. It refers to third-party companies or
                                            individuals employed by the Company to facilitate the
                                            Service, to provide the Service on behalf of the
                                            Company, to perform services related to the Service or
                                            to assist the Company in analyzing how the Service is
                                            used.
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Personal</strong> Data is any information that
                                            relates to an identified or identifiable individual.
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Cookies</strong> are small files that are placed
                                            on Your computer, mobile device or any other device by a
                                            website, containing the details of Your browsing history
                                            on that website among its many uses.
                                        </span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            <strong>Usage Data</strong> refers to data collected
                                            automatically, either generated by the use of the
                                            Service or from the Service infrastructure itself (for
                                            example, the duration of a page visit).
                                        </span>
                                    </li>
                                </ul>
                                ‍
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subTitle}
                                    variant="h4"
                                    color="initial"
                                >
                                    Collecting and Using Your Personal Data
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong>Types of Data Collected</strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>Personal Data</strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    While using Our Service, We may ask You to provide Us with
                                    certain personally identifiable information that can be used to
                                    contact or identify You. Personally identifiable information may
                                    include, but is not limited to:
                                </Typography>
                            </Grid>

                            <Grid item>
                                <ul className="ListPrivacyPolicy">
                                    <li className={classes.point}>
                                        <span className={classes.text}>Email address</span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>
                                            First name and last name
                                        </span>
                                    </li>

                                    <li className={classes.point}>
                                        <span className={classes.text}>Phone number</span>
                                    </li>
                                    <li className={classes.point}>
                                        <span className={classes.text}>Usage Data</span>
                                    </li>
                                </ul>
                                ‍
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>Usage Data</strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    Usage Data is collected automatically when using the Service.{' '}
                                    <br /> Usage Data may include information such as Your Device's
                                    Internet Protocol address (e.g. IP address), browser type,
                                    browser version, the pages of our Service that You visit, the
                                    time and date of Your visit, the time spent on those pages,
                                    unique device identifiers and other diagnostic data.
                                    <br /> When You access the Service by or through a mobile
                                    device, We may collect certain information automatically,
                                    including, but not limited to, the type of mobile device You
                                    use, Your mobile device unique ID, the IP address of Your mobile
                                    device, Your mobile operating system, the type of mobile
                                    Internet browser You use, unique device identifiers and other
                                    diagnostic data.
                                    <br /> We may also collect information that Your browser sends
                                    whenever You visit our Service or when You access the Service by
                                    or through a mobile device.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>
                                        {' '}
                                        Tracking Technologies and Cookies
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    We use Cookies and similar tracking technologies to track the
                                    activity on Our Service and store certain information. Tracking
                                    technologies used are beacons, tags, and scripts to collect and
                                    track information and to improve and analyze Our Service. You
                                    can instruct Your browser to refuse all Cookies or to indicate
                                    when a Cookie is being sent. However, if You do not accept
                                    Cookies, You may not be able to use some parts of our Service.{' '}
                                    <br /> Cookies can be "Persistent" or "Session" Cookies.
                                    Persistent Cookies remain on your personal computer or mobile
                                    device when You go offline, while Session Cookies are deleted as
                                    soon as You close your web browser. We use both session and
                                    persistent Cookies for the purposes set out below:
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>
                                        {' '}
                                        Necessary / Essential Cookies
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: 5 }}>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    * Type: Session Cookies <br />* Administered by: Us <br /> *
                                    Purpose: These Cookies are essential to provide You with
                                    services available through the Website and to enable You to use
                                    some of its features. They help to authenticate users and
                                    prevent fraudulent use of user accounts. Without these Cookies,
                                    the services that You have asked for cannot be provided, and We
                                    only use these Cookies to provide You with those services.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>
                                        {' '}
                                        Cookies Policy / Notice Acceptance Cookies
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: 5 }}>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    * Type: Persistent Cookies <br /> * Administered by: Us <br /> *
                                    Purpose: These Cookies identify if users have accepted the use
                                    of cookies on the Website.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>
                                        {' '}
                                        Cookies Policy / Notice Acceptance Cookies
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: 5 }}>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    * Type: Persistent Cookies <br /> * Administered by: Us <br /> *
                                    Purpose: These Cookies identify if users have accepted the use
                                    of cookies on the Website. <br /> * Functionality Cookies
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}> Functionality Cookies</strong>
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: 5 }}>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    * Type: Persistent Cookies <br /> * Administered by: Us <br /> *
                                    Purpose: These Cookies allow us to remember choices You make
                                    when You use the Website, such as remembering your login details
                                    or language preference. The purpose of these Cookies is to
                                    provide You with a more personal experience and to avoid You
                                    having to re-enter your preferences every time You use the
                                    Website.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={classes.text}>
                                        {' '}
                                        Tracking and Performance Cookies
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: 5 }}>
                                <Typography className={classes.text} variant="h4" color="initial">
                                    * Type: Persistent Cookies <br /> * Administered by:
                                    Third-Parties <br /> * Purpose: These Cookies are used to track
                                    information about traffic to the Website and how users use the
                                    Website. The information gathered via these Cookies may directly
                                    or indirectly identify you as an individual visitor. This is
                                    because the information collected is typically linked to a
                                    pseudonymous identifier associated with the device you use to
                                    access the Website. We may also use these Cookies to test new
                                    advertisements, pages, features or new functionality of the
                                    Website to see how our users react to them.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        style={{ marginTop: 15 }}
                    >
                        <Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong className={classes.white}>
                                        Use of Your Personal Data
                                    </strong>
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h6"
                                >
                                    The Company may use Personal Data for the following purposes:
                                </Typography>
                            </Grid>

                            <Grid item>
                                <ul className="ListPrivacyPolicy">
                                    <li className={classes.white}>
                                        <span className={`${classes.text} ${classes.white}`}>
                                            <strong className={classes.white}>
                                                {' '}
                                                To provide and maintain our Service
                                            </strong>
                                            <span className={classes.white}>
                                                {' '}
                                                , including to monitor the usage of our Service.
                                            </span>
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={classes.text}>
                                            <strong className={classes.white}>
                                                {' '}
                                                For the performance of a contract
                                            </strong>
                                            <span className={classes.white}>
                                                : the development, compliance and undertaking of the
                                                purchase contract for the products, items or
                                                services You have purchased or of any other contract
                                                with Us through the Service.
                                            </span>
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={classes.text}>
                                            <strong className={classes.white}>
                                                {' '}
                                                To contact You
                                            </strong>
                                            <span className={classes.white}>
                                                {' '}
                                                : To contact You by email, telephone calls, SMS, or
                                                other equivalent forms of electronic communication,
                                                such as a mobile application's push notifications
                                                regarding updates or informative communications
                                                related to the functionalities, products or
                                                contracted services.
                                            </span>
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={classes.text}>
                                            <strong className={classes.white}>
                                                To provide You
                                            </strong>
                                            <span className={classes.white}>
                                                with news, special offers and general information
                                                about other goods, services and events which we
                                                offer that are similar to those that you have
                                                already purchased or enquired about unless You have
                                                opted not to receive such information.
                                            </span>
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={classes.text}>
                                            <strong className={classes.white}>
                                                To manage your requests
                                            </strong>
                                            <span className={classes.white}>
                                                : To attend and manage Your requests to Us.
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                                ‍
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    className={classes.subTitle1}
                                    color="initial"
                                >
                                    <strong className={classes.white}>
                                        {' '}
                                        Retention of Your Personal Data
                                    </strong>
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    The Company will retain Your Personal Data only for as long as
                                    is necessary for the purposes set out in this Privacy Policy. We
                                    will retain and use Your Personal Data to the extent necessary
                                    to comply with our legal obligations (for example, if we are
                                    required to retain your data to comply with applicable laws),
                                    resolve disputes, and enforce our legal agreements and policies.
                                    The Company will also retain Usage Data for internal analysis
                                    purposes. Usage Data is generally retained for a shorter period
                                    of time, except when this data is used to strengthen the
                                    security or to improve the functionality of Our Service, or We
                                    are legally obligated to retain this data for longer time
                                    periods. ‍
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <br />
                        <Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong className={classes.white}>
                                        {' '}
                                        Transfer of Your Personal Data
                                    </strong>
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    Your information, including Personal Data, is processed at the
                                    Company's operating offices and in any other places where the
                                    parties involved in the processing are located. It means that
                                    this information may be transferred to — and maintained on —
                                    computers located outside of Your state, province, country or
                                    other governmental jurisdiction where the data protection laws
                                    may differ than those from Your jurisdiction. Your consent to
                                    this Privacy Policy followed by Your submission of such
                                    information represents Your agreement to that transfer. The
                                    Company will take all steps reasonably necessary to ensure that
                                    Your data is treated securely and in accordance with this
                                    Privacy Policy and no transfer of Your Personal Data will take
                                    place to an organization or a country unless there are adequate
                                    controls in place including the security of Your data and other
                                    personal information.‍
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="center">
                        <Grid>
                            <br />
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong className={classes.white}>
                                        {' '}
                                        Disclosure of Your Personal Data{' '}
                                    </strong>
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={`${classes.text} ${classes.white}`}>
                                        {' '}
                                        Business Transactions
                                    </strong>
                                </Typography>
                            </Grid>

                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    If the Company is involved in a merger, acquisition or asset
                                    sale, Your Personal Data may be transferred. We will provide
                                    notice before Your Personal Data is transferred and becomes
                                    subject to a different Privacy Policy.
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={`${classes.text} ${classes.white}`}>
                                        {' '}
                                        Law enforcement
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    Under certain circumstances, the Company may be required to
                                    disclose Your Personal Data if required to do so by law or in
                                    response to valid requests by public authorities (e.g. a court
                                    or a government agency).
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={`${classes.text} ${classes.white}`}>
                                        {' '}
                                        Other legal requirements
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    The Company may disclose Your Personal Data in the good faith
                                    belief that such action is necessary to:
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <ul className="ListPrivacyPolicy">
                                    <li className={classes.white}>
                                        <span className={`${classes.text} ${classes.white}`}>
                                            Comply with a legal obligation
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={`${classes.text} ${classes.white}`}>
                                            Protect and defend the rights or property of the Company
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={`${classes.text} ${classes.white}`}>
                                            Prevent or investigate possible wrongdoing in connection
                                            with the Service
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={`${classes.text} ${classes.white}`}>
                                            Protect the personal safety of Users of the Service or
                                            the public
                                        </span>
                                    </li>
                                    <li className={classes.white}>
                                        <span className={`${classes.text} ${classes.white}`}>
                                            Protect against legal liability
                                        </span>
                                    </li>
                                </ul>
                                ‍
                            </Grid>

                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={`${classes.text} ${classes.white}`}>
                                        {' '}
                                        Security of Your Personal Data
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    The security of Your Personal Data is important to Us, but
                                    remember that no method of transmission over the Internet, or
                                    method of electronic storage is 100% secure. While We strive to
                                    use commercially acceptable means to protect Your Personal Data,
                                    We cannot guarantee its absolute security. ‍
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={`${classes.text} ${classes.white}`}>
                                        {' '}
                                        Changes to this Privacy Policy
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    We may update our Privacy Policy from time to time. We will
                                    notify You of any changes by posting the new Privacy Policy on
                                    this page. We will let You know via email and/or a prominent
                                    notice on Our Service, prior to the change becoming effective
                                    and update the "Last updated" date at the top of this Privacy
                                    Policy. You are advised to review this Privacy Policy
                                    periodically for any changes. Changes to this Privacy Policy are
                                    effective when they are posted on this page.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" color="initial">
                                    <strong className={`${classes.text} ${classes.white}`}>
                                        {' '}
                                        Contact Us
                                    </strong>
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginTop: 5 }}>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    If you have any questions about this Privacy Policy, You can
                                    contact us:
                                </Typography>
                                <Typography
                                    className={`${classes.text} ${classes.white}`}
                                    variant="h4"
                                    color="initial"
                                >
                                    <ul className="ListPrivacyPolicy">
                                        <li>
                                            <a
                                                className={`${classes.text} ${classes.white}`}
                                                href="info@avalon-apps.com"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                info@waterharmonizer.com
                                            </a>
                                        </li>
                                    </ul>
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item lg={2} md={2} sm={12}>
                                <BigButton
                                    title="Go Back"
                                    onClick={() => {
                                        history.push('/support');
                                    }}
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </MainLayout>
    );
};

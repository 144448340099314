import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    avatar: {
        margin: theme.spacing(8),
        width: '210px',
        height: '289px',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },
    header: {
        color: 'inherit',
        marginTop: theme.spacing(1),
        fontFamily: ['Poppins', 'regular'].join(','),
        textTransform: 'none',
        fontSize: '36px',
    },
    message: {
        color: 'inherit',
        marginTop: theme.spacing(4),
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '28px',
        fontWeight: 400,
    },
}));

export default function SendMailSuccess() {
    const classes = useStyles();
    const history = useHistory();
    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        history.push('/');
    };

    return (
        <Container maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar variant="square" className={classes.avatar} src="/logo.png" />
                <Typography className={classes.header}>Mail sent successfully</Typography>
                <Typography className={classes.message}>We will contact you in 24 hours</Typography>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.submit}
                >
                    Go to Home
                </Button>
            </div>
        </Container>
    );
}

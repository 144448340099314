import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import BigButton from '../utils/Button/BigButton';

const useStyle = makeStyles<Theme>(() => ({
    infoName: {
        margin: '30px',
        fontWeight: 900,
        color: '#0468BF',
        fontSize: 36,
    },
    infoDescription: {
        fontFamily: ['Poppins', 'regular'].join(','),
        marginBottom: '30px',
        color: '#383838',
        fontSize: '16px',
    },
}));

const InfoPanel = ({
    panel,
    validRedirectOnClick,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    panel: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validRedirectOnClick: any;
}) => {
    const classes = useStyle();
    const { t } = useTranslation(['infoPanel']);

    return (
        <Grid
            item
            container
            xs={6}
            sm={6}
            justify="center"
            alignContent="center"
            alignItems="center"
            direction="column"
            style={{ marginTop: -500 }}
        >
            <Grid item>
                <Grid container>
                    <Grid item>
                        <img
                            src={`../assets/menu/${panel?.logo}.png`}
                            height={90}
                            alt={panel?.name}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" align="left" className={classes.infoName}>
                            {panel?.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Typography
                    variant="body1"
                    align="left"
                    paragraph
                    className={classes.infoDescription}
                >
                    {panel?.description}
                </Typography>
            </Grid>
            {panel && (
                <BigButton
                    title={t('infoPanel:Button')}
                    type="primary"
                    job="button"
                    onClick={validRedirectOnClick}
                />
            )}
        </Grid>
    );
};

export default InfoPanel;

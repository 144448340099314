import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import BigButton from '../utils/Button/BigButton';
import { createSubscriptionAction } from '../../redux/subscription/actions';
import useOnlineDispatch from '../OnlineDispatch';

const UpgradeSubscription = () => {
    const onlineDispatch = useOnlineDispatch();
    const user = useSelector((state: GlobalStateType) => state.user);
    const { selectedPrice } = useSelector((state: GlobalStateType) => state.prices);

    const handleUpgradeSubscription = () => {
        if (selectedPrice?.id && user?.stripeCustomerID && user.defaultPaymentMethodID)
            onlineDispatch(
                createSubscriptionAction({
                    customerID: user?.stripeCustomerID,
                    priceID: selectedPrice.id,
                    paymentMethodID: user.defaultPaymentMethodID,
                    user,
                }),
            );
    };

    return (
        <BigButton
            title="Reactivate Subscription"
            type="primary"
            onClick={handleUpgradeSubscription}
        />
    );
};

export default UpgradeSubscription;

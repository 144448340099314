export const PANELS_KEY = 'PANELS';
export interface ActivityType {
    id: string;
    name: string;
    description: string;
    logo: string;
    media: string;
}

export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    payload: {
        panel?: PanelType;
        panels?: PanelType[];
        error?: ErrorType;
    };
}

export interface PanelType {
    name: string;
    description: string;
    logo: string;
    id: string;
    media?: string;
    ingredients?: string[];
}

export interface PanelsType {
    panels: PanelType[];
}

// Replace with your PANEL types
export const GET_PANEL = 'GET_PANEL';
export const GET_PANEL_SUCCEEDED = 'GET_PANEL_SUCCEEDED';
export const GET_PANEL_FAILED = 'GET_PANEL_FAILED';

export const LIST_PANEL = 'LIST_PANEL';
export const LIST_PANEL_SUCCEEDED = 'LIST_PANEL_SUCCEEDED';
export const LIST_PANEL_FAILED = 'LIST_PANEL_FAILED';

export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';
export const SET_CURRENT_ACTIVITY_SUCCEEDED = 'SET_CURRENT_ACTIVITY_SUCCEEDED';
export const SET_CURRENT_ACTIVITY_FAILED = 'SET_CURRENT_ACTIVITY_FAILED';
export const RESET_HARMONIZE_TIME = 'RESET_HARMONIZE_TIME';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import useOnlineDispatch from '../OnlineDispatch';
import { listPriceAction } from '../../redux/prices/actions';
import { GlobalStateType } from '../../redux/rootTypes';
import { getProductAction } from '../../redux/product/actions';
import { ColorCircularProgress } from '../Loader';

const SubscriptionInfo = () => {
    const onlineDispatch = useOnlineDispatch();
    const { loading: subscriptionLoading } = useSelector(
        (state: GlobalStateType) => state.subscription,
    );
    const { loading: pricesLoading, data: prices } = useSelector(
        (state: GlobalStateType) => state.prices,
    );
    const { data: invoices } = useSelector((state: GlobalStateType) => state.invoices);
    const product = useSelector((state: GlobalStateType) => state.product.data[0]);
    const { subscription, loading: userLoading } = useSelector(
        (state: GlobalStateType) => state.user,
    );
    const [price, setPrice] = useState(
        prices?.find((p) => p.id === subscription?.pricePlan && subscription?.pricePlan),
    );
    const [lastChargeDate, setLastChargeDate] = useState<number | undefined>();
    const [nextChargeDate, setNextChargeDate] = useState<number | undefined>();

    useEffect(() => {
        setPrice(prices?.find((p) => p.id === subscription?.pricePlan && subscription?.pricePlan));
    }, [prices, subscription]);

    useEffect(() => {
        if (!product?.id) onlineDispatch(getProductAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    useEffect(() => {
        if (prices.length === 0 && product?.id)
            onlineDispatch(listPriceAction({ productID: product?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, prices]);

    const paymentStatus = () => {
        switch (subscription?.subscriptionStatus) {
            case 'active':
            case 'trialing':
                return 'active';
            case 'canceled':
                return 'canceled';
            default:
                return 'unpaid';
        }
    };

    const getLastChargeDate = (): number | undefined => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filteredPaidInvoices: any[] = (invoices || []).filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (invoice: any) => invoice && invoice.paid,
        );
        // eslint-disable-next-line no-console
        console.log(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            filteredPaidInvoices.map((i: any) =>
                new Date(i.status_transitions.paid_at * 1000).toLocaleString(),
            ),
        );
        filteredPaidInvoices.sort(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (i1: any, i2: any) => i2.status_transitions?.paid_at - i1.status_transitions?.paid_at,
        );
        return filteredPaidInvoices[0]?.status_transitions?.paid_at;
    };

    useEffect(() => {
        const lastCharge = getLastChargeDate();
        setLastChargeDate(lastCharge);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]);

    const getNextChargeDate = (): number | undefined => {
        if (subscription?.subscriptionStatus !== 'canceled' && !subscription?.pauseCollection) {
            return subscription?.currentPeriodEnd;
        }
        return undefined;
    };

    useEffect(() => {
        const nextCharge = getNextChargeDate();
        setNextChargeDate(nextCharge);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);
    // console.log('=====:', paymentStatus());
    return subscriptionLoading || userLoading ? (
        <ColorCircularProgress />
    ) : (

        <Grid item >
            <p>
                <>
                    <strong>Payment Status: </strong>
                    {paymentStatus()}{' '}
                    {paymentStatus() === 'active' && subscription?.cancelAt && (
                        <strong style={{ color: 'red' }}>
                            (Until: {new Date(subscription?.cancelAt * 1000).toLocaleString()})
                        </strong>
                    )}
                </>
            </p>

            <>
                {price ? (
                    <>
                        <p>
                            Your actual payment plan is{' '}
                            <strong>${(price?.unit_amount || 100) / 100} FOUR PAYMENTS </strong>{' '}
                        </p>
                        {lastChargeDate && (
                            <p>
                                Last charge was on{' '}
                                <strong>{new Date(lastChargeDate * 1000).toLocaleString()}</strong>{' '}
                            </p>
                        )}
                        {nextChargeDate && (
                            <p>
                                Next charge will be on{' '}
                                <strong>{new Date(nextChargeDate * 1000).toLocaleString()}</strong>{' '}
                            </p>
                        )}
                    </>
                ) : (
                    <>{pricesLoading ? <ColorCircularProgress /> : null}</>
                )}
            </>
        </Grid>
    );
};

export default SubscriptionInfo;

import React, { useState } from 'react';
import {
    makeStyles,
    createStyles,
    TextField,
    Backdrop,
    CircularProgress,
    Grid,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntent } from '../../redux/payments/apis';
import { GlobalStateType } from '../../redux/rootTypes';
import { updatePaymentFlowAction } from '../../redux/user/actions';

const cardStyle = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#32325d',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

const useStyles = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#1B7FD5',
        },
        email: {
            padding: 5,
            marginBottom: 30,
            marginTop: 30,
            backgroundColor: 'transparent',
            border: '1px solid transparent',
        },
        buttonDisabled: {
            border: '1px solid transparent',
            marginTop: 30,
            padding: 5,
            borderRadius: '12px',
            color: 'white',
            backgroundColor: 'grey',
            textTransform: 'none',
            fontFamily: ['Poppins', 'medium'].join(','),
            fontSize: '26px',
            width: '289px',
            height: '64px',
        },
        button: {
            border: '1px solid transparent',
            marginTop: 30,
            padding: 5,
            borderRadius: '12px',
            color: 'white',
            backgroundColor: '#00BBFF',
            textTransform: 'none',
            fontFamily: ['Poppins', 'medium'].join(','),
            fontSize: '26px',
            width: '289px',
            height: '64px',
            '&:hover': {
                backgroundColor: 'white',
                color: '#00BBFF',
            },
            '&:focus': {
                backgroundColor: 'white',
                color: '#00BBFF',
            },
        },
    }),
);

export default function CheckoutForm() {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [complete, setComplete] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const { selectedPrice } = useSelector((state: GlobalStateType) => state.prices);
    const user = useSelector((state: GlobalStateType) => state.user);
    const [email, setEmail] = useState('');
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = async (event: any) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        // console.log(event);
        setComplete(event.complete);
        setDisabled(event.empty);
        setError(event.error ? event.error.message : '');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setProcessing(true);

        if (selectedPrice.nickname && selectedPrice.nickname.indexOf('ONE') > -1) {
            if (user.payment?.paymentIntents && user.payment.paymentIntents.length > 0) {
                return;
            }

            const secret = await createPaymentIntent(selectedPrice.nickname);
            const payload = await stripe?.confirmCardPayment(secret, {
                receipt_email: email,
                payment_method: {
                    card: elements?.getElement(CardElement) || { token: '' },
                    billing_details: {
                        name: user.name,
                        email: user.email,
                    },
                },
            });

            if (payload?.error) {
                setError(`Payment failed ${payload.error.message}`);
                setProcessing(false);
            } else {
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                dispatch(updatePaymentFlowAction('pending'));
            }
        }
    };

    const disableButton = () => {
        return processing || disabled || succeeded || !complete;
    };

    return (
        <Grid container justify={isMobile ? 'center' : 'flex-start'}>
            <Grid item>
                <Backdrop className={classes.backdrop} open={processing}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        className={classes.email}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Cc Invoice to Payment"
                    />

                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                    {selectedPrice && (
                        <button
                            className={disableButton() ? classes.buttonDisabled : classes.button}
                            disabled={disableButton()}
                            id="submit"
                            type="submit"
                        >
                            <span id="button-text">
                                {processing ? <div className="spinner" id="spinner" /> : 'Pay now'}
                            </span>
                        </button>
                    )}
                    {/* Show any error that happens when processing the payment */}
                    {error && (
                        <div className="card-error" role="alert">
                            {error}
                        </div>
                    )}
                </form>
            </Grid>
        </Grid>
    );
}

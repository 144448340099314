import React from 'react';

import BillinDetails from '../../components/BillingDetails/BillingDetails';
import MainLayout from '../../layout/MainLayout';

const BillingDetailsPage = () => {
    return (
        <MainLayout>
            <BillinDetails />
        </MainLayout>
    );
};

export default BillingDetailsPage;

import { call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import {
    cancelSubscription,
    createSubscription,
    upgradeSubscription,
    pauseSubscription,
    getUserSubscription,
} from './apis';
import {
    CANCEL_SUBSCRIPTION,
    CREATE_SUBSCRIPTION,
    GET_SUBSCRIPTION,
    PAUSE_SUBSCRIPTION,
    SubscriptionType,
    UPGRADE_SUBSCRIPTION,
} from './types';
import {
    getSubscriptionSuccessAction,
    getSubscriptionFailedAction,
    subscriptionLoadingAction,
    cancelSubscriptionSuccessAction,
    cancelSubscriptionFailedAction,
    createSubscriptionSuccessAction,
    createSubscriptionFailedAction,
    upgradeSubscriptionSuccessAction,
    upgradeSubscriptionFailedAction,
    pauseSubscriptionSuccessAction,
    pauseSubscriptionFailedAction,
} from './actions';
import { updateUserSubscriptionFromStripe } from '../user/apis';
import {
    getUserSuccessAction,
    updatePaymentFlowAction,
    updateUserAction,
    updateUserSuccessAction,
} from '../user/actions';
import { UserType, USER_KEY } from '../user/types';
import { getItem } from '../../utils/localStorage';

function* sagaCancelSubscription(action: AnyAction) {
    try {
        yield put(subscriptionLoadingAction(true));
        const subscription: SubscriptionType = yield call(
            cancelSubscription,
            action.payload.subscriptionID,
        );
        const localUser: UserType = getItem(USER_KEY);
        const user: UserType = {
            ...localUser,
            subscription: { ...localUser.subscription, ...subscription },
        };
        yield put(getUserSuccessAction({ user }));
        yield put(updateUserAction(user));
        yield put(subscriptionLoadingAction(false));
        yield put(cancelSubscriptionSuccessAction());
    } catch (error) {
        yield put(cancelSubscriptionFailedAction(error));
    }
}

export function* cancelSubscriptionWatcher() {
    yield takeLatest(CANCEL_SUBSCRIPTION, sagaCancelSubscription);
}

function* fetchSubscription(action: AnyAction) {
    try {
        yield put(subscriptionLoadingAction(true));
        const response: { subscription: SubscriptionType } = yield call(
            getUserSubscription,
            action.payload,
        );
        // console.log('saga getSubscription: ', response);
        if (response.subscription) {
            const user: UserType = yield call(
                updateUserSubscriptionFromStripe,
                response?.subscription,
            );
            if (user) yield put(updateUserSuccessAction(user));
        }
        yield put(getSubscriptionSuccessAction(response));
        yield put(subscriptionLoadingAction(false));
    } catch (error) {
        yield put(getSubscriptionFailedAction(error));
    }
}

export function* getSubscriptionWatcher() {
    yield takeLatest(GET_SUBSCRIPTION, fetchSubscription);
}

function* createSubscriptionSaga(action: AnyAction) {
    try {
        yield put(subscriptionLoadingAction(true));
        // yield call(updateUserFromCreateSubscription, action.payload);
        const response: UserType = yield call(createSubscription, action.payload);
        yield put(updatePaymentFlowAction('pending'));
        yield put(subscriptionLoadingAction(false));
        if (response.subscription)
            yield put(createSubscriptionSuccessAction({ subscription: response.subscription }));
        yield put(updateUserSuccessAction(response));
        yield put(updateUserAction(response));
    } catch (error) {
        yield put(createSubscriptionFailedAction({ error }));
    }
}

export function* createSubscriptionWatcher() {
    yield takeLatest(CREATE_SUBSCRIPTION, createSubscriptionSaga);
}

function* upgradeSubscriptionSaga(action: AnyAction) {
    try {
        yield put(subscriptionLoadingAction(true));
        const response: { subscription: SubscriptionType } = yield call(
            upgradeSubscription,
            action.payload,
        );
        yield put(upgradeSubscriptionSuccessAction(response));
        yield put(subscriptionLoadingAction(false));
    } catch (error) {
        yield put(upgradeSubscriptionFailedAction({ error }));
    }
}

export function* upgradeSubscriptionWatcher() {
    yield takeLatest(UPGRADE_SUBSCRIPTION, upgradeSubscriptionSaga);
}

function* pauseSubscriptionSaga(action: AnyAction) {
    try {
        yield put(subscriptionLoadingAction(true));
        const response: { subscription: SubscriptionType } = yield call(
            pauseSubscription,
            action.payload,
        );
        yield put(pauseSubscriptionSuccessAction(response));
        yield put(subscriptionLoadingAction(false));
    } catch (error) {
        yield put(pauseSubscriptionFailedAction({ error }));
    }
}

export function* pauseSubscriptionWatcher() {
    yield takeLatest(PAUSE_SUBSCRIPTION, pauseSubscriptionSaga);
}

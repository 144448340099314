import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as AlertIcon } from '../../../assets/svg_icons/alert.svg';

type Props = {
    msg: string;
};

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            borderRadius: '10px',
            backgroundColor: 'white',
            padding: '5px 0',
            margin: '15px 0',
            alignItems: 'center',
        },
    }),
);

const Alert: React.FC<Props> = ({ msg }: Props) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.container} spacing={1}>
            <Grid item container xs={1} justify="center">
                <AlertIcon width="17" height="17" fill="#cedcf0" />
            </Grid>
            <Grid item xs={10}>
                <Typography variant="caption">{msg}</Typography>
            </Grid>
            <Grid container item xs={1} justify="center">
                <CloseIcon style={{ width: '15', height: '15' }} />
            </Grid>
        </Grid>
    );
};

export default Alert;

import React, { useState } from 'react';
// import MainLayout from '../../layout/MainLayout';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import SignUp from '../../components/Auth/SignUp';
import Background from '../../assets/bg.png';

export default () => {
    const [showPage, setShowPage] = useState(false);
    return (
        <div
            style={{
                minHeight: '100vh',
                height: 'auto',
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
            }}
        >
            <SignUp setShowPage={setShowPage} />
        </div>
    );
};

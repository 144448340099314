import { call, put, takeLatest, StrictEffect } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import sendEmail from './apis';
import { SEND_EMAIL, MailMessageType } from './types';
import {
    sendEmailSuccessAction,
    sendEmailFailedAction,
    setEmailSendingStatusAction,
} from './actions';
import { loaderOff, loaderOn } from '../loader/actions';

// Upload the specified file
export function* sendEmailSaga(action: AnyAction): Generator<StrictEffect, void, MailMessageType> {
    try {
        yield put(loaderOn());
        yield put(setEmailSendingStatusAction({ email: action.payload }));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = yield call<any>(sendEmail, action.payload);
        yield put(sendEmailSuccessAction({ response }));
        yield put(loaderOff());
    } catch (error) {
        yield put(sendEmailFailedAction(error));
        yield put(loaderOff());
    }
}

export function* sendEmailWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(SEND_EMAIL, sendEmailSaga);
}

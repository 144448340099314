import React from 'react';
import BillingHistory from '../../components/BillingHistory/BillingHistory';
import MainLayout from '../../layout/MainLayout';

export default () => {
    return (
        <MainLayout>
            <BillingHistory />
        </MainLayout>
    );
};

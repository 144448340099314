import { getItem, setItem } from '../../utils/localStorage';
import {
    ActionType,
    GET_PRICE_SUCCEEDED,
    GET_PRICE_FAILED,
    PriceType,
    ErrorType,
    LIST_PRICE_SUCCEEDED,
    LIST_PRICE_FAILED,
    LOADING_PRICES,
    PRICES_KEY,
    SET_SELECTED_PRICE,
    SET_SELECTED_PRICE_ERROR,
} from './types';

const storedPrices = getItem(PRICES_KEY);

const initialState: {
    data: PriceType[];
    error: ErrorType | null;
    loading: boolean;
    selectedPrice: PriceType | null;
} = {
    data: [],
    selectedPrice: null,
    ...storedPrices,
    error: null,
    loading: false,
};

export default function reducer(state = initialState, action: ActionType) {
    // console.log('price reducer: ', action);
    let localState;
    switch (action.type) {
        case GET_PRICE_SUCCEEDED:
            if (!action?.payload?.price?.id) {
                const filter = state.data.filter((p) => p.id !== action?.payload?.price?.id);
                localState = {
                    ...state,
                    data: [...filter, action.payload.price],
                };
            }
            break;
        case GET_PRICE_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case LIST_PRICE_SUCCEEDED:
            localState = {
                ...state,
                data: action.payload?.prices,
                error: null,
            };
            break;
        case LIST_PRICE_FAILED:
            localState = {
                ...state,
                error: action.payload.error,
                loading: false,
            };
            break;
        case LOADING_PRICES:
            localState = {
                ...state,
                loading: action.payload,
            };
            break;
        case SET_SELECTED_PRICE:
            localState = {
                ...state,
                loading: false,
                selectedPrice: state.data.find((p) => p.id === action?.payload?.price?.id),
                error: null,
            };
            break;
        case SET_SELECTED_PRICE_ERROR:
            localState = {
                ...state,
                loading: false,
                error: action.payload.error,
            };
            break;
        default:
            localState = state;
    }
    setItem(PRICES_KEY, localState);
    return localState;
}

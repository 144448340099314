import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import useOnlineDispatch from '../OnlineDispatch';
import { GlobalStateType } from '../../redux/rootTypes';
import PricesCards from '../BillingPricesCards';
import { setSelectedPriceAction } from '../../redux/prices/actions';
import Line from '../Line';
import useValidateSubscription from '../BillingStatus/validateBilling';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textContainer: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
            '& p': {
                padding: '6px',
            },
            '& #title': {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            '& #small': {
                textTransform: 'uppercase',
                fontSize: '10px',
            },
        },
        btnContainer: {
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                textAlign: 'center',
                padding: '25px',
            },
        },
        priceContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
        price: {
            width: '300px',
            border: '2px solid #0468BF',
            textAlign: 'center',
            margin: '5px',
            cursor: 'pointer',
            transition: 'all 120ms',
            outline: 'none',
            '&:hover': {
                border: '2px solid #1cc00a',
                boxShadow: '0px 0px 5px #4a4a4a',
            },
            '&:focus': {
                border: '2px solid #1cc00a !important                   ',
                boxShadow: '0px 0px 5px #4a4a4a',
            },
            [theme.breakpoints.down('sm')]: {
                minwidth: '220',
                margin: '25px',
            },
            '& p': {
                fontSize: '28px',
                margin: '16px auto',
                fontWeight: 'bolder',
            },
            '& span:nth-child(1)': {
                color: '#1cc00a',
            },
        },
        selectedPrice: {
            border: '2px solid #1cc00a',
            boxShadow: '0px 0px 5px #4a4a4a',
        },
        mobileTitle: {
            textAlign: 'center',
            color: '#0468BF',
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline',
                visibility: 'visible',
            },
        },
    }),
);

const PriceList = () => {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const {
        data: prices,
        error,
        selectedPrice,
    } = useSelector((state: GlobalStateType) => state.prices);
    const { subscription, payment } = useSelector((state: GlobalStateType) => state.user);
    const [showPriceList, setShowPriceList] = useState(false);
    const isValid = useValidateSubscription();

    useEffect(() => {
        const price = prices[0];
        if (price && !selectedPrice) onlineDispatch(setSelectedPriceAction({ price }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prices, selectedPrice]);

    useEffect(() => {
        if (isValid) {
            setShowPriceList(false);
            return;
        }
        if (subscription?.subscriptionStatus === 'canceled') {
            setShowPriceList(true);
            return;
        }
        setShowPriceList(true);
    }, [isValid, subscription, payment]);

    return showPriceList ? (
        <>
            <Line />
            <Grid>
                <Grid item>{error && <div>{error.message}</div>}</Grid>
                <Grid className={classes.textContainer}>
                    <PricesCards />
                </Grid>
            </Grid>
        </>
    ) : null;
};

export default PriceList;

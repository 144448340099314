import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        label: {
            color: 'white',
            marginTop: theme.spacing(2),
            fontFamily: ['Poppins', 'regular'].join(','),
            fontSize: '17px',
            fontWeight: 400,
        },
        link: {
            color: 'white',
            fontFamily: ['Poppins', 'regular'].join(','),
            textTransform: 'none',
        },
    }),
);

const { REACT_APP_GOOGLE_DRIVE_TROUBLESHOOTING, REACT_APP_GOOGLE_DRIVE_SIGNUP } = process.env;

export default function SignInManualButton() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid item xs="auto" md="auto">
                <List dense>
                    <Typography variant="h6" className={classes.label}>
                        Having trouble? Please see our guides:
                    </Typography>
                    <ListItem>
                        <ListItemText>
                            <Link
                                color="primary"
                                href={REACT_APP_GOOGLE_DRIVE_SIGNUP}
                                target="_blank"
                                className={classes.link}
                                rel="noreferrer"
                            >
                                SignUp Guide
                                <IconButton edge="end" aria-label="open">
                                    <OpenInNewIcon />
                                </IconButton>
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Link
                                color="primary"
                                href={REACT_APP_GOOGLE_DRIVE_TROUBLESHOOTING}
                                target="_blank"
                                className={classes.link}
                                rel="noreferrer"
                            >
                                TroubleShooting Guide
                                <IconButton edge="end" aria-label="open">
                                    <OpenInNewIcon />
                                </IconButton>
                            </Link>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
        </div>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core';
import MainLayout from '../../layout/MainLayout';
import SendMail from '../../components/SendMail/SendMailForm';

const useStyles = makeStyles((theme) => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(800)]: {
            background: '#ECF2FE',
            margin: '5px !important',
        },
    },
}));

export default () => {
    const classes = useStyles();
    return (
        <MainLayout>
            <div className={classes.bg}>
                <SendMail />
            </div>
        </MainLayout>
    );
};

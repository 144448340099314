import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Zoom from '@material-ui/core/Zoom';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { auraRoutes, routes, RouteType } from '../../routes/routes';
import { logoutAction } from '../../redux/user/actions';
import { toggleLeftSideMenu } from '../../redux/app/actions';
import { GlobalStateType } from '../../redux/rootTypes';
import UserAvatar from '../Avatar';
import useOnlineDispatch from '../OnlineDispatch';

const useStyles = makeStyles<Theme>((theme) => ({
    menu: {
        width: 279,
        backgroundColor: '#0468BF',
        overflow: 'hidden',
        [theme.breakpoints.down(300)]: {
            width: '100%',
        },
    },
    fullList: {
        width: 'auto',
    },
    headerMenu: {
        backgroundColor: '#0059A6',
        height: 148,
        position: 'relative',
        zIndex: 99,
    },
    drawer: {
        marginTop: '34px',
        marginLeft: '30px',
        width: '24px',
        height: '22px',

        opacity: 1,
        [theme.breakpoints.down(800)]: {
            marginTop: '34px',
            marginLeft: '30px',
        },
    },
    title: {
        color: 'white',
        fontSize: 14,
        marginTop: 5,
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    titleCustom: {
        color: 'white',
        fontSize: 14,
        marginTop: 5,
        fontFamily: ['Poppins', 'regular'].join(','),
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    link: {
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    userContent: {
        padding: '10px',
        margin: '0'
    },
    section: {
        overflowY: 'auto',
        height: 'calc(100vh - 148px)',
        display: 'block',
        background: '#1b6cba',
        paddingBottom: '24px',
    },
    avatar: {
        background: "transparent url('assets/userIcon.svg') 0% 0% no-repeat padding-box",
        with: 55,
        height: 55,
        marginTop: 5,
    },
}));

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ListItem button component="a" style={{ color: 'white', paddingTop: '10px' }} {...props} />
    );
}

interface Props {
    isOpen: boolean;
}

function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

const SideMenu = ({ isOpen }: Props) => {
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation(['sidemenu']);
    const user = useSelector((state: GlobalStateType) => state.user);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPageSlug, setCurrentPageSlug] = useState('');

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const url = "https://hvz6syv857.execute-api.us-east-1.amazonaws.com/testing/listSavedUsers";
        //         const requestData = {
        //             user_id: "6",
        //         };

        //         const response = await fetch(url, {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify(requestData),
        //         });

        //         if (response) {
        //             setDataLoaded(true);
        //         } else {
        //             setDataLoaded(false);
        //         }
        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //         setDataLoaded(false);
        //     }
        // };
        // fetchData();
        const currentPathname = window.location.pathname;
        const parts = currentPathname.split('/');
        const slug = parts[parts.length - 1];
        setCurrentPageSlug(slug);
        // chkraCenter();
    }, []);
    // chkraCenter();

    const handleLogout = () => {
        deleteCookie('ScanImage');
        localStorage.removeItem('user');
        onlineDispatch(logoutAction());
    };

    return (
        <React.Fragment key="left">
            <Drawer
                anchor="left"
                open={isOpen}
                classes={{ paper: classes.menu }}
                onClose={() => dispatch(toggleLeftSideMenu(isOpen))}
            >
                <Grid container direction="row" className={classes.headerMenu}>
                    <Grid container item justify="flex-start">
                        <IconButton
                            onClick={() => {
                                dispatch(toggleLeftSideMenu(isOpen));
                            }}
                            className={classes.drawer}
                        >
                            <Zoom in={isOpen} timeout={{ enter: 1000 }}>
                                <CloseIcon fontSize="large" style={{ color: '#fbd402' }} />
                            </Zoom>
                        </IconButton>
                    </Grid>

                    <Grid
                        container
                        className={classes.userContent}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="center"
                        justify="center"
                        spacing={2}
                    >
                        <Grid item xs={3}>
                            <UserAvatar />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography className={classes.title} variant="body2">
                                {t(`sidemenu:welcome`)} {user?.name}
                            </Typography>
                        </Grid>
                    </Grid>

                    { /*  {currentPageSlug && currentPageSlug === 'chakra-screen' && (
                        <Grid
                            container
                            className={classes.userContent}
                            direction="row"
                            alignContent="flex-start"
                            alignItems="center"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item xs={3}>
                                <Link to="/home">
                                    <UserAvatar />
                                </Link>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography className={classes.titleCustom} variant="body2">
                                    {t(`sidemenu:Main Chakra Centers`)}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {currentPageSlug && currentPageSlug === 'chakra-screen' && (
                    <Grid container direction="column" className={classes.chakraList}>
                        <ListItemLink button onClick='#'>
                            <ListItemText className={classes.link} primary='Crown Chakra' />
                            <span>{crownChakra}</span>
                        </ListItemLink>
                        <ListItemLink button onClick='#'>
                            <ListItemText className={classes.link} primary='Brow Chakra' />
                            <span>{browChakra}</span>
                        </ListItemLink>
                        <ListItemLink button onClick='#' className={classes.activeMenu}>
                            <ListItemText className={`${classes.link}`} primary='Throat Chakra ' />
                            <span>{throatChakra}</span>
                        </ListItemLink>
                        <ListItemLink button onClick='#'>
                            <ListItemText className={classes.link} primary='Heart Chakra' />
                            <span>{heartChakra}</span>
                        </ListItemLink>
                        <ListItemLink button onClick='#' className={classes.activeMenu}>
                            <ListItemText className={`${classes.link}`} primary='Solar Chakra' />
                            <span>{solarChakra}</span>
                        </ListItemLink>
                        <ListItemLink button onClick='#'>
                            <ListItemText className={classes.link} primary='Sacral Chakra' />
                            <span>{sacralChakra}</span>
                        </ListItemLink>
                        <ListItemLink button onClick='#'>
                            <ListItemText className={classes.link} primary='Root Chakra 209' />
                            <span>{rootChakra}</span>
                        </ListItemLink>
                    </Grid>
                )} */}

                    <Grid container direction="column" className={classes.section}>
                        <List component="nav">
                            <ListItemLink
                                key={0}
                                button
                                onClick={() => {
                                    history.push('/about');
                                    dispatch(toggleLeftSideMenu(isOpen));
                                }}
                            >
                                <ListItemText className={classes.link} primary={t(`sidemenu:about`)} />
                            </ListItemLink>
                            <ListItemLink
                                onClick={() => {
                                    history.push('/solfeggio-frequencies');
                                    dispatch(toggleLeftSideMenu(isOpen));
                                }}
                                key={1}
                                button
                            >
                                <ListItemText
                                    className={classes.link}
                                    primary={t(`sidemenu:solfeggio`)}
                                />
                            </ListItemLink>
                            <ListItemLink
                                onClick={() => {
                                    history.push('/disclaimer');
                                    dispatch(toggleLeftSideMenu(isOpen));
                                }}
                                key={11}
                                button
                            >
                                <ListItemText
                                    className={classes.link}
                                    primary={t(`sidemenu:disclaimer`)}
                                />
                            </ListItemLink>
                            <ListItemLink
                                onClick={() => {
                                    history.push('/messages');
                                    dispatch(toggleLeftSideMenu(isOpen));
                                }}
                                key={2}
                                button
                            >
                                <ListItemText
                                    className={classes.link}
                                    primary={t(`sidemenu:messages`)}
                                />
                            </ListItemLink>
                            <ListItemLink
                                onClick={() => {
                                    history.push('/privacy-policy');
                                    dispatch(toggleLeftSideMenu(isOpen));
                                }}
                                key={3}
                                button
                            >
                                <ListItemText className={classes.link} primary={t(`sidemenu:terms`)} />
                            </ListItemLink>
                            <ListItemLink
                                onClick={() => {
                                    history.push('/how-to');
                                    dispatch(toggleLeftSideMenu(isOpen));
                                }}
                                key={4}
                                button
                            >
                                <ListItemText className={classes.link} primary={t(`sidemenu:how-to`)} />
                            </ListItemLink>
                        </List>
                        <Divider />
                        <List component="nav" aria-label="">
                            {dataLoaded
                                ?
                                auraRoutes.map(({ title, icon, href }: RouteType) =>
                                    href === 'logout' ? (
                                        <ListItemLink key={title} button onClick={handleLogout}>
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText className={classes.link} primary={title} />
                                        </ListItemLink>
                                    ) : (
                                        <ListItemLink
                                            onClick={() => {
                                                history.push(`/${href}`);
                                                dispatch(toggleLeftSideMenu(isOpen));
                                            }}
                                            button
                                            key={href}
                                        >
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText
                                                className={classes.link}
                                                primary={t(`sidemenu:${title}`)}
                                            />
                                        </ListItemLink>
                                    ),
                                )
                                :
                                routes.map(({ title, icon, href }: RouteType) =>
                                    href === 'logout' ? (
                                        <ListItemLink key={title} button onClick={handleLogout}>
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText className={classes.link} primary={title} />
                                        </ListItemLink>
                                    ) : (
                                        <ListItemLink
                                            onClick={() => {
                                                history.push(`/${href}`);
                                                dispatch(toggleLeftSideMenu(isOpen));
                                            }}
                                            button
                                            key={href}
                                        >
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText
                                                className={classes.link}
                                                primary={t(`sidemenu:${title}`)}
                                            />
                                        </ListItemLink>
                                    ),
                                )
                            }
                        </List>
                    </Grid>

                </Grid>
            </Drawer>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(null, mapDispatchToProps)(SideMenu);

import { API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';

API.configure(awsexports);

const apiName = 'BillingInvoices';
const path = '/';
const myInit: {
    headers: string;
    response: true;
    queryStringParameters: {
        customer?: string;
        subscription?: string;
        invoiceId?: string;
    };
} = {
    headers: '',
    response: true,
    queryStringParameters: {},
};

export const getInvoices = async ({
    customer,
    subscription,
}: {
    customer?: string;
    subscription?: string;
}) => {
    myInit.headers = await getHeader(myInit.headers);
    myInit.queryStringParameters = {
        customer,
        subscription,
    };
    const { data } = await API.get(apiName, path, myInit);
    // eslint-disable-next-line no-console
    // console.log('getInvoiceInvoices Result: ', data);
    return { invoices: data };
};

export const payInvoice = async ({ invoiceId }: { invoiceId?: string }) => {
    myInit.headers = await getHeader(myInit.headers);
    myInit.queryStringParameters = {
        invoiceId,
    };
    const { data } = await API.patch(apiName, path, myInit);
    // eslint-disable-next-line no-console
    // console.log('getInvoiceInvoices Result: ', data);
    return { invoices: data };
};

export const listInvoices = async () => {
    // eslint-disable-next-line no-console
    console.log('listInvoices');
};

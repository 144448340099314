import { useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as UnitedStatesFlag } from '../../assets/flags/united-states.svg';
import { changeLanguage, toggleInternationalizationMenu } from '../../redux/app/actions';

const useStyles = makeStyles(() => ({
    menu: {
        marginLeft: '-17px',
        maxHeight: '217px',
        position: 'inherit',
        display: 'block',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    menuItem: {
        minWidth: '140px',
    },
    button: {
        color: 'white',
        fontSize: '12',
        cursor: 'pointer',
    },
    icon: {
        color: 'yellow',
    },
}));

const flagAndLanguage = [{ key: 'en', flag: 'united-stated.svg' }];

interface Props {
    isOpen: boolean;
    language: string;
}

const InternationalizationButton = ({ isOpen, language }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispath = useDispatch();

    const toggleMenu = () => {
        dispath(toggleInternationalizationMenu(isOpen));
    };

    const handleLanguageSelect = (key: string) => {
        dispath(changeLanguage(key));
        toggleMenu();
    };

    return (
        <div
            onMouseEnter={() => {
                if (!isMobile) toggleMenu();
            }}
            onMouseLeave={() => {
                if (!isMobile) toggleMenu();
            }}
        >
            <Button
                onClick={toggleMenu}
                startIcon={<UnitedStatesFlag />}
                className={classes.button}
                aria-controls="internationalization"
                endIcon={
                    isOpen ? (
                        <ExpandLessIcon className={classes.icon} />
                    ) : (
                        <ExpandMoreIcon className={classes.icon} />
                    )
                }
            >
                {t(`translation:${language}`)}
            </Button>
            {isOpen && (
                <div
                    className={classes.menu}
                    style={{
                        // position: 'fixed',
                        visibility: isOpen ? 'visible' : 'hidden',
                    }}
                >
                    {flagAndLanguage.map((e) => {
                        return (
                            <MenuItem
                                className={classes.menuItem}
                                key={e.key}
                                onClick={() => {
                                    handleLanguageSelect(e.key);
                                }}
                            >
                                <ListItemIcon>
                                    <UnitedStatesFlag />
                                </ListItemIcon>
                                {t(`translation:${e.key}`)}
                            </MenuItem>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default InternationalizationButton;

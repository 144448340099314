import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import {
    Backdrop,
    CircularProgress,
    FormHelperText,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { GlobalStateType } from '../../redux/rootTypes';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFFFFF',
        [theme.breakpoints.down(768)]: {
            padding: 5,
        },
    },
    avatar: {
        margin: theme.spacing(1),
        width: '210px',
        height: '289px',
    },
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '49px',
        fontSize: '16px',
        width: '340px',
        [theme.breakpoints.down(315)]: {
            width: 'auto',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#1B7FD5',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },
    header: {
        color: 'inherit',
        fontFamily: ['Poppins', 'regular'].join(','),
        textTransform: 'uppercase',
        fontSize: '36px',
        textAlign: 'center',
    },
    label: {
        color: 'inherit',
        marginTop: theme.spacing(2),
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '17px',
        fontWeight: 400,
    },
    signup: {
        margin: theme.spacing(2),
        borderRadius: '12px',
        backgroundColor: '##FFFFFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '18px',
        width: '234px',
        height: '56px',
        [theme.breakpoints.down(768)]: {
            width: 'auto',
        },
    },
    buttonDisabled: {
        border: '1px solid transparent',
        marginTop: 30,
        padding: 5,
        borderRadius: '12px',
        color: 'white',
        backgroundColor: 'grey',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
    },
    code: {
        color: 'inherit',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '12px',
        textTransform: 'none',
    },
}));
interface CaptchaLoaderType {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setShowPage: any;
}

export default function SignUp({ setShowPage }: CaptchaLoaderType) {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams<{ code?: string }>();
    const [email, setEmail] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [confirm, confirmPassword] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState<string>('');
    const user = useSelector((state: GlobalStateType) => state.user);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const reRef = useRef<ReCAPTCHA>(null);
    const [showLoader, setShowLoader] = useState(false);
    const verifyCaptcha = (res: any) => {
        if (res) {
            // validateToken(res);
            setCaptchaVerified(true);
        }
    };

    // ReCAPTCHA Expired
    const expireCaptcha = () => {
        setShowPage(false);
        setShowLoader(false);
    };

    useEffect(() => {
        if (user.sub) history.push('/home');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const validateEmail = () => {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!name.trim()) {
            setError('Please, enter your full name');
            setOpen(true);
            return;
        }
        if (!validateEmail()) {
            setError('Please, enter a valid email');
            setOpen(true);
            return;
        }
        if (password === confirm) {
            setLoading(true);
            Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                    name,
                    'custom:code': params.code,
                },
            })
                .then((response) => {
                    setLoading(false);
                    if (!response.userConfirmed) {
                        history.push('/signup-confirm');
                    }
                })
                .catch((err) => {
                    const message = err?.message?.replace(/username/g, 'email');
                    setOpen(true);
                    setError(`${message || 'Sorry, something went wrong'}`);
                    setLoading(false);
                });
        } else {
            setError('Passwords do not match');
            setOpen(true);
        }
    };

    function Alert(props: AlertProps) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const invalidName = () => {
        return !!(!name && (email || password || confirm));
    };

    const invalidEmail = () => {
        return !!(!email && (name || password || confirm));
    };

    const invalidPassword = () => {
        return !!(!password && (name || email || confirm));
    };

    const invalidConfirm = () => {
        return !!(!confirm && (name || email || password));
    };

    const invalidForm = () => {
        // console.log(invalidName() || invalidEmail() || invalidPassword() || invalidConfirm());
        // console.log(invalidName(), invalidEmail(), invalidPassword(), invalidConfirm());
        return (
            invalidName() ||
            invalidEmail() ||
            invalidPassword() ||
            invalidConfirm() ||
            (!name && !email && !password && !confirm)
        );
    };

    const verifyCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        history.push('/signup-confirm');
    };

    const sendConfirmationCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        history.push('/resend-confirmation-code');
    };

    return (
        <>
            <Button
                onClick={() => {
                    history.push('/login');
                }}
                endIcon={<ArrowBackIcon style={{ fontSize: 48, color: '#005FB3' }} />}
            />
            <Container maxWidth="xs">
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={open}
                    key="topcenter"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar variant="square" className={classes.avatar} src="/logo.png" />
                    <Typography className={classes.header}>Create an Account</Typography>
                    <form noValidate autoComplete="off">
                        <FormGroup className={classes.form}>
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                placeholder="Full Name"
                                name="name"
                                type="name"
                                autoFocus
                                onChange={(e) => setName(e.target.value)}
                                error={invalidName()}
                                label="Please, enter your full name"
                                helperText={invalidName() ? 'Incorrect entry.' : ''}
                            />
                            <FormHelperText id="email" error>
                                {invalidName() ? 'Incorrect entry.' : ''}
                            </FormHelperText>
                            <TextField
                                className={classes.input}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                type="email"
                                placeholder="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value?.trim())}
                                error={invalidEmail()}
                                label="Please, enter your email"
                            />
                            <FormHelperText id="email" error>
                                {invalidEmail() ? 'Incorrect entry.' : ''}
                            </FormHelperText>
                            <OutlinedInput
                                className={classes.input}
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                                error={invalidPassword()}
                                label="Password"
                                placeholder="Password"
                                type={showPassword ? 'string' : 'password'}
                                value={password}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                                fullWidth
                            />
                            <FormHelperText id="password" error>
                                {invalidPassword()
                                    ? 'Please use minimal 8 characters (lower and upper letters, numbers and symbols)'
                                    : ''}
                            </FormHelperText>
                            <OutlinedInput
                                className={classes.input}
                                id="confirm-password"
                                type={showPassword ? 'string' : 'password'}
                                value={confirm}
                                onChange={(e) => confirmPassword(e.target.value)}
                                error={invalidConfirm()}
                                label="Confirm"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required
                                fullWidth
                                name="confirm-password"
                                placeholder="Confirm Password"
                                autoComplete="confirm-password"
                            />
                            <FormHelperText id="confirm-password" error>
                                {invalidConfirm()
                                    ? 'Please use minimal 8 characters (lower and upper letters, numbers and symbols)'
                                    : ''}
                            </FormHelperText>
                            <FormHelperText>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_GOOGLE_reCAPTCHA_KEY || ''}
                                    ref={reRef}
                                    onChange={verifyCaptcha}
                                    onExpired={expireCaptcha}
                                />
                            </FormHelperText>
                            <Button className={classes.code} onClick={verifyCode}>
                                Confirm your code here
                            </Button>

                            <Button className={classes.code} onClick={sendConfirmationCode}>
                                Send New Confirmation Code
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={invalidForm() ? classes.buttonDisabled : classes.submit}
                                disabled={!isCaptchaVerified || invalidForm()}
                            >
                                Register Account
                            </Button>
                        </FormGroup>
                    </form>
                </div>
            </Container>
        </>
    );
}

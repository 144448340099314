import { API } from 'aws-amplify';
import { SubscriptionType } from './types';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';
import { getItem, setItem } from '../../utils/localStorage';
import { UserType, USER_KEY } from '../user/types';

API.configure(awsexports);

const apiName = 'BillingSubscription';
const path = '/';
let myInit: {
    headers: string;
    response: true;
    queryStringParameters?: { sub?: string; subscriptionID?: string; customerID?: string };
    body?: {
        customerID?: string;
        priceID?: string;
        paymentMethodID?: string;
        subscriptionID?: string;
    };
};

const init = () => {
    myInit = {
        headers: '',
        response: true,
        queryStringParameters: {},
        body: {},
    };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseSubscription = (data: any, subscription: SubscriptionType | null) => {
    return {
        customer: data.customer,
        paymentMethod: data.default_payment_method
            ? data.default_payment_method
            : subscription?.paymentMethod || null,
        subscriptionID: data.id,
        createdAt: data.created,
        subscriptionStatus: data.status,
        currentPeriodStart: data.current_period_end,
        currentPeriodEnd: data.current_period_start,
        pricePlan: data.plan.id,
        cancelAtPeriodEnd: data.cancel_at_period_end,
        cancelAt: data.cancel_at,
        canceledAt: data.canceled_at,
        pauseCollection: data.pause_collection,
    };
};

export const createSubscription = async (props: {
    customerID: string;
    priceID: string;
    paymentMethodID: string;
}) => {
    init();
    myInit.headers = await getHeader(myInit.headers);
    myInit.body = { ...props };
    const { data } = await API.post(apiName, path, myInit);
    // eslint-disable-next-line no-console
    // console.log('createSubscription Result: ', data);
    const localUser = getItem(USER_KEY);
    const result = { ...localUser, subscription: parseSubscription(data, null) };
    setItem(USER_KEY, result);
    return result;
};

export const upgradeSubscription = async (props: {
    priceID: string;
    subscriptionID: string;
    subscription: SubscriptionType;
}) => {
    init();
    myInit.headers = await getHeader(myInit.headers);
    myInit.body = { ...props };
    const { data } = await API.put(apiName, path, myInit);
    // eslint-disable-next-line no-console
    // console.log('upgradeSubscription Result: ', data);
    return { subscription: parseSubscription(data, props.subscription) };
};

export const cancelSubscription = async (subscriptionID: string): Promise<SubscriptionType> => {
    init();
    myInit.headers = await getHeader(myInit.headers);
    myInit.body = { subscriptionID };
    const { data } = await API.del(apiName, path, myInit);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // console.log('cancelSubscription Result: ', result);
    // await updateUser(user);
    // console.log('cancelSubscription: ', data);
    const localUser: UserType = getItem(USER_KEY);
    return parseSubscription(data, localUser.subscription || null);
};

export const pauseSubscription = async ({
    subscriptionID,
}: {
    subscriptionID: string;
}): Promise<SubscriptionType> => {
    init();
    myInit.headers = await getHeader(myInit.headers);
    myInit.body = { subscriptionID };
    const { data } = await API.patch(apiName, path, myInit);
    // console.log('pauseSubscription: ', data);
    const localUser: UserType = getItem(USER_KEY);
    return parseSubscription(data, localUser.subscription || null);
};

export const getUserSubscriptions = async (customerID: string) => {
    init();
    myInit.headers = await getHeader(myInit.headers);
    myInit.queryStringParameters = { customerID };
    const { data } = await API.get(apiName, path, myInit);
    // console.log('getsubscriptions: ', data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subscriptions = data?.data?.sort((s1: any, s2: any) => s2.created - s1.created);
    // console.log('getsubscription: ', customerID, subscriptions);
    return { subscriptions };
};

export const getUserSubscription = async (payload: {
    subscriptionID?: string;
    customerID?: string;
}) => {
    const { customerID } = payload;
    if (!customerID) return { subscription: null };
    const { subscriptions } = await getUserSubscriptions(customerID);
    // console.log('getsubscription: ', customerID, subscriptions);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subscriptionActive = subscriptions.find((s: any) => s.status === 'active');
    return { subscription: subscriptionActive || subscriptions[0] };
};

export const getSubscription = async (payload: {
    subscriptionID?: string;
    customerID?: string;
}) => {
    init();
    const { subscriptionID, customerID } = payload;
    myInit.headers = await getHeader(myInit.headers);
    myInit.queryStringParameters = { subscriptionID, customerID };
    const { data } = await API.get(apiName, path, myInit);
    // console.log('getsubscription: ', subscriptionID, customerID, data);
    if (!data) return { subscription: null };
    if (subscriptionID) {
        return { subscription: data };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subscriptions = data.data.sort((s1: any, s2: any) => s2.created - s1.created);
    // console.log('getsubscription: ', subscriptionID, customerID, subscriptions);
    return { subscription: subscriptions[0] };
};

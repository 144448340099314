import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import BigButton from '../utils/Button/BigButton';
import { GlobalStateType } from '../../redux/rootTypes';
import { payInvoicesAction } from '../../redux/invoices/actions';
import useOnlineDispatch from '../OnlineDispatch';
import { clearError, newError } from '../../redux/notifications/actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnPadding: {
            padding: '20px 0px',
            width: '266px',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                justifyContent: 'center',
                padding: '25px',
            },
        },
    }),
);

const PayInvoice = () => {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();
    const { data: invoices } = useSelector((state: GlobalStateType) => state.invoices);
    const [invoiceId, setInvoiceId] = useState<string>('');

    useEffect(() => {
        const invoiceToPay = invoices.find((invoice) => !invoice.paid && invoice.status !== 'void');
        if (invoiceToPay) {
            setInvoiceId(invoiceToPay.id);
            dispatch(
                newError({ severity: 'warning', message: 'Sorry, it seems that a payment failed' }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]);

    const handlePay = () => {
        if (invoiceId) {
            onlineDispatch(payInvoicesAction({ invoiceId }));
            dispatch(clearError());
        }
    };

    return invoiceId ? (
        <Grid item className={classes.btnPadding}>
            <BigButton title="Pay Actual Invoice" type="secundary" onClick={handlePay} />
        </Grid>
    ) : null;
};

export default PayInvoice;

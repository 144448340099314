import { useDispatch, useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import { newError } from '../../redux/notifications/actions';

export default () => {
    const network = useSelector((state: GlobalStateType) => state.network.data);

    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (action: any) => {
        // console.log('online dispatch: ', network, action);
        if (network) {
            dispatch(action);
        } else {
            newError({ severity: 'info', message: 'Sorry, it seems that you are offline' });
        }
    };
};

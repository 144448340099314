import { useHistory } from 'react-router';

const useGoTo = () => {
    const history = useHistory();

    const goTo = (route: string) => {
        history.push(route);
    };

    return goTo;
};

export default useGoTo;

import { SvgIcon } from '@material-ui/core';
import React from 'react';

const DrawerIcon = () => {
    return (
        <SvgIcon
            style={{ fill: '#fbd402' }}
            width="24.308"
            height="21.668"
            viewBox="0 0 24.308 21.668"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M99.217,65.23H77.028a1.059,1.059,0,0,1,0-2.118H99.217a1.059,1.059,0,1,1,0,2.118Z"
                transform="translate(-75.969 -63.112)"
            />
            <path
                d="M93.066,69.318H77.028a1.059,1.059,0,0,1,0-2.118H93.066a1.059,1.059,0,1,1,0,2.118Z"
                transform="translate(-75.969 -57.426)"
            />
            <path
                d="M99.217,73.409H77.028a1.06,1.06,0,0,1,0-2.121H99.217a1.06,1.06,0,0,1,0,2.121Z"
                transform="translate(-75.969 -51.741)"
            />
        </SvgIcon>
    );
};

export default DrawerIcon;

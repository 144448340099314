import Amplify, { API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';
import { setItem } from '../../utils/localStorage';

Amplify.configure(awsexports);
API.configure(awsexports);

const apiName = 'BillingPrices';
const path = '/';
const myInit: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers: any;
    response: true;
    queryStringParameters: { productID?: string; priceID?: string };
} = {
    headers: {},
    response: true,
    queryStringParameters: {
        productID: '',
        priceID: '',
    },
};

const getElements = async (queryStringParameters: { productID?: string; priceID?: string }) => {
    const options = {
        ...myInit,
        queryStringParameters,
        headers: await getHeader({}),
    };
    const { data } = await API.get(apiName, path, options);
    return data;
};

export const getPrice = async ({ priceID }: { priceID: string }) => {
    return { price: await getElements({ priceID }) };
};

export const listPrices = async ({ productID }: { productID: string }) => {
    const prices = await getElements({
        productID,
    });
    setItem('prices', prices);
    return { prices };
};

import React, { FormEvent } from 'react';

import { createStyles, FormControl, Grid, makeStyles, Select } from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';

import {
    PaymentMethod,
    StripeCardNumberElement,
    StripeElementChangeEvent,
} from '@stripe/stripe-js';

import { StyledInput } from '../ModifyBillingInfo/ModifyBillingInfo';
import BigButton from '../utils/Button/BigButton';
import StripeElementsProvider from '../utils/StripeElementsProvider/StripeELementsProvider';
import useGoTo from '../../utils/useGoTo';
import Line from '../Line';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            maxWidth: '334px',
            '& #title': {
                color: '#0468BF',
                fontSize: '24px',
                textTransform: 'capitalize',
                fontWeight: 'bold',
            },
            '& #sub_title': {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        icon: {
            color: '#BDD9FF',
            '& #CardIcon': {
                fontSize: '100px',
                padding: '0',
            },
            '& #ShieldIcon': {
                fontSize: '50px',
                top: '50px',
                right: '65px',
                position: 'relative',
            },
        },
        code: {
            width: '40px',
            height: '35px',
        },
        cardField: {
            backgroundColor: 'white',
            padding: '10px 24px 10px 12px',
            borderRadius: '4px',
            '& ::placeholder': {
                color: 'yellow',
            },
            '& .StripeElement--invalid': {
                color: 'yellow',
            },
        },
    }),
);

const AddCardForm = () => {
    const classes = useStyles();
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>('');
    const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod | null>();
    const goTo = useGoTo();

    const elements = useElements();
    const stripe = useStripe();
    const [type, setType] = React.useState<string>('');

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as string);
    };

    const handleChange = (event: StripeElementChangeEvent) => {
        const { error } = event;
        if (error) setErrorMsg(error.message);
        else setErrorMsg('');
        return event.complete;
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        const cardElement: StripeCardNumberElement | null = elements.getElement(CardNumberElement);

        if (cardElement) {
            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: 'Dani_testing',
                },
            });

            if (payload.error) {
                setErrorMsg(payload.error.message);
                setPaymentMethod(null);
            } else {
                setPaymentMethod(payload.paymentMethod);
                setErrorMsg('');
                goTo('/my-account');
            }
        }
    };

    return (
        <Grid className={classes.root}>
            <Grid item>
                <p id="title">Add Credit Card</p>
            </Grid>
            <Grid item container className={classes.icon} justify="flex-start">
                <CreditCardOutlinedIcon id="CardIcon" />
                <VerifiedUserTwoToneIcon id="ShieldIcon" />
            </Grid>
            <Grid style={{ width: '334px' }}>
                <p id="sub_title">Link a Card</p>
                <Line />
            </Grid>
            <form onSubmit={handleSubmit}>
                <Grid item container direction="column" spacing={2}>
                    <Grid item xs={12} style={{ width: '100%' }}>
                        <CardNumberElement className={classes.cardField} onChange={handleChange} />
                    </Grid>
                    <Grid item>
                        <FormControl style={{ width: '100%' }}>
                            <Select
                                native
                                value={type}
                                fullWidth
                                onChange={(e) => handleSelectChange(e)}
                                input={<StyledInput placeholder="Country" />}
                            >
                                <option label="Select Your Card Type" value="" />
                                <option label="Debit" value="debit">
                                    Debit
                                </option>
                                <option label="Credit" value="credit">
                                    Credit
                                </option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <CardExpiryElement className={classes.cardField} onChange={handleChange} />
                    </Grid>
                    <Grid
                        item
                        container
                        justify="flex-start"
                        alignItems="center"
                        spacing={4}
                        wrap="nowrap"
                    >
                        <Grid item xs={8}>
                            <CardCvcElement className={classes.cardField} onChange={handleChange} />
                        </Grid>
                        <Grid item container alignItems="center" xs={4}>
                            <img
                                alt="security code"
                                src="/assets/images/security_code.png"
                                width="50px"
                                height="30"
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        {errorMsg ? (
                            <p
                                style={{
                                    padding: '0.5em',
                                    color: 'red',
                                    transition: '500ms',
                                    maxWidth: '',
                                }}
                            >
                                {errorMsg}
                            </p>
                        ) : null}
                        {paymentMethod && <p>Got PaymentMethod: {paymentMethod.id}</p>}
                    </Grid>
                    <Grid item style={{ padding: '30px 0' }}>
                        <BigButton
                            title="Add Credit Card"
                            type="primary"
                            onClick={() => {}}
                            job="submit"
                        />
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

const AddCreditCard = () => {
    return (
        <StripeElementsProvider>
            <AddCardForm />
        </StripeElementsProvider>
    );
};

export default AddCreditCard;

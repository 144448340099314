import { getI18n } from 'react-i18next';

import * as types from './types';

export function toggleHarmonizing(state: boolean) {
    return {
        type: types.HARMONIZING,
        state,
    };
}

export function toggleInternationalizationMenu(state: boolean) {
    return {
        type: types.TOGGLE_INTERNATIONALIZATION,
        state,
    };
}

export function toggleLeftSideMenu(state: boolean) {
    return {
        type: types.TOGGLE_LEFT_SIDE_DRAWER,
        state,
    };
}

export const changeLanguage = (language: string) => {
    const i18n = getI18n();
    i18n.changeLanguage(language);
    return {
        type: types.CHANGE_LANGUAGE,
        language,
    };
};

export const showInfoClicked = (state?: boolean) => {
    return {
        type: types.SHOW_INFO,
        state,
    };
};

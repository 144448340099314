/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import moment from 'moment';
import { Badge, Grid, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../assets/svg_icons/editButton.svg';
import { GlobalStateType } from '../../redux/rootTypes';
import awsconfig from '../../aws-exports';
import { getItem, setItem } from '../../utils/localStorage';
import { USER_KEY } from '../../redux/user/types';
import UserAvatar from '../Avatar';
import { ColorCircularProgress } from '../Loader';
import Line from '../Line';
import { updateUserAction } from '../../redux/user/actions';
import useOnlineDispatch from '../OnlineDispatch';

Storage.configure(awsconfig);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: 10,
            maxWidth: '660px',
            '& #avatar': {
                width: '170px',
            },
            [theme.breakpoints.down('sm')]: {
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                '& #avatar': {
                    width: '100%',
                },
            },
        },
        textContainer: {
            maxWidth: '300px',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'center',
            },
            '& h1': {
                margin: '5px',
            },
            '& p': {
                margin: '5px',
            },
        },
        hr: {
            width: '100%',
            height: '2px',
            margin: '25px 25px 25px 0',
            background: '#a3cdf1',
            border: 'none',
        },
        large: {
            width: '136px',
            height: '136px',
            background: '#a3cdf1',
        },
        badge: {
            width: '20px',
            height: '20px',
            background: 'white',
            fill: '#a3cdf1',
            padding: '6.64px 6.5px 5.5px 6.78px ',
            borderRadius: '50%',
            transition: '300ms',
            '&:hover': {
                fill: '#0468BF',
                cursor: 'pointer',
            },
        },
        imageIcon: {
            height: '100%',
        },
        iconRoot: {
            textAlign: 'center',
        },
        button: {
            '&:hover &:focus': {
                color: 'transparent',
            },
            padding: '3px !important',
        },
    }),
);

const MemberInfo = () => {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const user = useSelector((state: GlobalStateType) => state.user);
    const {
        data: { paymentIntents },
        loading,
    } = useSelector((state: GlobalStateType) => state.payments);
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        // Retrieve user avatar from localStorage on component mount
        // const localUser = getItem(USER_KEY);
        // if (localUser?.avatar) {
        //     setImage(localUser.avatar);
        //     console.log('xxxxxxxxx');
        //     return;
        // }
    }, []);

    const handleImageChange = (e: any) => {
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();
        const key = `avatars/${user.sub}`;
        try {
            reader.readAsDataURL(file);
            const { type: mimeType } = file;
            Storage.put(key, file, {
                level: 'public',
                contentType: mimeType,
            }).then(() => {
                const localUser = getItem(USER_KEY);
                const updatedUser = { ...localUser, hasAvatar: true, avatar: reader.result };
                setItem(USER_KEY, updatedUser);
                setImage(updatedUser.avatar);
                // console.log('okokokokok');
            });
        } catch (err) {
            console.error(err);
        }
    };


    const fileInput: any = React.createRef();

    const onOpenFileDialog = () => {
        fileInput.current.click();
    };

    return (
        <Grid
            container
            className={classes.container}
            spacing={4}
            alignItems="center"
            key="memberInfo"
        >
            <form>
                <Grid container id="avatar" item justify="center">
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            <EditIcon
                                className={classes.badge}
                                type="submit"
                                onClick={() => {
                                    onOpenFileDialog();
                                }}
                            />
                        }
                    >
                        <UserAvatar image={image} large />
                    </Badge>
                    <input
                        type="file"
                        id="img"
                        name="image"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        ref={fileInput}
                        hidden
                    />
                </Grid>
            </form>
            <Grid container direction="column" className={classes.textContainer}>
                <Grid item>
                    <h1>{user.name}</h1>
                </Grid>
            </Grid>
            <Line key="memberInfo-line" />
        </Grid>
    );
};

export default MemberInfo;


import React, { ReactNode } from 'react';
import useSubscriptionValidation from './validateBilling';

interface Props {
    children: ReactNode;
}

const ValidateSubscriptionComponent = ({ children }: Props) => {
    const isValidSubscription = useSubscriptionValidation();
    // console.log(isValidSubscription);
    return isValidSubscription ? <>{children}</> : null;
};

export default ValidateSubscriptionComponent;

import React, { useState } from 'react';
import Background from '../../assets/bg.png';
import SignIn from '../../components/Auth/SignIn';

export default () => {
    const [showPage, setShowPage] = useState(false);
    return (
        <div
            style={{
                minHeight: '100vh',
                height: 'auto',
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
            }}
        >
            <SignIn setShowPage={setShowPage} />
        </div>
    );
};

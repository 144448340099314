import {
    createStyles,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    makeStyles,
    Select,
    TextField,
    Theme,
    withStyles,
} from '@material-ui/core';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import {
    PaymentMethod,
    StripeCardNumberElement,
    StripeElementChangeEvent,
} from '@stripe/stripe-js';
import React, { FormEvent } from 'react';
// import useGoTo from '../../../utils/useGoTo';
import { useSelector } from 'react-redux';
import { Data } from '../types';
import { COUNTRIES, STATES } from '../../../assets/countries/countries';
import BigButton from '../../utils/Button/BigButton';
import StripeElementsProvider from '../../utils/StripeElementsProvider/StripeELementsProvider';
import {
    setDefaultPaymentMethodAction,
    setDefaultPaymentMethodSuccessAction,
} from '../../../redux/payments/actions';
import { GlobalStateType } from '../../../redux/rootTypes';
import Line from '../../Line';
import { ColorCircularProgress } from '../../Loader';
import useOnlineDispatch from '../../OnlineDispatch';

export const StyledInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
            '& .MuiSelect-icon': {
                color: '#0468BF',
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            boxShadow: '0px 3px 6px #00000008',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: ['Roboto'].join(','),
            '&:focus': {
                borderRadius: 4,
            },
        },
    }),
)(InputBase);

export const StyledTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '& input:valid + fieldset': {
                borderColor: '#FFF',
                borderWidth: 1,
            },
            '& input:invalid + fieldset': {
                borderColor: '#FFF',
                borderWidth: 1,
            },
            '& input:valid:focus + fieldset': {
                borderLeftWidth: 5,
                borderWidth: 2,
                borderColor: '#FFF',
                color: '#FFF',
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& label': {
                color: 'black',
                fontWeight: 'bold',
                top: '-5px',
            },
            '& input': {
                borderRadius: 4,
                position: 'relative',
                backgroundColor: theme.palette.background.paper,
                fontSize: 16,
                padding: '10px 26px 10px 12px',
                boxShadow: '0px 3px 6px #00000008',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                // Use the system font instead of the default Roboto font.
                fontFamily: ['Roboto'].join(','),
                '&:focus': {
                    borderRadius: 4,
                },
            },
        },
    }),
)(TextField);

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: '750px',
            '& p': {
                padding: '6px',
            },
            '& #title': {
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: '#0468BF',
                fontSize: '24px',
            },
            '& #sub_title': {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        icon: {
            color: '#BDD9FF',
            '& #CardIcon': {
                fontSize: '100px',
                padding: '0',
            },
            '& #ShieldIcon': {
                fontSize: '50px',
                top: '50px',
                right: '65px',
                position: 'relative',
            },
        },
        code: {
            width: '40px',
            height: '35px',
        },
        cardField: {
            backgroundColor: 'white',
            padding: '10px 24px 10px 12px',
            borderRadius: '4px',
            '& ::placeholder': {
                color: 'yellow',
            },
            '& .StripeElement--invalid': {
                color: 'yellow',
            },
        },
    }),
);

const CreatePaymentMethodForm = () => {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const { stripeCustomerID } = useSelector((state: GlobalStateType) => state.user);
    const elements = useElements();
    const stripe = useStripe();
    const [errorMsg, setErrorMsg] = React.useState<string | undefined>('');
    const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod | null>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [billingDetails, setData] = React.useState<Data>({
        name: '',
        email: '',
        city: '',
        country: { id: '', value: '' },
        line1: '',
        line2: '',
        postal_code: '',
        state: { id: '', value: '' },
    });

    const getCountries = () => {
        return COUNTRIES.map((country) => (
            <option key={country.ID} value={country.ShortForm}>
                {country.CountryTitle}
            </option>
        ));
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>, field: string) => {
        let country1;
        let state1;
        let fieldValue;
        switch (field) {
            case 'country':
                country1 = COUNTRIES.find((country) => country.ShortForm === event.target.value);
                fieldValue = { id: country1?.ID, value: country1?.ShortForm };
                break;
            case 'state':
                state1 = STATES.find((state) => state.StateTitle === event.target.value);
                fieldValue = { id: state1?.ID, value: state1?.StateTitle };
                break;
            default:
                fieldValue = event.target.value;
                break;
        }
        setData({
            ...billingDetails,
            [field]: fieldValue,
        });
    };

    const handleElementsChange = (event: StripeElementChangeEvent) => {
        const { error } = event;
        if (error) setErrorMsg(error.message);
        else setErrorMsg('');
        return event.complete;
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const { name, email, city, country, line1, line2, state } = billingDetails;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        const cardElement: StripeCardNumberElement | null = elements.getElement(CardNumberElement);

        if (cardElement) {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name,
                    email,
                    address: {
                        city,
                        line1,
                        line2,
                        postal_code: billingDetails.postal_code,
                        country: country.value,
                        state: state.value,
                    },
                },
            });

            if (result.error) {
                setErrorMsg(result.error.message);
                setLoading(false);
            } else {
                setPaymentMethod(result.paymentMethod);
                setErrorMsg('');
                // Call attachPaymentMethod API
                // dispatch set default payment method action
                // with the result.paymentMethod object
                onlineDispatch(
                    setDefaultPaymentMethodSuccessAction({ paymentMethod: result.paymentMethod }),
                );
                if (stripeCustomerID)
                    onlineDispatch(
                        setDefaultPaymentMethodAction({
                            customerID: stripeCustomerID,
                            paymentMethodID: result?.paymentMethod.id,
                        }),
                    );
                setLoading(false);
                // goTo('/my-account');
            }
        }
    };

    return (
        <Grid className={classes.container}>
            <p id="sub_title">Billing Information</p>
            <Grid>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid container spacing={4} alignItems="center" style={{ maxWidth: '700px' }}>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                id="name"
                                label="Customer Name"
                                placeholder="Full Name"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ disableUnderline: true }}
                                required
                                value={billingDetails.name}
                                onChange={(e) => handleChange(e, 'name')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                id="email"
                                type="email"
                                label="Billing Email"
                                placeholder="Email Address"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ disableUnderline: true }}
                                required
                                value={billingDetails.email}
                                onChange={(e) => handleChange(e, 'email')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                id="b_address_1"
                                label="Billing Address"
                                placeholder="Billing Address line 1"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ disableUnderline: true }}
                                required
                                value={billingDetails.line1}
                                onChange={(e) => handleChange(e, 'line1')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                id="b_address_2"
                                label=" "
                                placeholder="Billing Address line 2"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={billingDetails.line2}
                                onChange={(e) => handleChange(e, 'line2')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel
                                    htmlFor="age-native-simple"
                                    shrink
                                    style={{ fontWeight: 'bold', color: 'black' }}
                                >
                                    Country
                                </InputLabel>
                                <Select
                                    native
                                    value={billingDetails.country.value}
                                    fullWidth
                                    onChange={(e) => handleChange(e, 'country')}
                                    input={<StyledInput placeholder="Country" required />}
                                >
                                    <option label="Country" value="" />
                                    {getCountries()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledTextField
                                id="zip"
                                placeholder="Zip/Postal Code"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ disableUnderline: true }}
                                required
                                value={billingDetails.postal_code}
                                onChange={(e) => handleChange(e, 'postal_code')}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ padding: '0px 0px 16px 16px' }}>
                            <p id="sub_title">Credit Card</p>
                            <Line />
                        </Grid>{' '}
                        <Grid
                            item
                            container
                            direction="column"
                            spacing={2}
                            style={{ padding: '0px  0px 16px 16px' }}
                        >
                            <Grid item xs={6} style={{ width: '100%' }}>
                                <CardNumberElement
                                    className={classes.cardField}
                                    onChange={handleElementsChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CardExpiryElement
                                    className={classes.cardField}
                                    onChange={handleElementsChange}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                container
                                justify="flex-start"
                                alignItems="center"
                                spacing={4}
                                wrap="nowrap"
                            >
                                <Grid item xs={8}>
                                    <CardCvcElement
                                        className={classes.cardField}
                                        onChange={handleElementsChange}
                                    />
                                </Grid>
                                <Grid item container alignItems="center" xs={4}>
                                    <img
                                        alt="security code"
                                        src="/assets/images/security_code.png"
                                        width="50px"
                                        height="30"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                {errorMsg ? (
                                    <p
                                        style={{
                                            padding: '0.5em',
                                            color: 'red',
                                            transition: '500ms',
                                            maxWidth: '',
                                        }}
                                    >
                                        {errorMsg}
                                    </p>
                                ) : (
                                    paymentMethod && <p>Got PaymentMethod: {paymentMethod.id}</p>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <div style={{ width: '332px', padding: '20px 0', textAlign: 'center' }}>
                        {loading ? (
                            <ColorCircularProgress />
                        ) : (
                            <BigButton title="Add Payment Method" type="primary" job="submit" />
                        )}
                    </div>
                </form>
            </Grid>
            {/* <Grid style={{ width: '332px' }}>
                <LinkedCards />
            </Grid> */}
        </Grid>
    );
};

const CreatePaymentMethod = () => {
    return (
        <StripeElementsProvider>
            <CreatePaymentMethodForm />
        </StripeElementsProvider>
    );
};

export default CreatePaymentMethod;

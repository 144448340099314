import React from 'react';
import { Detector } from 'react-detect-offline';
import { useDispatch } from 'react-redux';
import { networkOff, networkOn } from '../../redux/network/actions';

export default () => {
    const dispatch = useDispatch();
    const handleNetworkChanges = (value: boolean) => {
        // console.log('network: ', value, network);
        if (value) {
            dispatch(networkOn());
        } else {
            dispatch(networkOff());
        }
    };

    return (
        <>
            <Detector
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                render={(props: any) => {
                    handleNetworkChanges(props.online);
                    return null;
                }}
            />
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Grid,
    makeStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme,
    TextareaAutosize,
} from '@material-ui/core';
import { Image } from '@material-ui/icons';
import './modal.css';
import MainLayout from '../../layout/MainLayout';
import animateImg from '../../assets/prediction.gif';

const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: 'calc(100vh - 200px)',
        height: 'auto',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    scanButton: {
        backgroundColor: '#f10 !important',
        fontWeight: 'bold',
        minWidth: '100% !important',
        margin: '20px auto !important',
        display: 'block !important',
        color: '#fff !important',
        height: 'auto !important',
        borderRadius: '5px !important',
        padding: '10px 20px 10px 20px !important',
        border: '1px solid #747474 !important',
    },
    wrap: {
        margin: '0px auto',
        width: '50%',
        '@media (max-width: 575px)': {
            width: '100%',
        },
    },
    button: {
        backgroundColor: '#0468BF !important',
        minWidth: '100% !important',
        margin: '20px auto !important',
        display: 'block !important',
        color: '#fff !important',
        height: 'auto !important',
        borderRadius: '5px !important',
        padding: '10px 20px 10px 20px !important',
        border: '1px solid #747474 !important',
        '& .MuiButton-label': {
            fontSize: '20px !important',
            fontWeight: 'bolder !important',
            textTransform: 'capitalize !important',
        },
        '&.customBtn': {
            width: '100% !important',
        },
        '&.customStartBtn': {
            color: '#f10 !important',
        },
        '&:hover': {
            backgroundColor: '#0468BF !important',
            opacity: '.9 !important',
        },
    },
}));
const AuraScan = () => {
    const classes = useStyle();
    const [open, setOpen] = React.useState(false);
    const [openSignature, setOpenSignature] = React.useState(false);
    const [openInput, setOpenInput] = React.useState(false);
    const [openHight, setOpenHight] = React.useState(false);
    const [inputText, setInputText] = useState('');

    useEffect(() => {
        setOpen(true);
    }, []);
    const handleCloseYes = () => {
        setOpenInput(true);
        setOpen(false);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseInput = () => {
        setOpenInput(false);
        setOpenSignature(true);
    };
    const historty = useHistory();
    const [animation, setAnimation] = React.useState(false);
    const handleClickScan = async () => {
        setAnimation(true);
        setTimeout(() => {
            setAnimation(false);
        }, 5000);
        setTimeout(() => {
            setOpenHight(true);
        }, 6000);
    };

    const handleClickRoute = () => {
        historty.push('/aura-screen');
    };
    const handleSignature = () => {
        setOpenSignature(false);
    };
    const handleHight = () => {
        setOpenHight(false);
    };
    return (
        <>
            <MainLayout>
                <div className={classes.bg}>
                    {animation && (
                        <div className="animate_wrap">
                            <Image href={animateImg} alt="animate image" />
                        </div>
                    )}
                    <div className={classes.wrap}>
                        <Button className={classes.button} onClick={handleClickRoute}>
                            Aura
                        </Button>
                        {/* <Button className={classes.scanButton} onClick={handleClickScan}> Scan </Button> */}
                        {/* <Button className={classes.scanButton}> Scan </Button> */}
                    </div>
                </div>
            </MainLayout>

            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description1"
            >
                <DialogContent style={{ backgroundColor: '#0d3c61' }}>
                    <DialogContentText id="alert-dialog-description1" style={{ color: '#fff' }}>
                        Would you like to add a personalized energetic signature using text?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#0d3c61' }}>
                    <Button onClick={handleClose} variant="contained">
                        No
                    </Button>
                    <Button onClick={handleCloseYes} variant="contained">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* ============================= TEXT BOX MODAL ============================= */}

            <Dialog open={openInput}>
                <DialogContent style={{ backgroundColor: '#0d3c61' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="scan_text"
                        style={{ color: '#fff' }}
                        onChange={(e) => setInputText('Energy Signature Added')}
                    />
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#0d3c61' }}>
                    <Button onClick={handleCloseInput} variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openSignature}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description2"
            >
                <DialogContent style={{ backgroundColor: '#0d3c61' }}>
                    <DialogContentText id="alert-dialog-description2" style={{ color: '#fff' }}>
                        {inputText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#0d3c61' }}>
                    <Button onClick={handleSignature} variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openHight}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description3"
            >
                <DialogContent style={{ backgroundColor: '#0d3c61' }}>
                    <DialogContentText id="alert-dialog-description3" style={{ color: '#fff' }}>
                        The higher the number (650 and above), the higher the probability of disturbance. Drag selected items for balancing into the Balance Rx or Multi Layer Rx tray, select desired time and START. Balance Rx vs Multi Layer Rx? See help section
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: '#0d3c61' }}>
                    <Button onClick={handleHight} variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AuraScan;

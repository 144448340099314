import React from 'react';
import UpgradeSubscription from '../../components/Subscription/UpgradeSubscription';
import MainLayout from '../../layout/MainLayout';

export default () => {
    return (
        <MainLayout>
            <UpgradeSubscription />
        </MainLayout>
    );
};

import React, { useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFFFFF',
        [theme.breakpoints.down(768)]: {
            padding: 5,
        },
    },
    avatar: {
        margin: theme.spacing(1),
        width: '210px',
        height: '289px',
    },
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '49px',
        fontSize: '16px',
        width: '340px',
        [theme.breakpoints.down(768)]: {
            width: 'auto',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },
    header: {
        color: 'inherit',
        fontFamily: ['Poppins', 'regular'].join(','),
        textTransform: 'uppercase',
        fontSize: '36px',
    },
    label: {
        color: 'inherit',
        marginTop: theme.spacing(2),
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '17px',
        fontWeight: 400,
    },
    signup: {
        margin: theme.spacing(2),
        borderRadius: '12px',
        backgroundColor: '##FFFFFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '18px',
        width: '234px',
        height: '56px',
    },
}));
interface CaptchaLoaderType {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setShowPage: any;
}
export default function ResetPassword({ setShowPage }: CaptchaLoaderType) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [password, setPassword] = React.useState<string>('');
    const [code, setCode] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState<string>('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [isCaptchaVerified, setCaptchaVerified] = useState(false);
    const reRef = useRef<ReCAPTCHA>(null);
    const [showLoader, setShowLoader] = useState(false);
    const verifyCaptcha = (res: any) => {
        if (res) {
            // validateToken(res);
            setCaptchaVerified(true);
        }
    };

    // ReCAPTCHA Expired
    const expireCaptcha = () => {
        setShowPage(false);
        setShowLoader(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!isCaptchaVerified) {
            // Show an alert message if ReCAPTCHA is not verified
            alert('Please complete the ReCAPTCHA before signing in.');
            return;
        }
        const { username } = location.state;
        Auth.forgotPasswordSubmit(username?.trim(), code, password)
            .then(() => {
                history.push('/login');
            })
            .catch(() => {
                setOpen(true);
                setError(`Code has been sent to your email.`);
            });
    };
    function Alert(props: AlertProps) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Container maxWidth="xs">
            <Snackbar
                open={open}
                key="topcenter"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar variant="square" className={classes.avatar} src="/logo.png" />
                <Typography className={classes.header}>Reset password</Typography>
                <form noValidate autoComplete="off">
                    <FormGroup className={classes.form}>
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="code"
                            label="Confirmation Code"
                            name="code"
                            type="string"
                            autoFocus
                            onChange={(e) => setCode(e.target.value?.trim())}
                            value={code}
                        />
                        <OutlinedInput
                            className={classes.input}
                            id="password"
                            label="New password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? 'string' : 'password'}
                            value={password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            required
                            fullWidth
                            placeholder="New Password"
                        />
                        <FormHelperText>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_GOOGLE_reCAPTCHA_KEY || ''}
                                ref={reRef}
                                onChange={verifyCaptcha}
                                onExpired={expireCaptcha}
                            />
                        </FormHelperText>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.submit}
                        >
                            Change
                        </Button>
                    </FormGroup>
                </form>
            </div>
        </Container>
    );
}

import React from 'react';
import MainLayout from '../../layout/MainLayout';
import ModifyBillingInfo from '../../components/ModifyBillingInfo/ModifyBillingInfo';

export default () => {
    return (
        <MainLayout>
            <ModifyBillingInfo />
        </MainLayout>
    );
};

import header from './header.json';
import test from './test.json';
import infoPanel from './infoPanel.json';
import sidemenu from './sidemenu.json';
import translation from './translation.json';
import about from './about.json';
import frecuencies from './frequencies.json';
import messages from './messages.json';
import termOfUse from './termOfUse.json';
import support from './support.json';

export default {
    header,
    test,
    infoPanel,
    sidemenu,
    translation,
    about,
    frecuencies,
    messages,
    termOfUse,
    support,
};

import React from 'react';
// import MainLayout from '../../layout/MainLayout';
import Background from '../../assets/bg.png';
import ConfirmSignUp from '../../components/Auth/ConfirmSignUp';

export default () => {
    return (
        <div
            style={{
                minHeight: '100vh',
                height: 'auto',
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
            }}
        >
            <ConfirmSignUp />
        </div>
    );
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Theme } from '@material-ui/core';

import MainLayout from '../../layout/MainLayout';
import Panels from '../../components/Panel/panels';
import { GlobalStateType } from '../../redux/rootTypes';
import { listPanelAction } from '../../redux/panels/actions';
import useOnlineDispatch from '../../components/OnlineDispatch';

const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',

        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
}));

const Home = () => {
    const onlineDispatch = useOnlineDispatch();
    const classes = useStyle();
    const { panels, network } = useSelector((state: GlobalStateType) => state);
    const staticThinkThin = [{
        "media": "video playa corto mp4.mp4",
        "description": "Helps provide for a smooth absorption of nutrients while eliminating toxins out of the body.",
        "logo": "1",
        "id": "01",
        "ingredients": [
            "Leptin",
            "African Mango",
            "Alpha-Lipoic Acid",
            "Fish Oil",
            "Green Tea Extract",
            "Conjugated Linoleic Acid",
            "Lipase",
            "Glucagon",
            "Adrenaline",
            "T3",
            "T4",
            "Testosterone",
            "Chromium",
            "Garcinia Cambogia",
            "Bitter Orange",
            "Coleus forskohlii",
            "Beta Glucans",
            "Raspberry Ketones",
            "Caffeine",
            "Capsaicin",
            "Hoodia",
            "Probiotics",
            "Prebiotics",
            "Harmonized Water",
            "Green Coffee Bean Extract",
            "Fucoxanthin",
            "Carnitine",
            "Pyruvate",
            "Guarana",
            "Yohimbe",
            "Vitamin D",
            "Glucomannan",
            "Chitosan",
            "Vitamin C",
            "Vitamin A",
            "Progesterone",
            "DHEA",
            "Guar Gum",
            "Chamomile",
            "Kava Kava",
            "Lavender",
            "Skullcap",
            "Passion Flower"
        ],
        "name": "Think Thin"
    }]
    useEffect(() => {
        console.log("panels", panels)
        onlineDispatch(listPanelAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network]);

    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid container justify="center" alignItems="flex-start">
                    <Panels panels={staticThinkThin} />{' '}
                    {/* <Panels panels={panels?.data} />{' '} */}
                </Grid>
            </div>
        </MainLayout>
    );
};

export default Home;

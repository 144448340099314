import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import { getUserAction, updatePaymentFlowAction } from '../../redux/user/actions';
import useValidBillingStatus from './validateBilling';
import useOnlineDispatch from '../OnlineDispatch';
import { listPaymentIntentAction } from '../../redux/payments/actions';
import { getSubscriptionAction } from '../../redux/subscription/actions';

export default () => {
    const dispatch = useDispatch();
    const {
        user: { paymentFlow, loading: userLoading, sub, stripeCustomerID, subscription },
        subscription: { loading: subscriptionLoading },
        payments: { loading: paymentLoading },
    } = useSelector((state: GlobalStateType) => state);
    const onlineDispatch = useOnlineDispatch();
    const isValid = useValidBillingStatus();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timerIdRef = useRef<any>(0);
    const [count, setCount] = useState(0);
    const [success, setSuccess] = useState(isValid || paymentFlow !== 'pending');

    const startHandler = () => {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = setInterval(() => setCount((c) => c + 1), 2000);
    };

    const stopHandler = () => {
        clearInterval(timerIdRef.current);
        timerIdRef.current = 0;
    };

    useEffect(() => {
        setSuccess(isValid || paymentFlow === 'success' || !!subscription?.subscriptionStatus);
        if (paymentFlow === 'success' || paymentFlow === null) stopHandler();
        else if (paymentFlow === 'pending') startHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentFlow]);

    useEffect(() => {
        if (!success) {
            if (!(userLoading || subscriptionLoading || paymentLoading)) {
                if (stripeCustomerID)
                    onlineDispatch(listPaymentIntentAction({ customerID: stripeCustomerID }));
                onlineDispatch(getSubscriptionAction({ customerID: stripeCustomerID }));
                onlineDispatch(getUserAction({ sub }));
            }
            setSuccess(isValid || paymentFlow === 'success');
        } else if (success) {
            dispatch(updatePaymentFlowAction('success'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    return paymentFlow === 'pending' ? <>payment in progress...</> : null;
};

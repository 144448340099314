import { API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';

API.configure(awsexports);

const apiName = 'Panel';
const path = '/';
const myInit: {
    headers: string;
    response: true;
    queryStringParameters: { id?: string };
} = {
    headers: '',
    response: true,
    queryStringParameters: {},
};

const getElements = async (queryStringParameters: { id: string }) => {
    const options = {
        ...myInit,
        queryStringParameters,
        headers: await getHeader({}),
    };
    const { data } = await API.get(apiName, path, options);
    return data?.result?.Items;
};

export const getPanel = async (id: string) => {
    return { panel: await getElements({ id }) };
};

export const listPanels = async () => {
    return getElements({ id: 'all' });
};

export interface ErrorType {
    code?: number;
    message?: string;
}

export type MailMessageType = {
    to: string[];
    from: string;
    subject: string;
    text: string;
};

export interface MailService {
    sendMail: (mailMessage: MailMessageType) => void;
}

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCEEDED = 'SEND_EMAIL_SUCCEEDED';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';
export const SET_EMAIL_SENDING_STATUS = 'SET_EMAIL_SENDING_STATUS';

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../layout/MainLayout';

const useStyles = makeStyles<Theme>((theme) => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        color: 'white',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 24,
        textAlign: 'center',
    },
    margin: {
        margin: 'px 0px 40px 0px',
    },
    text: {
        color: '#383838',
        fontSize: '16px',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    img: {
        background: "transparent url('assets/audio.png') 0% 0% no-repeat padding-box",
        height: 271,
        width: 524,
        [theme.breakpoints.down(450)]: {
            width: 'auto',
        },
        margin: 'auto',
    },
}));

export default () => {
    const classes = useStyles();
    const { t } = useTranslation(['frecuencies']);
    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    direction="column"
                    style={{ margin: 50 }}
                >
                    <Grid item lg={4} md={4} sm={12}>
                        <Grid item className={classes.margin}>
                            <Typography className={classes.title} variant="h4" color="initial">
                                {t('frecuencies:title')}
                            </Typography>
                        </Grid>
                        <Grid item />
                        <br />
                        <Grid item className={classes.img} />
                        <br />
                        <Grid item>
                            <Typography className={classes.text} variant="h6">
                                {t('frecuencies:text')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </MainLayout>
    );
};

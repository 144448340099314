// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setItem = (key: string, value: any) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.info(error);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getItem = (key: string): any => {
    try {
        const result = localStorage.getItem(key);
        return result ? JSON.parse(result) : result;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.info(error);
        return null;
    }
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

import { PaymentMethod, PaymentIntent } from '@stripe/stripe-js';

export interface CardType {
    brand: string;
    checks: {
        address_line1_check?: string | null;
        address_postal_code_check?: string | null;
        cvc_check: string;
    };
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from: string | null;
    last4: number;
    networks: {
        available: [string];
        preferred: string | null;
    };
    three_d_secure_usage: {
        supported: boolean;
    };
    wallet: null;
}

export interface PaymentMethods {
    paymentMethods: PaymentMethod[];
}

export interface Data {
    paymentIntents: PaymentIntent[];
    defaultPaymentMethod: PaymentMethod;
    paymentMethodsList: PaymentMethod[];
}

export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    payload: {
        paymentMethod?: PaymentMethod;
        paymentMethods?: PaymentMethod[];
        error?: ErrorType;
        paymentMethodID?: string;
        paymentIntents?: PaymentIntent[];
        paymentIntent?: PaymentIntent;
    };
}

export interface PaymentType {
    paymentIntents?: PaymentIntent[];
}

export const SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD';
export const SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED = 'SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED';
export const SET_DEFAULT_PAYMENT_METHOD_FAILED = 'SET_DEFAULT_PAYMENT_METHOD_FAILED';

export const GET_DEFAULT_PAYMENT_METHOD = 'GET_DEFAULT_PAYMENT_METHOD';
export const GET_DEFAULT_PAYMENT_METHOD_SUCCEEDED = 'GET_DEFAULT_PAYMENT_METHOD_SUCCEEDED';
export const GET_DEFAULT_PAYMENT_METHOD_FAILED = 'GET_DEFAULT_PAYMENT_METHOD_FAILED';

export const LIST_PAYMENT_METHOD = 'LIST_PAYMENT_METHOD';
export const LIST_PAYMENT_METHOD_SUCCEEDED = 'LIST_PAYMENT_METHOD_SUCCEEDED';
export const LIST_PAYMENT_METHOD_FAILED = 'LIST_PAYMENT_METHOD_SUCCEEDED';

export const LOADING_PAYMENTS = 'LOADING_PAYMENTS';

export const LIST_PAYMENT_INTENT = 'LIST_PAYMENT_INTENT';
export const LIST_PAYMENT_INTENT_SUCCEEDED = 'LIST_PAYMENT_INTENT_SUCCEEDED';
export const LIST_PAYMENT_INTENT_FAILED = 'LIST_PAYMENT_INTENT_SUCCEEDED';

export const SET_CUSTOMER_TO_PAYMENT_INTENT = 'SET_CUSTOMER_TO_PAYMENT_INTENT';
export const SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED = 'SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED';
export const SET_CUSTOMER_TO_PAYMENT_INTENT_FAILED = 'SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED';

import React from 'react';
import { makeStyles, Theme, CircularProgress, Modal, withStyles } from '@material-ui/core';

const useStyle = makeStyles<Theme>(() => ({
    root: {
        paddingTop: window.screen.height / 2,
        textAlign: 'center',
        width: '100%',
        position: 'fixed',
        opacity: 1,
        backgroundColor: 'trasparent',
        borderColor: 'trasparent',
    },
}));

type Props = {
    show: boolean;
};

export const ColorCircularProgress = withStyles({
    root: {
        color: '#428cd0',
    },
})(CircularProgress);

export const LoaderComponent = ({ show }: Props) => {
    const classes = useStyle();
    return (
        <Modal open={show} aria-labelledby="root" aria-describedby="root" className={classes.root}>
            <CircularProgress size="60px" thickness={5} />
        </Modal>
    );
};

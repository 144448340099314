import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { GlobalStateType } from '../../redux/rootTypes';
import BigButton from '../utils/Button/BigButton';
import { setSelectedPriceErrorAction } from '../../redux/prices/actions';
import { createSubscriptionAction } from '../../redux/subscription/actions';
import useOnlineDispatch from '../OnlineDispatch';

const StartSubscription = () => {
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: GlobalStateType) => state.user);
    const { selectedPrice } = useSelector((state: GlobalStateType) => state.prices);
    const { loading } = useSelector((state: GlobalStateType) => state.subscription);
    const { defaultPaymentMethod } = useSelector((state: GlobalStateType) => state.payments.data);
    const [showButton, setShowButton] = React.useState<boolean>(false);

    useEffect(() => {
        if (!defaultPaymentMethod?.id || loading) setShowButton(false);
        else setShowButton(true);
    }, [loading, defaultPaymentMethod]);

    const startSubscription = async () => {
        if (!selectedPrice) {
            dispatch(
                setSelectedPriceErrorAction({ error: { message: '*Select a Price Plan first' } }),
            );
            return;
        }
        if (user?.stripeCustomerID && selectedPrice?.id && defaultPaymentMethod?.id) {
            onlineDispatch(
                createSubscriptionAction({
                    customerID: user?.stripeCustomerID,
                    priceID: selectedPrice.id,
                    paymentMethodID: defaultPaymentMethod.id,
                    user,
                }),
            );
        }
        history.push('/home');
    };

    return showButton ? (
        <BigButton title="Start Now" type="primary" onClick={startSubscription} />
    ) : null;
};

export default StartSubscription;

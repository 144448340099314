import { API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { getHeader } from '../../utils/api';

API.configure(awsexports);

type Body = {
    customerID?: string;
    paymentMethodID?: string;
    item?: string;
    paymentMethods?: boolean;
    paymentIntentID?: string;
};

const apiName = 'BillingPayment';
const path = '/';
const myInit: {
    headers: string;
    response: true;
    queryStringParameters: Body;
    body: Body;
} = {
    headers: '',
    response: true,
    queryStringParameters: {},
    body: {},
};

const getElements = async (queryStringParameters: Body) => {
    const options = {
        ...myInit,
        queryStringParameters,
        headers: await getHeader({}),
    };
    const { data } = await API.get(apiName, path, options);
    return data;
};

export const setDefaultPaymentsMethod = async ({ customerID, paymentMethodID }: Body) => {
    myInit.headers = await getHeader(myInit.headers);
    myInit.body.customerID = customerID;
    myInit.body.paymentMethodID = paymentMethodID;
    const { data } = await API.put(apiName, path, myInit);
    return { paymentMethod: { id: data.invoice_settings.default_payment_method } };
};

export const getDefaultPaymentsMethod = async ({
    paymentMethodID,
}: {
    paymentMethodID: string;
}) => {
    const data = await getElements({
        paymentMethodID,
        paymentMethods: true,
    });
    return { paymentMethod: data };
};

export const listPaymentsMethods = async (customerID: string) => {
    const data = await getElements({
        customerID,
        paymentMethods: true,
    });
    // console.log('listPaymentsMethods data: ', data);
    return { paymentMethods: data?.data };
};

export const listPaymentIntents = async (customerID: string) => {
    const data = await getElements({
        customerID,
    });
    return { paymentIntents: data?.data };
};

export const createPaymentIntent = async (item: string) => {
    myInit.headers = await getHeader(myInit.headers);
    myInit.body.item = item;
    const { data } = await API.post(apiName, path, myInit);
    return data.clientSecret;
};

export const updatePaymentIntent = async ({
    customerID,
    paymentIntentID,
}: {
    customerID: string;
    paymentIntentID: string;
}) => {
    myInit.headers = await getHeader(myInit.headers);
    myInit.queryStringParameters = {};
    myInit.body = {
        customerID,
        paymentIntentID,
    };
    const { data } = await API.patch(apiName, path, myInit);
    return data;
};

import { makeStyles, Theme } from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import MainLayout from '../../layout/MainLayout';
import { GlobalStateType } from '../../redux/rootTypes';
import { PanelType } from '../../redux/panels/types';
import HarmonizerPanel from '../../components/InfoPanel';

const useStyle = makeStyles<Theme>((theme) => ({
    rootStyles: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',
        paddingLeft: 20,
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    alternativeRootStyles: {
        height: '100%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        opacity: 1,
        paddingLeft: 20,
    },
}));

const InfoPanel = () => {
    const classes = useStyle();
    const activity = useSelector((state: GlobalStateType) => state.panels.activity);
    const { id } = useParams<{ id: string }>();
    const { isShowingInfo, panels }: { isShowingInfo: boolean; panels: PanelType[] } = useSelector(
        (state: GlobalStateType) => ({
            isShowingInfo: state.header.isShowingInfo,
            panels: state.panels.data,
        }),
    );
    const [panel, setPanel] = useState<PanelType | undefined>(
        panels.find((p) => !!p && (p.id === id || p.id === activity?.id)),
    );

    useEffect(() => {
        setPanel(panels.find((p) => !!p && (p.id === id || p.id === activity?.id)));
    }, [panels, id, activity]);

    return (
        <MainLayout>
            <div className={isShowingInfo ? classes.alternativeRootStyles : classes.rootStyles}>
                <HarmonizerPanel panel={panel} />
            </div>
        </MainLayout>
    );
};

export default InfoPanel;

import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import {
    SET_DEFAULT_PAYMENT_METHOD,
    SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED,
    SET_DEFAULT_PAYMENT_METHOD_FAILED,
    LIST_PAYMENT_METHOD,
    LIST_PAYMENT_METHOD_SUCCEEDED,
    LIST_PAYMENT_METHOD_FAILED,
    PaymentMethods,
    ErrorType,
    LOADING_PAYMENTS,
    GET_DEFAULT_PAYMENT_METHOD,
    LIST_PAYMENT_INTENT,
    LIST_PAYMENT_INTENT_SUCCEEDED,
    LIST_PAYMENT_INTENT_FAILED,
    SET_CUSTOMER_TO_PAYMENT_INTENT,
    SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED,
    SET_CUSTOMER_TO_PAYMENT_INTENT_FAILED,
} from './types';

export const setDefaultPaymentMethodAction = (payload: {
    customerID: string;
    paymentMethodID: string;
}) => ({
    type: SET_DEFAULT_PAYMENT_METHOD,
    payload,
});

export const setDefaultPaymentMethodSuccessAction = (payload: {
    paymentMethod: PaymentMethod;
}) => ({
    type: SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED,
    payload,
});

export const setDefaultPaymentMethodFailedAction = (payload: { error: ErrorType }) => ({
    type: SET_DEFAULT_PAYMENT_METHOD_FAILED,
    payload,
});

export const getDefaultPaymentMethodAction = (payload: { paymentMethodID: string }) => ({
    type: GET_DEFAULT_PAYMENT_METHOD,
    payload,
});

export const getDefaultPaymentMethodSuccessAction = (payload: {
    paymentMethod: PaymentMethod;
}) => ({
    type: SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED,
    payload,
});

export const getDefaultPaymentMethodFailedAction = (payload: { error: ErrorType }) => ({
    type: SET_DEFAULT_PAYMENT_METHOD_FAILED,
    payload,
});

export const listPaymentMethodAction = (payload: { customerID?: string }) => ({
    type: LIST_PAYMENT_METHOD,
    payload,
});

export const listPaymentMethodSuccessAction = (payload: PaymentMethods) => ({
    type: LIST_PAYMENT_METHOD_SUCCEEDED,
    payload,
});

export const listPaymentMethodFailedAction = (payload: { error: ErrorType }) => ({
    type: LIST_PAYMENT_METHOD_FAILED,
    payload,
});

export const paymentLoadingAction = (payload: boolean) => ({
    type: LOADING_PAYMENTS,
    payload,
});

export const listPaymentIntentAction = (payload: { customerID: string }) => ({
    type: LIST_PAYMENT_INTENT,
    payload,
});

export const listPaymentIntentSuccessAction = (payload: { paymentIntents: PaymentIntent[] }) => ({
    type: LIST_PAYMENT_INTENT_SUCCEEDED,
    payload,
});

export const listPaymentIntentFailedAction = (payload: { error: ErrorType }) => ({
    type: LIST_PAYMENT_INTENT_FAILED,
    payload,
});

export const setCustomerToPaymentIntentAction = (payload: {
    customerID: string;
    paymentIntentID: string;
}) => ({
    type: SET_CUSTOMER_TO_PAYMENT_INTENT,
    payload,
});

export const setCustomerToPaymentIntentSuccessAction = (payload: {
    paymentIntent: PaymentIntent;
}) => ({
    type: SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED,
    payload,
});

export const setCustomerToPaymentIntentFailedAction = (payload: { error: ErrorType }) => ({
    type: SET_CUSTOMER_TO_PAYMENT_INTENT_FAILED,
    payload,
});

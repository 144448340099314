const awsconfig = {
    aws_project_region: process.env.REACT_APP_AWS_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_REGION,
    federationTarget: 'COGNITO_USER_POOLS',
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_AWS_COGNITO_USER_POOL_DOMAIN,
            scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_AWS_COGNITO_USER_POOL_SIGN_IN_URLS,
            redirectSignOut: process.env.REACT_APP_AWS_COGNITO_USER_POOL_SIGN_OUT_URLS,
            responseType: 'code',
        },
    },
    API: {
        endpoints: [
            {
                name: 'User',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/user`,
            },
            {
                name: 'Panel',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/panel`,
            },
            {
                name: 'BillingCustomer',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/billingcustomer`,
            },
            {
                name: 'BillingProduct',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/billingproduct`,
            },
            {
                name: 'BillingPrices',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/billingprices`,
            },

            {
                name: 'BillingPayment',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/billingpayment`,
            },
            {
                name: 'BillingSubscription',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/billingsubscription`,
            },
            {
                name: 'BillingInvoices',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/billinginvoices`,
            },
            {
                name: 'SendMail',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/sendemail`,
            },
            {
                name: 'reCaptcha',
                endpoint: `${process.env.REACT_APP_AWS_APIGATEWAY_URL}/googlerecaptcha`,
            },
        ],
    },
    Storage: {
        region: process.env.REACT_APP_AWS_REGION,
        bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    },
};

export default awsconfig;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import BigButton from '../../components/utils/Button/BigButton';

export default () => {
    const history = useHistory();
    return (
        <Grid container spacing={1} justify="center" alignContent="center">
            <Grid item>
                <Typography variant="h1" color="initial" style={{ color: '#0468BF' }}>
                    404 Not Found
                </Typography>
                <BigButton title="Go Home" type="primary" onClick={() => history.push('/home')} />
            </Grid>
        </Grid>
    );
};

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        hr: {
            width: '100%',
            height: '2px',
            margin: '25px 25px 25px 0',
            background: '#a3cdf1',
            border: 'none',
            [theme.breakpoints.down(768)]: {
                margin: '25px 25px 25px 25px',
            },
        },
    }),
);

export default function Line() {
    const classes = useStyles();
    return <hr className={classes.hr} />;
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';

export default function LoginTrigger() {
    const user = useSelector((state: GlobalStateType) => state.user);

    useEffect(() => {
        // console.log('LoginTrigger: ', JSON.stringify(user));
        if (user?.errors && typeof user.errors === 'string') {
            window.location.href = '/login';
        } else if (user?.sub && window.location.href.indexOf('login') > -1) {
            window.location.href = '/aura';
        }
    }, [user]);

    return <></>;
}

import {
    createStyles,
    Grid,
    InputBase,
    makeStyles,
    TextField,
    Theme,
    withStyles,
} from '@material-ui/core';
import React from 'react';
import Alert from '../utils/Alert/Alert';
import CreatePaymentMethod from './createPaymentMethod/CreatePaymentMethod';

export const StyledInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
            '& .MuiSelect-icon': {
                color: '#0468BF',
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '10px 26px 10px 12px',
            boxShadow: '0px 3px 6px #00000008',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            // Use the system font instead of the default Roboto font.
            fontFamily: ['Roboto'].join(','),
            '&:focus': {
                borderRadius: 4,
            },
        },
    }),
)(InputBase);

export const StyledTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '& input:valid + fieldset': {
                borderColor: '#FFF',
                borderWidth: 1,
            },
            '& input:invalid + fieldset': {
                borderColor: '#FFF',
                borderWidth: 1,
            },
            '& input:valid:focus + fieldset': {
                borderLeftWidth: 5,
                borderWidth: 2,
                borderColor: '#FFF',
                color: '#FFF',
            },
            '& label.Mui-focused': {
                color: 'black',
            },
            '& label': {
                color: 'black',
                fontWeight: 'bold',
                top: '-5px',
            },
            '& input': {
                borderRadius: 4,
                position: 'relative',
                backgroundColor: theme.palette.background.paper,
                fontSize: 16,
                padding: '10px 26px 10px 12px',
                boxShadow: '0px 3px 6px #00000008',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
                // Use the system font instead of the default Roboto font.
                fontFamily: ['Roboto'].join(','),
                '&:focus': {
                    borderRadius: 4,
                },
            },
        },
    }),
)(TextField);

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            maxWidth: '750px',
            '& p': {
                padding: '6px',
            },
            '& #title': {
                textTransform: 'capitalize',
                fontWeight: 'bold',
                color: '#0468BF',
                fontSize: '24px',
            },
            '& #sub_title': {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
    }),
);

const ModifyBillingInfo: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid className={classes.container}>
            <p id="title">Modify Billing Info</p>
            <Alert msg="The changes in your billing information will be effective from your next invoice." />
            <Alert msg="IMPORTANT: Invoices generation for the United Kingdom could be delayed up to one month due to Brexit." />
            <CreatePaymentMethod />
        </Grid>
    );
};

export default ModifyBillingInfo;

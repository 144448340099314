import React from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';

type ButtonProps = {
    title: string;
    type: 'primary' | 'secundary' | 'white';
    job?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
};

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            width: '302px',
            height: '72px',
            borderRadius: '15px',
            padding: '10px 20px 10px 20px !important',
            '& .MuiButton-label': {
                fontSize: '20px',
                fontWeight: 'bolder',
                textTransform: 'capitalize',
            },
        },
        primary: {
            color: 'white',
            backgroundColor: '#00BBFF',

            '& .MuiButton-label': {
                color: 'white',
                transition: '300ms',
            },
            '&:hover': {
                backgroundColor: 'white',
                '& .MuiButton-label': {
                    color: '#00BBFF',
                },
            },
        },
        secundary: {
            backgroundColor: 'white',

            '& .MuiButton-label': {
                color: '#00BBFF',
            },
            '&:hover': {
                backgroundColor: '00BBFF',
                '& .MuiButton-label': {
                    color: '#00BBFF',
                },
            },
        },
        white: {
            backgroundColor: 'white',

            '& .MuiButton-label': {
                color: '#00BBFF',
            },
            '&:hover': {
                backgroundColor: '00BBFF',
                '& .MuiButton-label': {
                    color: '#00BBFF',
                },
            },
        },
    }),
);

const BigButton: React.FC<ButtonProps> = ({ title, type, job, onClick }: ButtonProps) => {
    const classes = useStyles();
    return (
        <Button
            onClick={onClick}
            className={`${classes.button} ${classes[type]}`}
            size="large"
            type={job}
        >
            {title}
        </Button>
    );
};

BigButton.defaultProps = {
    onClick: () => {},
    job: undefined,
};

export default BigButton;

import React from 'react';
// import MainLayout from '../../layout/MainLayout';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import Signed from '../../components/Auth/Signed';

export default () => {
    return (
        <AuthWrapper>
            <Signed />
        </AuthWrapper>
    );
};

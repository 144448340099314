import React, { useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles, Radio, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod } from '@stripe/stripe-js';
import { ReactComponent as MasterCardIcon } from '../../../assets/svg_images/mastercard.svg';
import { ReactComponent as VisaCardIcon } from '../../../assets/svg_images/visa.svg';
import { ReactComponent as GenericCardIcon } from '../../../assets/svg_images/generic_card_icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/svg_icons/checkIcon.svg';
import { GlobalStateType } from '../../../redux/rootTypes';
import {
    getDefaultPaymentMethodAction,
    setDefaultPaymentMethodAction,
} from '../../../redux/payments/actions';
import useOnlineDispatch from '../../OnlineDispatch';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& p:nth-child(1)': {
                fontWeight: 'bold',
            },
        },
        cardContainer: {
            fontSize: '12px',
        },
    }),
);

const StyledRadio = withStyles({
    root: {
        color: 'grey',
        padding: '0',
        '&$checked': {
            color: '#ffd700',
        },
    },
    checked: {},
})(Radio);

type CardInfoProps = {
    paymentMethod: PaymentMethod;
    active: boolean;
};

export default function CardInfo({ paymentMethod, active }: CardInfoProps) {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [card, setCard] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [Icon, setIcon] = useState<any>(GenericCardIcon);
    const { stripeCustomerID } = useSelector((state: GlobalStateType) => state.user);
    const { defaultPaymentMethod } = useSelector((state: GlobalStateType) => state.payments.data);

    useEffect(() => {
        // console.log(defaultPaymentMethod);
        if (!defaultPaymentMethod.id && paymentMethod.id)
            onlineDispatch(getDefaultPaymentMethodAction({ paymentMethodID: paymentMethod.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, paymentMethod.id]);

    useEffect(() => {
        switch (card?.brand) {
            case 'visa':
                setIcon(VisaCardIcon);
                break;
            case 'mastercard':
                setIcon(MasterCardIcon);
                break;
            default:
                break;
        }
    }, [card]);

    useEffect(() => {
        setCard(paymentMethod?.card);
    }, [paymentMethod]);

    const handleClick = () => {
        if (stripeCustomerID)
            onlineDispatch(
                setDefaultPaymentMethodAction({
                    customerID: stripeCustomerID,
                    paymentMethodID: paymentMethod.id,
                }),
            );
    };

    return (
        <Grid container justify="center" alignItems="center" className={classes.cardContainer}>
            <Grid item container xs={1} justify="flex-start">
                <StyledRadio
                    checked={active}
                    size="small"
                    style={{ padding: '0 !important' }}
                    onClick={handleClick}
                />
            </Grid>
            <Grid item container xs={3} justify="flex-start">
                <Icon width="72" height="54" />
            </Grid>
            <Grid item container justify="flex-start" xs={4}>
                <Grid item xs={12}>
                    {card?.brand}
                </Grid>
                <Grid item xs={12}>
                    {`Checking •••• ${card?.last4 ? card.last4 : 'Loading...'}`}
                </Grid>
            </Grid>
            <Grid item container xs={3} wrap="nowrap" justify="flex-end">
                {active && (
                    <>
                        <CheckIcon /> Active
                    </>
                )}
            </Grid>
        </Grid>
    );
}

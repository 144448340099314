/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import MainLayout from '../../layout/MainLayout';
import BigButton from '../../components/utils/Button/BigButton';
import ChangePassword from '../../components/ChangePassword/index';

const useStyles = makeStyles(() => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        display: 'flex',
        justifyContent: 'center',
        padding: 40,
    },
    button: {
        backgroundColor: '#FFD701',
        color: 'white',
    },
    title: {
        color: 'white',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    link: {
        color: 'black',
        textDecorationLine: 'underline',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
    },
    text: {
        color: '#383838',
        fontSize: '16px',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
}));

export default () => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const goToSendMail = () => history.push('send-mail');

    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid
                    container
                    justify="flex-start"
                    item
                    lg={3}
                    md={3}
                    sm={12}
                    spacing={2}
                    direction="column"
                    style={{ marginTop: 50 }}
                >
                    <Grid item>
                        <Typography className={classes.title} variant="h4" color="initial">
                            How can we help you?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            onClick={() => {
                                history.push('my-account');
                            }}
                            className={classes.link}
                            variant="h6"
                            color="initial"
                        >
                            Can't remember login details
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            onClick={() => {
                                setOpen(true);
                            }}
                            className={classes.link}
                            variant="h6"
                            color="initial"
                        >
                            Change password
                        </Typography>
                    </Grid>
                    {/* <Grid item>
                        <Typography className={classes.link} variant="h6" color="initial">
                            Manage your personal details
                        </Typography>
                    </Grid> */}
                    <Grid item>
                        <Typography
                            onClick={() => {
                                history.push('/privacy-policy');
                            }}
                            className={classes.link}
                            variant="h6"
                            color="initial"
                        >
                            Privacy Policy
                        </Typography>
                    </Grid>
                    {/*    <Grid item>
                        <Typography className={classes.link} variant="h6" color="initial">
                            Terms and Conditions
                        </Typography>
                    </Grid> */}
                    <Grid item>
                        <Typography className={classes.text} variant="h6">
                            Can't find what you're looking for? Send us an email and we'll get back
                            to you within 24 hours.
                        </Typography>
                    </Grid>
                    <Grid>
                        <BigButton title="Send Email" type="primary" onClick={goToSendMail} />
                    </Grid>
                </Grid>
                <br />
                <ChangePassword open={open} handleCloseDialog={handleCloseDialog} />
            </div>
        </MainLayout>
    );
};

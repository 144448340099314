import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  TextareaAutosize,
  CircularProgress
} from '@material-ui/core';
import { useHistory } from 'react-router';
import chakraBg from "../../assets/chakra-bg.png"
import browChakraImg from "../../assets/brow-chakra.png"
import crownChakraImg from "../../assets/crown-chakra.png"
import heartChakraImg from "../../assets/heart-chakra.png"
import rootChakraImg from "../../assets/root-chakra.png"
import sacralChakraImg from "../../assets/sacral-chakra.png"
import solarChakraImg from "../../assets/solar-chakra.png"
import throatChakraImg from "../../assets/throat-chakra.png"
import MainLayout from '../../layout/MainLayout'

const useStyle = makeStyles<Theme>((theme) => ({
  rootLinear: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  bg: {
    background: `url('../../assets/demoImg/yoga.jpg')`,
    backgroundSize: 'cover',
    minHeight: 'calc(100vh - 200px)',
    height: 'auto',
    width: '100vw',
    // marginRight: '-20px !important',
    // marginLeft: '-20px !important',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  scanButton: {
    backgroundColor: '#f10 !important',
    fontWeight: "bold",
    minWidth: '100% !important',
    margin: '20px auto !important',
    display: 'block !important',
    color: '#fff !important',
    height: 'auto !important',
    borderRadius: '5px !important',
    padding: '10px 20px 10px 20px !important',
    border: "1px solid #747474 !important",
    lineHeight: '1',
  },
  wrap: {
    margin: '0px auto',
    width: '50%',
  },
  chakraScan: {
    background: "url(assets/bg.png)",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageTitle: {
    color: '#ffffff',
    width: '100%',
    margin: '0',
    borderTop: '1px solid #fff',
    borderBottom: '2px solid #fff',
    padding: '10px 0',
    textAlign: 'center',
    boxSizing: 'border-box',
    zIndex: '999',
    textTransform: 'uppercase',
    fontSize: '16px',
    marginBottom: '30px'
  },
  auraTypeImage: {
    position: 'absolute',
    zIndex: '999',
    left: '88%',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: '0',
    listStyleType: 'none',
    margin: '0',
  },
  // activeChakra: {
  //   background: 'linear-gradient(90deg, #f10f10, #1b6cba)',
  // }
}));
const ChakraScreen = () => {
  const classes = useStyle();
  const historty = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [successClass, setSuccessClass] = useState(false);
  const [pic, setPic] = useState()

  useEffect(() => {
    // Get an item from local storage
    const storedItem = localStorage.getItem('capturedImage');
    if (storedItem) {
      setPic(storedItem)
    }

    // Do something with the retrieved item
    // console.log('Retrieved item from local storage:', storedItem);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setOpen(true);
  }, [])
  const [crownChakra, setCrownChakra] = useState(null);
  const [browChakra, setBrowChakra] = useState(null);
  const [throatChakra, setThroatChakra] = useState(null);
  const [heartChakra, setHeartChakra] = useState(null);
  const [solarChakra, setSolarChakra] = useState(null);
  const [sacralChakra, setSacralChakra] = useState(null);
  const [rootChakra, setRootChakra] = useState(null);
  const chkraCenter = () => {
    setCrownChakra(Math.floor(Math.random() * 900) + 100);
    setBrowChakra(Math.floor(Math.random() * 900) + 100);
    setThroatChakra(Math.floor(Math.random() * 900) + 100);
    setHeartChakra(Math.floor(Math.random() * 900) + 100);
    setSolarChakra(Math.floor(Math.random() * 900) + 100);
    setSacralChakra(Math.floor(Math.random() * 900) + 100);
    setRootChakra(Math.floor(Math.random() * 900) + 100);
  }
  const listItems = [
    { id: 0, dataNumber: crownChakra },
    { id: 1, dataNumber: browChakra },
    { id: 2, dataNumber: throatChakra },
    { id: 3, dataNumber: heartChakra },
    { id: 4, dataNumber: solarChakra },
    { id: 5, dataNumber: sacralChakra },
    { id: 6, dataNumber: rootChakra },
  ];
  const maxDataNumber = listItems.reduce((maxItem, currentItem) =>
    currentItem.dataNumber > maxItem.dataNumber ? currentItem : maxItem
  );
  const maxDataNumberItem = maxDataNumber.dataNumber;
  // Remove the item with the maximum dataNumber from the list
  const listWithoutMax = listItems.filter((item) => item !== maxDataNumber);
  // Find the item with the second maximum dataNumber
  const secondMaxDataNumber = listWithoutMax.reduce(
    (maxItem, currentItem) =>
      currentItem.dataNumber > maxItem.dataNumber ? currentItem : maxItem,
    { dataNumber: -Infinity }
  );
  const listWithoutSecMax = listWithoutMax.filter((item) => item !== secondMaxDataNumber);
  const secondMaxDataNumberItem = secondMaxDataNumber.dataNumber;

  // Remove the item with the second maximum dataNumber from the list
  const thirdMaxDataNumber = listWithoutSecMax.reduce(
    (maxItem, currentItem) =>
      currentItem.dataNumber > maxItem.dataNumber ? currentItem : maxItem,
    { dataNumber: -Infinity }
  );
  const thirdMaxDataNumberItem = thirdMaxDataNumber.dataNumber;
  const handleClickScan = () => {
    setOpenLoader(true)
    setTimeout(() => {
      setOpenLoader(false)
      setSuccessClass(true)
      chkraCenter();
    }, 6000)
  }
  const [openPDF, setOpenPDF] = useState(false)
  const handleClickPDF = () => {
    setOpenPDF(true)
  }
  const handleClosePDF = () => {
    setOpenPDF(false);
  }
  const handleOpenUserRecorde = () => {
    historty.push('/home');
  }
  return (
    <>
      <MainLayout>
        <div className={classes.chakraScan} style={{ width: "100vw", margin: "0 -20px" }}>
          <div style={{ width: "400px", maxWidth: '100%', position: 'relative' }} className={`bg_screen bg_chakra ${successClass && 'success_bg--'}`} >
            {/* <div className='scan-img-wrap'>
              <img src={pic} alt="" className={`${openLoader && "fadeinout"}`} />
            </div> */}

            <ul className='auraTypeName'>
              <li> <h2 className={classes.pageTitle}>Main Chakra Center</h2></li>
              <li data-number={crownChakra} className={
                crownChakra && (crownChakra === maxDataNumberItem || crownChakra === secondMaxDataNumberItem || crownChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Crown Chakra <span className='chakraNum'>{crownChakra}</span></li>
              <li data-number={browChakra} className={
                browChakra && (browChakra === maxDataNumberItem || browChakra === secondMaxDataNumberItem || browChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Brow Chakra <span className='chakraNum'>{browChakra}</span></li>
              <li data-number={throatChakra} className={
                throatChakra && (throatChakra === maxDataNumberItem || throatChakra === secondMaxDataNumberItem || throatChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Throat Chakra <span className='chakraNum'>{throatChakra}</span></li>
              <li data-number={heartChakra} className={
                heartChakra && (heartChakra === maxDataNumberItem || heartChakra === secondMaxDataNumberItem || heartChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Heart Chakra <span className='chakraNum'>{heartChakra}</span></li>
              <li data-number={solarChakra} className={
                solarChakra && (solarChakra === maxDataNumberItem || solarChakra === secondMaxDataNumberItem || solarChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Solar Chakra <span className='chakraNum'>{solarChakra}</span></li>
              <li data-number={sacralChakra} className={
                sacralChakra && (sacralChakra === maxDataNumberItem || sacralChakra === secondMaxDataNumberItem || sacralChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Sacral Chakra <span className='chakraNum'>{sacralChakra}</span></li>
              <li data-number={rootChakra} className={
                rootChakra && (rootChakra === maxDataNumberItem || rootChakra === secondMaxDataNumberItem || rootChakra === thirdMaxDataNumberItem) ? 'acitveChakra' : ''}>Root Chakra <span className='chakraNum'>{rootChakra}</span></li>
            </ul>
            <ul className={classes.auraTypeImage}>
              <li style={{ lineHeight: '0' }}><img src={crownChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
              <li style={{ lineHeight: '0' }}><img src={browChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
              <li style={{ lineHeight: '0' }}><img src={throatChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
              <li style={{ lineHeight: '0' }}><img src={heartChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
              <li style={{ lineHeight: '0' }}><img src={solarChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
              <li style={{ lineHeight: '0' }}><img src={sacralChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
              <li style={{ lineHeight: '0' }}><img src={rootChakraImg} alt="" style={{ width: "40px", borderRadius: "50%" }} /></li>
            </ul>
            <div className='scan-btn-wrap'>
              {!successClass
                ?
                <Button className={classes.scanButton} onClick={handleClickScan}> Scan </Button>
                :
                <Button className={classes.scanButton} onClick={handleOpenUserRecorde}> Done </Button>
              };
            </div>
            <div className='pdf-btn-wrap'>
              <Button className={classes.pdf} onClick={handleClickPDF}> ? </Button>
            </div>
          </div>
        </div>
      </MainLayout>
      <Dialog
        open={openLoader}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description1"
      >
        <DialogContent style={{ backgroundColor: "#0d3c61" }}>
          <DialogContentText id="alert-dialog-description1" style={{ color: "#fff" }}>
            EnergyScan in progress
            <div className='loader-wrap'>
              <CircularProgress />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* ===================== PDF MODAL ===================== */}
      <Dialog
        open={openPDF}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description2"
        className='custom-pop-up'
      >
        <DialogContent style={{ backgroundColor: "#0d3c61" }}>
          <DialogContentText id="alert-dialog-description2" style={{ color: "#fff" }}>
            <iframe title='Aura Scaner' src="../assets/Chakras_Scan.pdf" width="100%" height="500px" />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#0d3c61" }}>
          <Button onClick={handleClosePDF} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChakraScreen
import {
    SET_MEDIA_DOWNLOADING,
    GET_MEDIA_SUCCEEDED,
    GET_MEDIA_FAILED,
    LIST_MEDIA_SUCCEEDED,
    LIST_MEDIA_FAILED,
    GET_MEDIA_URL_SUCCEEDED,
    GET_MEDIA_URL_FAILED,
    MediaType,
    ErrorType,
} from './types';

const initialState: {
    data: MediaType[];
    currentMediaDownloading: MediaType | null;
} = {
    data: [],
    currentMediaDownloading: null,
};

export default function reducer(
    state = initialState,
    action: {
        type: string;
        payload: { media?: MediaType; medias?: MediaType[]; error?: ErrorType };
    },
) {
    // eslint-disable-next-line no-console
    // console.log('reducer media: ', action, state);

    if (!action) return state;
    if (!action.payload) return state;

    const key = action.payload?.media?.key || null;
    const media = state.data.find((m) => m.key === key);
    const tempState = state.data.filter((m) => m.key !== key);
    const currentMediaDownloading = { ...media, ...action.payload.media };

    switch (action.type) {
        case SET_MEDIA_DOWNLOADING:
            return {
                ...state,
                data: [...tempState, currentMediaDownloading],
                currentMediaDownloading,
            };
        case GET_MEDIA_SUCCEEDED:
        case GET_MEDIA_URL_SUCCEEDED:
            if (key)
                return {
                    ...state,
                    data: [...tempState, { ...media, ...action.payload.media }],
                    currentMediaDownloading: null,
                };
            return {
                ...state,
                currentMediaDownloading: null,
            };
        case GET_MEDIA_URL_FAILED:
        case GET_MEDIA_FAILED:
            return {
                ...state,
                error: action.payload.error,
                currentMediaDownloading: null,
            };
        case LIST_MEDIA_SUCCEEDED:
            return {
                ...state,
                data: action.payload.medias,
            };
        case LIST_MEDIA_FAILED:
            return {
                ...state,
                error: action.payload.error,
            };
        default:
            return state;
    }
}

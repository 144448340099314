/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Avatar } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ReactComponent as ImagePlaceholder } from '../../assets/svg_images/ProfilePlaceholder.svg';
import { GlobalStateType } from '../../redux/rootTypes';
import { getItem, setItem } from '../../utils/localStorage';
import { USER_KEY } from '../../redux/user/types';
import awsconfig from '../../aws-exports';

Storage.configure(awsconfig);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
    }),
);

export default function UserAvatar({ large, image }: { large?: boolean; image?: any }) {
    const classes = useStyles();
    const user = useSelector((state: GlobalStateType) => state.user);
    const [localImage, setLocalImage] = useState<any>(null);
    const getUserAvatar = async () => {
        const localUser = getItem(USER_KEY);
        if (localUser?.avatar) {
            // setLocalImage(localUser.avatar);
            // return;
        }
        if (user.hasAvatar)
            try {
                const avatarList = await Storage.list(`avatars/${user.sub}`);
                const sortedAvatars = avatarList.sort((a, b) => b.lastModified - a.lastModified);

                // const result: any = await Storage.get(`avatars/${user.sub}`, {
                //     level: 'public',
                //     download: true,
                // });
                // const avatar = URL.createObjectURL(result.Body);
                if (sortedAvatars.length > 0) {
                    const mostRecentAvatarKey = sortedAvatars[0].key;
                    const mostRecentAvatar = await Storage.get(mostRecentAvatarKey);
                    console.log('Most recent avatar:', mostRecentAvatar);
                    setLocalImage(mostRecentAvatar);
                    // return mostRecentAvatar;
                } else {
                    console.log('No avatars found.');
                }
                // console.log('avatar=====: ', sortedAvatars)

                //setItem(USER_KEY, { ...user, avatar });
            } catch (error) {
                console.info('Not avatar found yet', error);
            }
    };
    useEffect(() => {
        getUserAvatar();
        // console.log('okkkkk');
    }, [user]);

    useEffect(() => {
        // console.log('okkkkk');
        if (image) setLocalImage(image);
    }, [image]);

    return (
        <Avatar
            alt="Travis Howard"
            className={large ? classes.large : classes.small}
            src={localImage}
        >
            <ImagePlaceholder />
        </Avatar>
    );
}

UserAvatar.defaultProps = {
    large: false,
    image: null,
};

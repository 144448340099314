/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Grid, useMediaQuery, useTheme, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../layout/MainLayout';

const useStyles = makeStyles(() => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: 'auto',
        display: 'flex',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        padding: 20,
    },
    button: {
        backgroundColor: '#FFD701',
        color: 'white',
    },
    title: {
        color: '#0468BF',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    subTitle: {
        color: '#0468BF',

        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
    },
    subTitle1: {
        color: 'black',

        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
        fontStyle: 'bold',
    },
    text: {
        color: '#383838',
        fontSize: '16px',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
    link: {
        color: 'black',
        textDecorationLine: 'underline',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
    },
    point: {
        color: '#0468BF',
    },
    white: {
        color: 'white !important',
    },
}));

const {
    REACT_APP_YOUTUBE_VIDEO,
    REACT_APP_GOOGLE_DRIVE_TROUBLESHOOTING,
    REACT_APP_GOOGLE_DRIVE_SIGNUP,
} = process.env;

export default () => {
    const classes = useStyles();
    const { t } = useTranslation(['about']);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    direction="column"
                    style={
                        !isMobile
                            ? { marginLeft: 300, marginTop: 50, marginBottom: 50 }
                            : { marginTop: 50, marginBottom: 50 }
                    }
                >
                    <Grid container justify="flex-end" alignItems="center">
                        <Grid item lg={12} md={12} sm={12}>
                            <Grid item>
                                <Typography className={classes.title} variant="h4" color="initial">
                                    How-To
                                </Typography>
                            </Grid>
                            <br />
                            <Grid item>
                                <Typography className={classes.text} variant="h6">
                                    {t('about:text')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justify="flex-start"
                        alignItems="center"
                        style={{ marginTop: 15 }}
                    >
                        <Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong>Videos</strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <ul className="ListPrivacyPolicy">
                                    <li className={classes.point}>
                                        <Typography
                                            className={classes.link}
                                            variant="h6"
                                            color="initial"
                                        >
                                            <Link
                                                target="_blank"
                                                rel="noreferrer"
                                                href={REACT_APP_YOUTUBE_VIDEO}
                                            >
                                                Water Harmonizer Video
                                            </Link>
                                        </Typography>
                                    </li>
                                </ul>
                                ‍
                            </Grid>

                            <Grid item>
                                <Typography
                                    className={classes.subTitle1}
                                    variant="h4"
                                    color="initial"
                                >
                                    <strong>Guides</strong>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <ul className="ListPrivacyPolicy">
                                    <li className={classes.point}>
                                        <Typography
                                            className={classes.link}
                                            variant="h6"
                                            color="initial"
                                        >
                                            <Link
                                                target="_blank"
                                                rel="noreferrer"
                                                href={REACT_APP_GOOGLE_DRIVE_SIGNUP}
                                            >
                                                Sign Up Guide
                                            </Link>
                                        </Typography>
                                    </li>
                                    <li className={classes.point}>
                                        <Typography
                                            className={classes.link}
                                            variant="h6"
                                            color="initial"
                                        >
                                            <Link
                                                target="_blank"
                                                rel="noreferrer"
                                                href={REACT_APP_GOOGLE_DRIVE_TROUBLESHOOTING}
                                            >
                                                Trouble Shooting
                                            </Link>
                                        </Typography>
                                    </li>
                                </ul>
                                ‍
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </MainLayout>
    );
};

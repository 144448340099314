import React, { useState } from 'react';
import { useReactPWAInstall } from 'react-pwa-install';
import { makeStyles } from '@material-ui/core/styles';
import { isIOS, isSafari } from 'react-device-detect';
import InstallNotification from './InstallNotification';
import InstallOnSafariNotification from './InstallOnSafariNotification';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function PWA() {
    const classes = useStyles();
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
    const [showAlert, setShowAlert] = useState(true);

    const handleClick = () => {
        pwaInstall({
            title: 'Install App',
            logo: '/favicon.png',
            features: (
                <ul>
                    <li>
                        Vision: People have reported improved visual acuity using this technology.
                    </li>
                    <li>Memory / Focus: Provides for increased cognitive awareness.</li>
                    <li>General Detox: Helping the body to process and remove unwanted toxins.</li>
                    <li>
                        Inflammation: Using this Quantum technology to reduce inflammatory response.
                    </li>
                    <li>
                        Digestion: Helps provide for a smooth absorption of nutrients while
                        eliminating toxins out of the body.
                    </li>
                    <li>
                        Sleep: Helps to promote a deeper level of relaxation and sleep and
                        restoration.
                    </li>
                    <li>
                        Hormones / Emotions(Hers): Correcting both hormonal and emotional
                        imbalances.
                    </li>
                    <li>
                        Hormones / Emotions(His): Correcting both hormonal and emotional imbalances.
                    </li>
                    <li>Wellness Charge: Super charge your body with optimal nutrition.</li>
                </ul>
            ),
            description: `The Water Harmonizer is a revolutionary wellness app, 
            using the Quantum Field to communicate with your innate intelligence 
            to determine what is required for the body to restore and maintain perfect balance.`,
        })
            .then(() => {
                setShowAlert(false);
                // console.log('installing app');
            })
            // eslint-disable-next-line no-console
            .catch(() => console.error('User opted out from installing'));
    };

    return showAlert && supported() && !isInstalled() ? (
        <div className={classes.root}>
            {isIOS && !isSafari ? (
                <InstallOnSafariNotification setShowAlert={setShowAlert} />
            ) : (
                <InstallNotification handleClick={handleClick} setShowAlert={setShowAlert} />
            )}
        </div>
    ) : null;
}

import { all } from 'redux-saga/effects';
import { getPanelWatcher, listPanelWatcher, setActivityWatcher } from '../panels/sagas';
import { getMediaWatcher, getSignedURLWatcher, listMediaWatcher } from '../medias/sagas';
import {
    loginWatcher,
    logoutWatcher,
    changePasswordWatcher,
    updateUserWatcher,
    fetchUserWatcher,
    updatePaymentFlowStatusWatcher,
} from '../user/sagas';
import { getPriceWatcher, listPriceWatcher } from '../prices/sagas';
import { getProductWatcher, listProductWatcher } from '../product/sagas';
import {
    listPaymentMethodWatcher,
    setDefaultPaymentMethodWatcher,
    getDefaultPaymentMethodWatcher,
    listPaymentIntentWatcher,
    setCustomerToPayimentIntentWatcher,
} from '../payments/sagas';
import {
    getSubscriptionWatcher,
    cancelSubscriptionWatcher,
    createSubscriptionWatcher,
    upgradeSubscriptionWatcher,
    pauseSubscriptionWatcher,
} from '../subscription/sagas';
import { sendEmailWatcher } from '../emails/sagas';
import { getInvoiceWatcher, payInvoiceWatcher } from '../invoices/sagas';

export default function* rootSaga() {
    // Combine sagas with
    yield all([
        getPanelWatcher(),
        listPanelWatcher(),
        getMediaWatcher(),
        loginWatcher(),
        logoutWatcher(),
        changePasswordWatcher(),
        updateUserWatcher(),
        updatePaymentFlowStatusWatcher(),
        fetchUserWatcher(),
        getSubscriptionWatcher(),
        cancelSubscriptionWatcher(),
        createSubscriptionWatcher(),
        upgradeSubscriptionWatcher(),
        pauseSubscriptionWatcher(),
        getProductWatcher(),
        listProductWatcher(),
        getPriceWatcher(),
        listPriceWatcher(),
        listPaymentMethodWatcher(),
        listPaymentIntentWatcher(),
        setCustomerToPayimentIntentWatcher(),
        setDefaultPaymentMethodWatcher(),
        getDefaultPaymentMethodWatcher(),
        setActivityWatcher(),
        getSignedURLWatcher(),
        listMediaWatcher(),
        sendEmailWatcher(),
        getInvoiceWatcher(),
        payInvoiceWatcher(),
    ]);
    // OR
    // yield all([fork(FeatureSaga1)]);
}

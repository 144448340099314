export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    payload: {
        subscription?: SubscriptionType;
        loading?: boolean;
        error?: ErrorType;
    };
}

export interface SubscriptionType {
    customer?: string;
    paymentMethod?: string;
    subscriptionID?: string;
    createdAt?: number;
    subscriptionStatus?: string;
    currentPeriodStart?: number;
    currentPeriodEnd?: number;
    pricePlan?: string;
    cancelAtPeriodEnd?: boolean;
    cancelAt?: number;
    canceledAt?: number;
    pauseCollection?: string;
}

// Replace with your SUBSCRIPTION types
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCEEDED = 'GET_SUBSCRIPTION_SUCCEEDED';
export const GET_SUBSCRIPTION_FAILED = 'GET_SUBSCRIPTION_FAILED';
export const LOADING_SUBSCRIPTION = 'LOADING_SUBSCRIPTION';
export const SUBSCRIPTION_KEY = 'SUBSCRIPTION_KEY';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCEEDED = 'CANCEL_SUBSCRIPTION_SUCCEEDED';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCEEDED = 'CREATE_SUBSCRIPTION_SUCCEEDED';
export const CREATE_SUBSCRIPTION_FAILED = 'CREATE_SUBSCRIPTION_FAILED';
export const UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION';
export const UPGRADE_SUBSCRIPTION_SUCCEEDED = 'UPGRADE_SUBSCRIPTION_SUCCEEDED';
export const UPGRADE_SUBSCRIPTION_FAILED = 'UPGRADE_SUBSCRIPTION_FAILED';
export const PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION';
export const PAUSE_SUBSCRIPTION_SUCCEEDED = 'PAUSE_SUBSCRIPTION_SUCCEEDED';
export const PAUSE_SUBSCRIPTION_FAILED = 'PAUSE_SUBSCRIPTION_FAILED';

import { call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getPanel, listPanels } from './apis';
import { GET_PANEL, LIST_PANEL, PANELS_KEY, PanelType, SET_CURRENT_ACTIVITY } from './types';
import {
    getPanelSuccessAction,
    getPanelFailedAction,
    listPanelSuccessAction,
    listPanelFailedAction,
    seActivitySuccessAction,
    setActivityFailedAction,
} from './actions';
import { loaderOff, loaderOn } from '../loader/actions';
import { getItem } from '../../utils/localStorage';

function* fetchActivity(action: AnyAction) {
    try {
        yield put(loaderOn());
        yield put(seActivitySuccessAction(action.payload.panel));
        yield put(loaderOff());
    } catch (error) {
        yield put(setActivityFailedAction(error));
    }
}

export function* setActivityWatcher() {
    yield takeLatest(SET_CURRENT_ACTIVITY, fetchActivity);
}

function* fetchPanel(action: AnyAction) {
    try {
        yield put(loaderOn());
        const panels: PanelType[] = getItem(PANELS_KEY);
        let response: PanelType | undefined = panels.find((p) => p.id === action.payload);
        if (!response) {
            response = yield call(getPanel, action.payload);
        }
        if (response) yield put(getPanelSuccessAction({ panel: response }));
        else throw new Error('Empty response');
        yield put(loaderOff());
    } catch (error) {
        yield put(getPanelFailedAction(error));
    }
}

export function* getPanelWatcher() {
    yield takeLatest(GET_PANEL, fetchPanel);
}

function* fetchPanelListSaga() {
    try {
        yield put(loaderOn());
        const storedPanels = getItem(PANELS_KEY);
        const response: PanelType[] = yield call(listPanels) || [];
        if (response?.length < 1) {
            yield put(listPanelSuccessAction({ panels: [...(storedPanels?.data || [])] }));
        } else yield put(listPanelSuccessAction({ panels: [...response] }));
        yield put(loaderOff());
    } catch (error) {
        yield put(listPanelFailedAction(error));
    }
}

export function* listPanelWatcher() {
    yield takeLatest(LIST_PANEL, fetchPanelListSaga);
}

import React from 'react';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import ResendConfirmationCode from '../../components/Auth/ResendConfirmationCode';

export default () => {
    return (
        <AuthWrapper>
            <ResendConfirmationCode />
        </AuthWrapper>
    );
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { PaymentIntent } from '@stripe/stripe-js';

import { GlobalStateType } from '../../redux/rootTypes';
import { ColorCircularProgress } from '../Loader';
import { PriceType } from '../../redux/prices/types';

const SinglePaymentInfo = () => {
    const {
        prices: { data: priceList },
        payments: {
            data: { paymentIntents },
            loading: paymentsLoading,
        },
    } = useSelector((state: GlobalStateType) => state);
    const [paymentStatus, setPaymentStatus] = useState<{
        status: string;
        count: number;
        amount: number;
        lastPayment?: PaymentIntent;
        noSucceededPayment?: PaymentIntent;
    }>({ status: 'unpaid', count: 0, amount: 0 });
    const [price, setPrice] = useState<PriceType | undefined>();

    useEffect(() => {
        setPrice(priceList?.find((p) => p.unit_amount === paymentStatus.lastPayment?.amount));
    }, [priceList, paymentStatus]);

    useEffect(() => {
        if (paymentIntents?.length > 0) {
            const amount = paymentIntents
                .map((p) => (p.status === 'succeeded' && p.amount) || 0)
                .reduce((a, c) => a + c);
            const actualPaymentDate = paymentIntents[0]?.created;
            const lastPayment = paymentIntents.find((p) => p.created >= actualPaymentDate);
            const noSucceededPayment = paymentIntents?.find((p) => p.status !== 'succeeded');
            const status = noSucceededPayment ? noSucceededPayment.status : 'paid';
            const count = paymentIntents?.length;
            setPaymentStatus({ status, count, amount, lastPayment, noSucceededPayment });
        }
    }, [paymentIntents]);

    return (
        <Grid item>
            {!paymentsLoading ? (
                <>
                    <p>
                        <strong>Payment Status: </strong> {paymentStatus.status}
                    </p>
                    {price && paymentStatus.lastPayment && (
                        <>
                            <p>
                                Your actual payment plan is:{' '}
                                <strong>${(price.unit_amount || 100) / 100} ONE PAYMENT</strong>
                            </p>
                            Last charged on{' '}
                            <strong>
                                {new Date(
                                    paymentStatus.lastPayment.created * 1000,
                                ).toLocaleString()}
                            </strong>{' '}
                        </>
                    )}
                </>
            ) : (
                <>
                    <ColorCircularProgress />
                </>
            )}
        </Grid>
    );
};

export default SinglePaymentInfo;

import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface InstallNotificationPropsType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowAlert: (value: any) => void;
}

export default function InstallOnSafariNotification({
    setShowAlert,
}: InstallNotificationPropsType) {
    return (
        <Alert
            action={
                <>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setShowAlert(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </>
            }
        >
            You can install the app from safari and it could be a better option!
        </Alert>
    );
}

import { Auth } from 'aws-amplify';
import awsexports from '../aws-exports';

Auth.configure(awsexports);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHeader = async (headers: any) => {
    return {
        ...headers,
        'x-api-key': `${process.env.REACT_APP_WATERHARMONIZER_API_KEY}`,
    };
};

// eslint-disable-next-line
export const apiGet = (endpoint: string, path: string, method: string, body: any) => {
    return {};
};

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SubscriptionInfo from '../BillingDetails/SubscriptionInfo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ConfirmCancelDialog({ open, setOpen, setConfirmed }: any) {
    const handleConfirm = () => {
        setConfirmed(true);
        setOpen(false);
    };

    const handleClose = () => {
        setConfirmed(false);
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-cancel-dialog-title">
                <DialogTitle id="confirm-cancel-dialog-title">
                    Do you want to cancel your subscription?
                </DialogTitle>
                <DialogContent>
                    <SubscriptionInfo />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleConfirm} color="secondary">
                        YES
                    </Button>
                    <Button variant="outlined" autoFocus onClick={handleClose} color="primary">
                        NO
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

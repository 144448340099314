import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface InstallNotificationPropsType {
    handleClick: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowAlert: (value: any) => void;
}

export default function InstallNotification({
    handleClick,
    setShowAlert,
}: InstallNotificationPropsType) {
    return (
        <Alert
            action={
                <>
                    <Button color="inherit" size="small" onClick={handleClick}>
                        Install App
                    </Button>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setShowAlert(false);
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </>
            }
        >
            You can install this app in your device!
        </Alert>
    );
}

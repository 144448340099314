import React from 'react';
import StripeElementsProvider from '../utils/StripeElementsProvider/StripeELementsProvider';
import CheckoutForm from './CheckoutFom';

const SinglePayment = () => {
    return (
        <StripeElementsProvider>
            <CheckoutForm />
        </StripeElementsProvider>
    );
};

export default SinglePayment;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigButton from '../utils/Button/BigButton';
import { GlobalStateType } from '../../redux/rootTypes';
import { cancelSubscriptionAction } from '../../redux/subscription/actions';
import useOnlineDispatch from '../OnlineDispatch';
import ConfirmCancelDialog from './ConfirmCancelDialog';

const CancelSubscription = () => {
    const onlineDispatch = useOnlineDispatch();
    const { subscription } = useSelector((state: GlobalStateType) => state.user);
    const [open, setOpen] = React.useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        if (subscription?.subscriptionID) {
            onlineDispatch(
                cancelSubscriptionAction({ subscriptionID: subscription?.subscriptionID }),
            );
        }
    };

    useEffect(() => {
        if (confirmed) {
            handleCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmed]);

    return (
        <>
            <BigButton title="Cancel Renewal" type="secundary" onClick={handleClickOpen} />
            <ConfirmCancelDialog open={open} setOpen={setOpen} setConfirmed={setConfirmed} />
        </>
    );
};

export default CancelSubscription;

import { Dispatch } from 'redux';
import { SignInData } from './model';
import {
    CLEAR_ERRORS,
    LOADING_USER,
    REMEMBER_CREDENTIALS,
    LOGIN,
    LOGIN_SUCCEEDED,
    LOGIN_FAILED,
    LOGOUT_SUCCEEDED,
    LOGOUT_FAILED,
    LOGOUT,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_RESPONSE,
    CHANGE_PASSWORD_ERROR,
    CLEAR_MESSAGES,
    ErrorType,
    CHANGE_PASSWORD_LOADER,
    UserType,
    GET_USER,
    GET_USER_SUCCEEDED,
    GET_USER_FAILED,
    UPDATE_USER,
    UPDATE_USER_SUCCEED,
    UPDATE_USER_FAILED,
    UPDATE_PAYMENT_FLOW_STATUS,
    UPDATE_PAYMENT_FLOW_STATUS_SUCCESS,
    UPDATE_PAYMENT_FLOW_STATUS_FAILED,
} from './types';

export const updatePaymentFlowAction = (payload: string) => ({
    type: UPDATE_PAYMENT_FLOW_STATUS,
    payload,
});

export const updatePaymentFlowSuccessAction = (payload: UserType) => ({
    type: UPDATE_PAYMENT_FLOW_STATUS_SUCCESS,
    payload,
});

export const updatePaymentFlowFailedAction = (payload: { error: ErrorType }) => ({
    type: UPDATE_PAYMENT_FLOW_STATUS_FAILED,
    payload,
});

export const updateUserAction = (payload: UserType) => ({
    type: UPDATE_USER,
    payload,
});

export const updateUserSuccessAction = (payload: UserType) => ({
    type: UPDATE_USER_SUCCEED,
    payload,
});

export const updateUserFailedAction = (payload: { error: ErrorType }) => ({
    type: UPDATE_USER_FAILED,
    payload,
});

export const userLoadingAction = (payload: boolean) => ({
    type: LOADING_USER,
    payload,
});

export const loginAction = (payload: SignInData) => ({
    type: LOGIN,
    payload,
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS,
});

export const loginSuccessAction = (payload: UserType) => ({
    type: LOGIN_SUCCEEDED,
    payload,
});

export const loginFailedAction = (payload: string) => ({
    type: LOGIN_FAILED,
    payload,
});

export const logoutAction = () => ({
    type: LOGOUT,
});

export const logoutSuccessAction = () => ({
    type: LOGOUT_SUCCEEDED,
});

export const logoutFailedAction = (payload: string) => ({
    type: LOGOUT_FAILED,
    payload,
});

export const rememberCredentialsAction = (remember: boolean) => (dispatch: Dispatch) => {
    if ('PasswordCredential' in window && remember) {
        const credential = new PasswordCredential({
            id: 'email',
            password: 'password',
        });

        navigator.credentials.store(credential).then(() => {
            dispatch({ type: REMEMBER_CREDENTIALS, payload: remember });
        });
    }
};

export const rememberCredentials = (checked: boolean) => (dispatch: Dispatch) => {
    dispatch({ type: REMEMBER_CREDENTIALS, payload: checked });
};

export const changePasswordLoader = () => {
    return {
        type: CHANGE_PASSWORD_LOADER,
    };
};

export const changePasswordResponse = (success: boolean) => {
    return {
        type: CHANGE_PASSWORD_RESPONSE,
        success,
    };
};

export const changePasswordError = (error: ErrorType) => {
    return {
        type: CHANGE_PASSWORD_ERROR,
        error,
    };
};

export const clearMessages = () => {
    return {
        type: CLEAR_MESSAGES,
    };
};
export const changePasswordAction = (oldPassword: string, newPassword: string) => {
    return {
        type: CHANGE_PASSWORD_REQUEST,
        payload: { oldPassword, newPassword },
    };
};

export const getUserAction = (payload: { sub: string }) => ({
    type: GET_USER,
    payload,
});

export const getUserSuccessAction = (payload: { user: UserType }) => ({
    type: GET_USER_SUCCEEDED,
    payload,
});

export const getUserFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_USER_FAILED,
    payload,
});

import {
    SEND_EMAIL_SUCCEEDED,
    SEND_EMAIL_FAILED,
    MailMessageType,
    ErrorType,
    SET_EMAIL_SENDING_STATUS,
} from './types';

const initialState: {
    data: MailMessageType | string;
    error: ErrorType | null;
    sending: boolean;
} = {
    data: '',
    error: null,
    sending: false,
};

export default function reducer(
    state = initialState,
    action: {
        type: string;
        payload: { email?: MailMessageType; error?: ErrorType; response: string };
    },
) {
    // eslint-disable-next-line no-console
    // console.log('reducer email: ', action, state);
    switch (action.type) {
        case SET_EMAIL_SENDING_STATUS:
            return {
                ...state,
                sending: true,
                data: action.payload.email,
            };
        case SEND_EMAIL_SUCCEEDED:
            return {
                ...state,
                sending: false,
                data: action.payload.response,
                error: null,
            };
            return state;
        case SEND_EMAIL_FAILED:
            return {
                ...state,
                sending: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
}

import { call, put, takeLatest, StrictEffect, take } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getMedia, getSignedURL, listMedias } from './apis';
import { GET_MEDIA, MediaType, GET_MEDIA_URL, LIST_MEDIA } from './types';
import {
    getMediaSuccessAction,
    getMediaFailedAction,
    setMediaDownloadingAction,
    getSignedURLSuccessAction,
    getSignedURLFailedAction,
    listMediaSuccessAction,
    listMediaFailedAction,
} from './actions';
import { loaderOff, loaderOn } from '../loader/actions';

// Upload the specified file
export function* downloadFileSaga(action: AnyAction): Generator<StrictEffect, void, MediaType> {
    try {
        const mediaDownloading: MediaType = {
            ...action.payload,
            isDownloading: true,
            progress: 0,
        };
        yield put(setMediaDownloadingAction({ media: mediaDownloading }));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const channel: any = yield call<any>(getMedia, mediaDownloading);
        while (true) {
            const media = yield take(channel);
            // eslint-disable-next-line no-console
            console.log('download: ', media);
            if (media.error) {
                yield put(getMediaFailedAction({ error: media.error }));
            } else if (media.progress) {
                // eslint-disable-next-line no-console
                console.log('download progress: ', media.progress, media.isDownloading);
                yield put(setMediaDownloadingAction({ media: { ...media } }));
                // console.log('save media: ');
                if (!media.isDownloading) yield put(getMediaSuccessAction({ media: { ...media } }));
            }
        }
    } catch (error) {
        yield put(getMediaFailedAction(error));
    }
}

export function* getMediaWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(GET_MEDIA, downloadFileSaga);
}

export function* getSignedURLSaga(action: AnyAction): Generator<StrictEffect, void, MediaType> {
    try {
        yield put(loaderOn());
        const media: MediaType = yield call(getSignedURL, action.payload.media);
        yield put(getSignedURLSuccessAction({ media }));
        yield put(loaderOff());
    } catch (error) {
        yield put(getSignedURLFailedAction(error));
    }
}

export function* getSignedURLWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(GET_MEDIA_URL, getSignedURLSaga);
}

function* fetchMediaListSaga(): Generator<StrictEffect, void, MediaType[]> {
    try {
        yield put(loaderOn());
        const response: MediaType[] = yield call(listMedias);
        yield put(loaderOff());
        yield put(listMediaSuccessAction({ medias: response }));
    } catch (error) {
        yield put(listMediaFailedAction(error));
    }
}

export function* listMediaWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(LIST_MEDIA, fetchMediaListSaga);
}

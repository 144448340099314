import {
    ActionType,
    GET_INVOICES_SUCCEEDED,
    GET_INVOICES_FAILED,
    InvoiceType,
    ErrorType,
    LOADING_INVOICE,
    INVOICE_KEY,
    PAY_INVOICES_FAILED,
    PAY_INVOICES_SUCCEEDED,
} from './types';
import { getItem, setItem } from '../../utils/localStorage';

const storedInvoice = getItem(INVOICE_KEY);
const initialState: { data: InvoiceType[]; error: ErrorType | null; loading: boolean } = {
    data: [],
    ...storedInvoice,
    loading: false,
    error: null,
};

export default function reducer(state = initialState, action: ActionType) {
    let localState;
    switch (action.type) {
        case LOADING_INVOICE:
            localState = {
                ...state,
                loading: action.payload,
            };
            break;
        case GET_INVOICES_SUCCEEDED:
            localState = {
                ...state,
                data: action.payload?.invoices || state.data,
            };
            break;
        case PAY_INVOICES_FAILED:
        case GET_INVOICES_FAILED:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            };
        case PAY_INVOICES_SUCCEEDED:
            // eslint-disable-next-line no-case-declarations
            const filteredInvoices = state.data.filter((i) => i.id !== action.payload?.invoice?.id);
            localState = {
                ...state,
                data: [...filteredInvoices, action.payload?.invoice],
            };
            break;
        default:
            localState = state;
    }
    // console.log(localState);
    setItem(INVOICE_KEY, localState);
    return localState;
}

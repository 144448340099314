import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    TextField,
    useMediaQuery,
    useTheme,
    InputAdornment,
    CircularProgress,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import { changePasswordAction, changePasswordError, clearMessages } from '../../redux/user/actions';
import useOnlineDispatch from '../OnlineDispatch';

const useStyles = makeStyles((theme) => ({
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '49px',
        fontSize: '16px',
        width: '340px',
        '& #password': {
            color: '#000 !important ',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },

    label: {
        color: 'inherit',
        marginTop: theme.spacing(2),
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '17px',
        fontWeight: 400,
    },

}));

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

interface Props {
    open: boolean;
    handleCloseDialog: () => void;
}

export default function ChangePassword({ open, handleCloseDialog }: Props) {
    const classes = useStyles();
    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();

    const { success, loading, error } = useSelector(
        (state: GlobalStateType) => state.user.changePassword || {},
    );

    const handleClose = () => {
        handleCloseDialog();
    };

    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            dispatch(
                changePasswordError({
                    code: 1,
                    message: 'The verification password does not match.',
                    name: '',
                }),
            );

            setTimeout(() => {
                dispatch(clearMessages());
            }, 10000);
        } else {
            onlineDispatch(changePasswordAction(oldPassword, newPassword));
        }
    };

    return (
        <div>
            <Dialog
                fullScreen={isMobile}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Change Password
                </DialogTitle>
                <DialogContent dividers>
                    {error && <Alert severity="error">{error.message}</Alert>}
                    {success && (
                        <Alert severity="success">
                            Your password has been successfully changed
                        </Alert>
                    )}
                    {loading && <CircularProgress />}
                    <form noValidate>
                        <FormGroup className={classes.form}>
                            <TextField
                                InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                id="password"
                                placeholder="Current Password"
                                name="Current Password"
                                autoFocus
                                onChange={(e) => setOldPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                            />

                            <TextField
                                InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setShowPassword1(!showPassword1);
                                                }}
                                                edge="end"
                                            >
                                                {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                name="password"
                                placeholder="New Password"
                                type={showPassword1 ? 'text' : 'password'}
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <TextField
                                InputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                id="password"
                                name="Confirm-password"
                                placeholder="Confirm Password"
                                type={showPassword1 ? 'text' : 'password'}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.submit}
                            >
                                Change Password
                            </Button>
                        </FormGroup>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

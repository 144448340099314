import React from 'react';
import MainLayout from '../../layout/MainLayout';
import HarmonizedComplete from '../../components/InfoPanel/HarmonizedComplete';

const HarmonizerComplete = () => {
    return (
        <MainLayout>
            <HarmonizedComplete />
        </MainLayout>
    );
};

export default HarmonizerComplete;

import { API } from 'aws-amplify';
import awsexports from '../../aws-exports';
import { MailMessageType } from './types';
import { getHeader } from '../../utils/api';

API.configure(awsexports);

export default async function sendEmail(message: MailMessageType) {
    const apiName = 'SendMail';
    const path = '/';
    const init = {
        headers: await getHeader({}),
        body: {
            ...message,
        },
    };

    return API.post(apiName, path, init).then((res) => {
        // eslint-disable-next-line no-console
        // console.log('Response', res);
        window.location.href = '/mail-sent';
        return res;
    });
}

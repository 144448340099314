import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { setSelectedPriceAction } from '../../redux/prices/actions';
import { PriceType } from '../../redux/prices/types';

import { GlobalStateType } from '../../redux/rootTypes';
import { ColorCircularProgress } from '../Loader';
import useOnlineDispatch from '../OnlineDispatch';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textContainer: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
            '& p': {
                padding: '6px',
            },
            '& #title': {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            '& #small': {
                textTransform: 'uppercase',
                fontSize: '10px',
            },
        },
        btnContainer: {
            width: '300px',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                textAlign: 'center',
                padding: '25px',
            },
        },
        priceContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '25px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
        },
        price: {
            width: '300px',
            [theme.breakpoints.down(768)]: {
                width: 'auto',
            },
            border: '2px solid #0468BF',
            textAlign: 'center',
            margin: '5px',
            cursor: 'pointer',
            transition: 'all 120ms',
            outline: 'none',
            '&:hover': {
                border: '2px solid #1cc00a',
                boxShadow: '0px 0px 5px #4a4a4a',
            },
            '&:focus': {
                border: '2px solid #1cc00a !important                   ',
                boxShadow: '0px 0px 5px #4a4a4a',
            },
            [theme.breakpoints.down('sm')]: {
                minwidth: '220',
                margin: '25px',
            },
            '& p': {
                fontSize: '28px',
                margin: '16px auto',
                fontWeight: 'bolder',
            },
            '& span:nth-child(1)': {
                color: '#1cc00a',
            },
        },
        selectedPrice: {
            border: '2px solid #1cc00a',
            boxShadow: '0px 0px 5px #4a4a4a',
        },
        mobileTitle: {
            textAlign: 'center',
            color: '#0468BF',
            display: 'none',
            [theme.breakpoints.down('sm')]: {
                display: 'inline',
                visibility: 'visible',
            },
        },
    }),
);

export default function PriceCards() {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const {
        loading: priceLoading,
        error,
        data: prices,
        selectedPrice,
    } = useSelector((state: GlobalStateType) => state.prices);
    const { loading: paymentLoading } = useSelector((state: GlobalStateType) => state.payments);

    const handleSelect = (evt: string, price: PriceType) => {
        if (evt === 'Enter' || evt === 'click') {
            onlineDispatch(setSelectedPriceAction({ price }));
        }
    };

    const pricesCard = () => {
        return prices?.map((p, index) =>
            p.active ? (
                <div
                    className={`${classes.price} ${p.id === selectedPrice?.id ? classes.selectedPrice : null
                        }`}
                    key={p.id}
                    onClick={() => handleSelect('click', p)}
                    onKeyDown={(evt) => handleSelect(evt.key, p)}
                    role="button"
                    tabIndex={index}
                >
                    <p>
                        <span>${((p.unit_amount || 100) / 100).toFixed(2)}</span> /{' '}
                        {p.nickname?.replace('_', ' ')}
                    </p>
                </div>
            ) : null,
        );
    };

    return (
        <>
            <p>Select a price Plan:</p>
            {priceLoading || paymentLoading ? (
                <ColorCircularProgress />
            ) : (
                <div className={classes.priceContainer}>{pricesCard()}</div>
            )}
            {error && <p style={{ color: 'red' }}>{error.message}</p>}
        </>
    );
}

PriceCards.defaultProps = {
    gtPrice: 0,
};

import { getItem } from '../../utils/localStorage';
import {
    ActionType,
    Data,
    ErrorType,
    LIST_PAYMENT_METHOD_SUCCEEDED,
    LIST_PAYMENT_METHOD_FAILED,
    SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED,
    SET_DEFAULT_PAYMENT_METHOD_FAILED,
    LOADING_PAYMENTS,
    GET_DEFAULT_PAYMENT_METHOD_FAILED,
    GET_DEFAULT_PAYMENT_METHOD_SUCCEEDED,
    LIST_PAYMENT_INTENT_SUCCEEDED,
    LIST_PAYMENT_INTENT_FAILED,
    SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED,
    SET_CUSTOMER_TO_PAYMENT_INTENT_FAILED,
} from './types';

const defaultPM = getItem('defaultPM');

const initialState: { data: Data; error: ErrorType; loading: boolean } = {
    data: {
        defaultPaymentMethod: defaultPM || {
            id: '',
            customer: '',
            object: 'payment_method',
            billing_details: {
                address: null,
                email: '',
                name: '',
                phone: '',
            },
            created: 0,
            livemode: false,
            metadata: {},
            type: 'card',
        },
        paymentMethodsList: [],
        paymentIntents: [],
    },
    error: {},
    loading: false,
};

export default function reducer(state = initialState, action: ActionType) {
    // if (action.type.indexOf('PAYMENT') > -1) console.log('payment reducer: ', action, state);
    let filtered;
    switch (action.type) {
        case SET_DEFAULT_PAYMENT_METHOD_SUCCEEDED:
            filtered = state.data.paymentMethodsList.filter(
                (pm) => pm.id !== action.payload?.paymentMethod?.id,
            );
            return {
                ...state,
                data: {
                    ...state.data,
                    defaultPaymentMethod:
                        action.payload.paymentMethod || state.data.defaultPaymentMethod,
                    paymentMethodsList: action?.payload?.paymentMethod?.id
                        ? [...filtered, action?.payload?.paymentMethod]
                        : [...filtered],
                },
            };
        case GET_DEFAULT_PAYMENT_METHOD_SUCCEEDED:
            if (!action.payload?.paymentMethod)
                return {
                    ...state,
                };
            return {
                ...state,
                data: {
                    ...state.data,
                    defaultPaymentMethod: action.payload.paymentMethod,
                    paymentMethodsList: filtered,
                },
            };
        case LIST_PAYMENT_METHOD_SUCCEEDED:
            return {
                ...state,
                data: {
                    ...state.data,
                    defaultPaymentMethod: state?.data?.defaultPaymentMethod,
                    paymentMethodsList: action?.payload?.paymentMethods || [],
                },
                error: {},
            };
        case SET_CUSTOMER_TO_PAYMENT_INTENT_SUCCEEDED:
            filtered = state.data.paymentIntents.filter(
                (p) => p.id !== action.payload?.paymentIntent?.id,
            );
            return {
                ...state,
                data: {
                    ...state.data,
                    paymentIntents: [...filtered, action.payload?.paymentIntent],
                },
                loading: false,
                error: {},
            };
        case LIST_PAYMENT_INTENT_SUCCEEDED:
            return {
                ...state,
                data: {
                    ...state.data,
                    paymentIntents: action?.payload?.paymentIntents,
                },
                loading: false,
                error: {},
            };
        case SET_CUSTOMER_TO_PAYMENT_INTENT_FAILED:
        case SET_DEFAULT_PAYMENT_METHOD_FAILED:
        case GET_DEFAULT_PAYMENT_METHOD_FAILED:
        case LIST_PAYMENT_METHOD_FAILED:
        case LIST_PAYMENT_INTENT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case LOADING_PAYMENTS:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}

import { Button, Grid, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import Background from '../Video/background';
import { useMediaBackground, useMediaFractal } from '../Video/MediaHook';
import ValidateSubscriptonComponent from '../BillingStatus/validateBillingComponent';
import { clearError } from '../../redux/notifications/actions';
import { ColorCircularProgress } from '../Loader';
import { toggleHarmonizing } from '../../redux/app/actions';
import Fractal from '../Video/fractal';

const useStyles = makeStyles((theme) => ({
    rootStyles: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100vh',
            height: 'auto',
        },
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        opacity: 1,

        mixBlendMode: 'normal',
    },
    stop: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        color: 'white',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '150px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            '& .MuiButton-label': {
                color: '#00BBFF',
            },
        },
    },

    footer: {
        /*  backgroundColor: '#0368BF', */

        bottom: 0,
        backgroundColor: 'transparent !important',
        width: '100vw',
        height: 'auto',
        padding: 40,
        zIndex: 0,
    },
    footerText: {
        color: 'white',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: 30,
    },
}));

const StartHarmonizer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const mediaFileVideo = useMediaFractal();
    const mediaFileBackground = useMediaBackground();
    const [playing, setPlaying] = useState(false);
    const [mediaReady, setMediaReady] = useState({ fractal: false, background: false });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleError = (e: any) => {
        // eslint-disable-next-line no-console
        console.warn('Media File: ', e);
        // dispatch( newError({ severity: 'error', message: 'Sorry, something went wrong playing media' }),);
    };

    useEffect(() => {
        dispatch(toggleHarmonizing(false));
        return () => {
            dispatch(toggleHarmonizing(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButton = () => {
        setPlaying(!playing);
        dispatch(toggleHarmonizing(!playing));
    };

    const handleMediaReady = (mediaName: string) => {
        setMediaReady({ ...mediaReady, [mediaName]: true });
        dispatch(clearError());
    };

    const handleMediaPause = () => {
        if (playing) {
            setPlaying(false);
            dispatch(toggleHarmonizing(false));
        }
    };

    const handleMediaPlay = () => {
        if (!playing) {
            setPlaying(true);
            dispatch(toggleHarmonizing(true));
        }
    };

    const allMediaReady = () => {
        return mediaReady.fractal && mediaReady.background;
    };

    return (
        <>
            <Container maxWidth="lg">
                <ValidateSubscriptonComponent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignContent="center"
                        alignItems="center"
                    // style={ValidateSubscriptonStyle()}
                    >
                        {mediaFileVideo && mediaFileBackground ? (
                            <>
                                <Fractal
                                    autoPlay={false}
                                    handleError={handleError}
                                    mediaFile={mediaFileVideo}
                                    playing={playing}
                                    handleMediaReady={handleMediaReady}
                                    handleMediaPause={handleMediaPause}
                                    handleMediaPlay={handleMediaPlay}
                                />

                                <Button
                                    style={{ zIndex: 999 }}
                                    className={classes.stop}
                                    onClick={handleButton}
                                    disabled={!allMediaReady()}
                                >
                                    {playing ? 'Stop' : (allMediaReady() && 'Play') || 'Loading...'}
                                </Button>
                                <div style={{ display: 'none' }}>
                                    <Background
                                        handleError={handleError}
                                        mediaFile={mediaFileBackground}
                                        playing={playing}
                                        handleMediaReady={handleMediaReady}
                                        handleMediaPause={handleMediaPause}
                                        handleMediaPlay={handleMediaPlay}
                                    />
                                </div>
                            </>
                        ) : (
                            <ColorCircularProgress />
                        )}
                    </Grid>
                </ValidateSubscriptonComponent>
            </Container>
            <Grid
                container
                justify="center"
                alignItems="center"
                alignContent="center"
                className={classes.footer}
            />
        </>
    );
};

export default StartHarmonizer;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import { BILLING_FLOW_STATUS } from '../../redux/app/types';

export default () => {
    const {
        user: { code, subscription, payment },
    } = useSelector((state: GlobalStateType) => state);

    const validateBilling = () => {
        if (code) return { FLOW: 'CODE', STATUS: BILLING_FLOW_STATUS.CODE[code.status] };

        if (subscription && subscription?.subscriptionStatus)
            return {
                FLOW: 'SUBSCRIPTION',
                STATUS:
                    BILLING_FLOW_STATUS.SUBSCRIPTION[subscription?.subscriptionStatus] ||
                    BILLING_FLOW_STATUS.SUBSCRIPTION.unpaid,
            };

        if (payment?.paymentIntents && payment.paymentIntents.length > 0) {
            const onePayment = payment.paymentIntents.filter((p) => p.status === 'succeeded');
            const status = onePayment.length > 0 ? 'paid' : 'unpaid';
            return { FLOW: 'PAYMENT', STATUS: BILLING_FLOW_STATUS.PAYMENT[status] };
        }

        return { FLOW: 'DEFAULT', STATUS: null };
    };

    const [paymentStatus, setPaymentStatus] = useState(validateBilling());

    useEffect(() => {
        setPaymentStatus(validateBilling());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, subscription, payment]);

    return paymentStatus;
};

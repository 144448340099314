import './i18n/config';
import './index.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ClearCacheProvider } from 'react-clear-cache';
import ReactPWAInstallProvider from 'react-pwa-install';

import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import RenderRoutes from './routes/RenderRoutes';

ReactDOM.render(
    <ClearCacheProvider duration={60000}>
        <Provider store={store}>
            <Suspense fallback={null}>
                <ReactPWAInstallProvider enableLogging>
                    <RenderRoutes />
                </ReactPWAInstallProvider>
            </Suspense>
        </Provider>
    </ClearCacheProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

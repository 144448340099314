import { call, put, takeLatest, StrictEffect } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import {
    loginService,
    logoutService,
    changePassword,
    updateUser,
    getUser,
    updatePaymentFlowStatus,
} from './apis';
import {
    LOGIN,
    LOGOUT,
    CHANGE_PASSWORD_REQUEST,
    UPDATE_USER,
    UserType,
    GET_USER,
    UPDATE_PAYMENT_FLOW_STATUS,
    USER_KEY,
} from './types';
import {
    userLoadingAction,
    loginSuccessAction,
    loginFailedAction,
    logoutSuccessAction,
    logoutFailedAction,
    changePasswordResponse,
    changePasswordError,
    changePasswordLoader,
    updateUserFailedAction,
    updateUserSuccessAction,
    getUserSuccessAction,
    getUserFailedAction,
    updatePaymentFlowSuccessAction,
    updatePaymentFlowFailedAction,
} from './actions';
import { getItem } from '../../utils/localStorage';
import { getSubscriptionAction } from '../subscription/actions';

function* runLogin(action: AnyAction): Generator<StrictEffect, void, UserType> {
    try {
        yield put(userLoadingAction(true));
        let response: UserType = getItem(USER_KEY);
        // console.log('saga login indexeddb: ', response);
        if (!response?.sub) {
            response = yield call(loginService, action.payload);
            // console.log('saga login response: ', response);
        }
        if (response.sub) yield put(loginSuccessAction(response));
    } catch (error) {
        yield put(loginFailedAction(error));
    }
}

export function* loginWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(LOGIN, runLogin);
}

function* runLogout(): Generator<StrictEffect, void, void> {
    try {
        yield put(userLoadingAction(true));
        yield call(logoutService);
        yield put(logoutSuccessAction());
    } catch (error) {
        yield put(logoutFailedAction(error));
    }
}

export function* logoutWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(LOGOUT, runLogout);
}

function* runChangePassword(action: AnyAction): Generator<StrictEffect, void, boolean> {
    try {
        yield put(changePasswordLoader());
        const response: boolean = yield call(changePassword, action.payload);
        yield put(changePasswordResponse(response));
    } catch (error) {
        yield put(changePasswordError(error));
    }
}

export function* changePasswordWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(CHANGE_PASSWORD_REQUEST, runChangePassword);
}

function* updatePaymentFlowStatusSaga(action: AnyAction): Generator<StrictEffect, void, UserType> {
    try {
        yield put(userLoadingAction(true));
        const response: UserType = yield call(updatePaymentFlowStatus, action.payload);
        yield put(updatePaymentFlowSuccessAction(response));
    } catch (error) {
        yield put(updatePaymentFlowFailedAction(error));
    }
}

export function* updatePaymentFlowStatusWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(UPDATE_PAYMENT_FLOW_STATUS, updatePaymentFlowStatusSaga);
}

function* updateUserSaga(action: AnyAction): Generator<StrictEffect, void, UserType> {
    try {
        yield put(userLoadingAction(true));
        const response: UserType = yield call(updateUser, action.payload);
        yield put(updateUserSuccessAction(response));
    } catch (error) {
        yield put(updateUserFailedAction(error));
    }
}

export function* updateUserWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(UPDATE_USER, updateUserSaga);
}

function* fetchUserSaga(action: AnyAction): Generator<StrictEffect, void, UserType> {
    try {
        yield put(userLoadingAction(true));
        const response: UserType = yield call(getUser, action.payload.sub);
        yield put(getUserSuccessAction({ user: response }));
        if (response?.stripeCustomerID || response.subscription?.subscriptionID)
            yield put(
                getSubscriptionAction({
                    customerID: response?.stripeCustomerID,
                    subscriptionID: response.subscription?.subscriptionID,
                }),
            );
    } catch (error) {
        yield put(getUserFailedAction(error));
    }
}

export function* fetchUserWatcher(): Generator<StrictEffect, void, void> {
    yield takeLatest(GET_USER, fetchUserSaga);
}

import { combineReducers } from 'redux';
import panels from '../panels/reducer';
import subscription from '../subscription/reducer';
import product from '../product/reducer';
import prices from '../prices/reducer';
import payments from '../payments/reducer';
import medias from '../medias/reducer';
import HeaderReducer from '../app/reducer';
import user from '../user/reducer';
import loader from '../loader/reducer';
import network from '../network/reducer';
import emails from '../emails/reducer';
import invoices from '../invoices/reducer';
import notifications from '../notifications/reducer';

export default combineReducers({
    header: HeaderReducer,
    user,
    panels,
    subscription,
    medias,
    loader,
    product,
    prices,
    payments,
    network,
    emails,
    invoices,
    notifications,
});

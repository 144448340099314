import { call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { getPrice, listPrices } from './apis';
import { GET_PRICE, LIST_PRICE, PriceType } from './types';
import {
    listPriceFailedAction,
    listPriceSuccessAction,
    getPriceSuccessAction,
    getPriceFailedAction,
    pricesLoadingAction,
} from './actions';

function* fetchPrice(action: AnyAction) {
    try {
        yield put(pricesLoadingAction(true));
        const response: { price: PriceType } = yield call(getPrice, action.payload);
        yield put(pricesLoadingAction(false));
        yield put(getPriceSuccessAction(response));
    } catch (error) {
        yield put(getPriceFailedAction(error));
    }
}

export function* getPriceWatcher() {
    yield takeLatest(GET_PRICE, fetchPrice);
}

function* fetchPriceListSaga(action: AnyAction) {
    try {
        yield put(pricesLoadingAction(true));
        const response: { prices: PriceType[] } = yield call(listPrices, action.payload);
        yield put(pricesLoadingAction(false));
        yield put(listPriceSuccessAction(response));
    } catch (error) {
        yield put(listPriceFailedAction(error));
    }
}

export function* listPriceWatcher() {
    yield takeLatest(LIST_PRICE, fetchPriceListSaga);
}

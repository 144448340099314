import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'idb-keyval';
import { GlobalStateType } from '../../redux/rootTypes';
import { getMediaAction, listMediaAction } from '../../redux/medias/actions';
import { BACKGROUND_NAME, MediaType, MEDIA_INDEXED_KEY } from '../../redux/medias/types';
import useOnlineDispatch from '../OnlineDispatch';

const Downloader = () => {
    const onlineDispatch = useOnlineDispatch();
    const user = useSelector((state: GlobalStateType) => state.user.sub);
    const { data: medias, currentMediaDownloading } = useSelector((state: GlobalStateType) => ({
        ...state.medias,
    }));

    const [localMedias, setLocalMedias] = useState<MediaType[]>([]);

    useEffect(() => {
        if (user) onlineDispatch(listMediaAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (localMedias.length === 0 || !currentMediaDownloading) {
            (async () => {
                const indexedMedia = (await get(MEDIA_INDEXED_KEY)) || [];
                let tempLocalMedias = medias.filter(
                    (m) =>
                        m.key &&
                        !indexedMedia.some(
                            (indexedM: MediaType) => indexedM.key === m.key && indexedM.blob,
                        ),
                );
                const background = tempLocalMedias.find(
                    (a) => `${a.key}`.indexOf(BACKGROUND_NAME) > -1,
                );
                if (background) {
                    tempLocalMedias = tempLocalMedias.filter(
                        (a) => `${a.key}`.indexOf(BACKGROUND_NAME) === -1,
                    );
                    tempLocalMedias.unshift(background);
                }
                if (tempLocalMedias.length > 0) setLocalMedias([...tempLocalMedias]);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medias]);

    useEffect(() => {
        const nextToDownload = localMedias[0];
        if (!currentMediaDownloading && nextToDownload?.key && user) {
            onlineDispatch(
                getMediaAction({
                    ...localMedias[0],
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localMedias, user]);

    return <></>;
};

export default Downloader;

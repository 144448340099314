import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import {
    Button,
    Grid,
    makeStyles,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme,
    TextareaAutosize,
    CircularProgress
} from '@material-ui/core';
import { useHistory } from 'react-router';
import axios from 'axios';
import { getItem, setItem } from '../../utils/localStorage';
import { USER_KEY } from '../../redux/user/types';
import userIcon from '../../assets/userIcon.svg'
import MainLayout from '../../layout/MainLayout';
import { GlobalStateType } from '../../redux/rootTypes';
import awsconfig from '../../aws-exports';
import { ColorCircularProgress } from '../../components/Loader';

Storage.configure(awsconfig);
const useStyle = makeStyles<Theme>((theme) => ({
    rootLinear: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',

        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    wrap: {
        margin: '0px auto',
        width: '50%',
        '@media (max-width: 575px)': {
            width: '100%',
        },
    },
    nameField: {
        backgroundColor: '#0d3c61 !important',
        color: '#fff !important',
    },
    button: {
        backgroundColor: '#0468BF',
        minWidth: '302px',
        margin: '20px auto',
        display: 'block',
        color: '#fff',
        height: 'auto',
        borderRadius: '10px',
        padding: '10px 20px 10px 20px !important',
        border: '1px solid #747474',
        '& .MuiButton-label': {
            fontSize: '20px',
            fontWeight: 'bolder',
            textTransform: 'capitalize',
        },
        '&.customBtn': {
            width: '100%',
        },
        '&.customStartBtn': {
            background: '#f10',
        },
        '&:hover': {
            backgroundColor: '#0468BF',
            opacity: '.9',
        },
    },
    scanImg: {
        width: '100%',
        height: '98%',
    },
}));

const UserRecord = () => {
    const classes = useStyle();
    const [userData, setUserData] = useState([]);
    const [showloader, setShowLoader] = useState(false);
    const user = useSelector((state: GlobalStateType) => state.user);
    useEffect(() => {
        setShowLoader(true);
        const localUser = getItem(USER_KEY);
        const fetchData = async () => {
            const avatars = await Storage.list(`aura/${user.sub}`);
            let urls = [];
            if (avatars) {
                urls = await Promise.all(
                    avatars.map(async (item) => {
                        const imageUrl = await Storage.get(item.key);
                        return { key: item.key, value: imageUrl };
                    })
                );
            }
            // console.log('======Images:', urls)
            const url = "https://hvz6syv857.execute-api.us-east-1.amazonaws.com/testing/listSavedUsers";
            const requestData = {
                user_id: localUser.sub
            };
            try {
                const response = await axios.post(url, requestData);
                const kkss = response.data.body.data;
                // console.log('==========Users:', kkss)
                const processed = kkss.map((item, index) => {
                    const matchingUrl = urls.find((img) => img.key === item.client_image);
                    const clientAvt = matchingUrl ? matchingUrl.value : userIcon;
                    return {
                        "birth_date": item.birth_date,
                        "user_id": item.user_id,
                        "client_avt": clientAvt,
                        // "client_avt": urls.map((img) => {
                        //     return (img.key === item.client_image) ? img.value : userIcon
                        // }),
                        "fname": item.fname,
                        "user_email": item.user_email,
                        "user_text": item.user_text,
                        "lname": item.lname,
                        "user_phone": item.user_phone,
                        "id": item.id
                    }
                });
                setUserData(processed);
            } catch (error) {
                console.error('Error calling API:', error);
            }
            setShowLoader(false);
        };
        fetchData();
    }, [user]);
    const historty = useHistory();
    const handleBack = () => {
        historty.push('/user-details');
    }
    const handleClick = (ks) => {
        historty.push(`/user-details#${ks}`);
    }
    return (
        <div>
            <MainLayout>
                <div className={classes.bg}>
                    <div className="userRecords-wrapper">
                        <div className="userRecords-header">
                            <Button onClick={handleBack}>
                                Back
                            </Button>
                            <Button onClick={handleBack}>
                                New
                            </Button>
                        </div>
                        {/* <Button onClick={handleDelete}>
                            Edit
                        </Button> */}
                        <div className='userRecords-body'>
                            {(!showloader
                                ? ''
                                : <ColorCircularProgress />
                            )}
                            {Array.isArray(userData) && userData.length > 0 ? (
                                userData.map((userAura, index) => (
                                    <div className="userRecords-bodyList"
                                        onClick={(e) => handleClick(userAura.id)}
                                        onKeyDown={(e) => handleClick(userAura.id)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <img src={userAura.client_avt} alt="" key={userAura.id} />
                                        <p>{userAura.fname} {userAura.lname}</p>
                                    </div>
                                ))
                            ) : (
                                <div className="userRecords-bodyList">
                                    <p>No Records found</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </MainLayout>
        </div>
    );
};

export default UserRecord;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { GuardProvider, GuardedRoute, GuardFunction } from 'react-router-guards';

import NotFound from '../pages/404';
import About from '../pages/About';
import AddCreditCard from '../pages/AddCreditCard';
import BillingDetails from '../pages/BillingDetails';
import BillingHistory from '../pages/BillingHistory';
import ConfirmSignUp from '../pages/ConfirmSignUp';
import HarmonizedCompleted from '../pages/HarmonizedCompleted';
import HarmonizingScreen from '../pages/HarmonizingScreen';
import Help from '../pages/Help';
import Home from '../pages/Home';
import LogIn from '../pages/Login';
import Messages from '../pages/Messages';
import ModifyBillingInfo from '../pages/ModifyBillingInfo';
import PanelInfo from '../pages/InfoPanel';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Signed from '../pages/Signed';
import SignUp from '../pages/SignUp';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import SolfeggioFrequencies from '../pages/Solfeggio Frequencies';
import Support from '../pages/Support';
import UpgradePlan from '../pages/UpgradePlan';
import SendMail from '../pages/SendMail';
import SendMailSuccess from '../pages/SendMailSuccess';
import Loading from './Loading';
// import CreatePaymentMethod from '../components/ModifyBillingInfo/createPaymentMethod/CreatePaymentMethod';
import NetworkStatus from '../components/NetworkStatus';
import LoginTrigger from '../components/Auth/LoginTrigger';
import { getItem } from '../utils/localStorage';
import TermsOfUse from '../pages/TermsOfUse';
import PWA from '../components/PWA';
import ClearCache from '../components/Cache';
import ResendConfirmationCode from '../pages/ResendConfirmationCode';
import { USER_KEY } from '../redux/user/types';
import Disclaimer from '../pages/Disclaimer';
import HowToDo from '../pages/HowToDo';
import Aura from '../pages/Aura';
import AuraScan from '../pages/Aura/AuraScan';
import AuraScreen from '../pages/Aura/AuraScreen';
import ChakraScan from '../pages/Chakra/ChakraScan';
import ChakraScreen from '../pages/Chakra/ChakraScreen';
import UserRecord from '../pages/Aura/UserRecord';

export async function isAuthenticated() {
    try {
        const user = getItem(USER_KEY);
        // console.log('router guard: ', user);
        return !!user?.sub;
    } catch (error) {
        return error;
    }
}

const requireLogin: GuardFunction = async (to, from, next) => {
    // console.log('requireLogin', JSON.stringify(to));
    if (to.meta.auth) {
        if (await isAuthenticated()) {
            next();
        } else next.redirect('/login');
    } else next();
};

// pages imports
const RenderRoutes = () => {
    return (
        <>
            <PWA />
            <NetworkStatus />
            <ClearCache />
            <BrowserRouter>
                <GuardProvider guards={[requireLogin]} loading={Loading} error={NotFound}>
                    <Switch>
                        <Route exact path="/login" render={() => <LogIn />} />
                        <Route exact path="/signup/" render={() => <SignUp />} />
                        <Route exact path="/signup/:code" render={() => <SignUp />} />
                        <Route exact path="/signup-confirm" render={() => <ConfirmSignUp />} />
                        <Route exact path="/signed" render={() => <Signed />} />
                        <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
                        <Route exact path="/reset-password" render={() => <ResetPassword />} />
                        <Route
                            exact
                            path="/resend-confirmation-code"
                            render={() => <ResendConfirmationCode />}
                        />
                        <GuardedRoute
                            path="/"
                            exact
                            render={() => <Redirect to="/user-details" />}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute exact path="/home" component={Home} meta={{ auth: true }} />
                        <GuardedRoute
                            path="/my-account"
                            component={BillingDetails}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/upgrade-plan"
                            component={UpgradePlan}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/modify-billing-info"
                            component={ModifyBillingInfo}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/billing-history"
                            component={BillingHistory}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/add-credit-card"
                            component={AddCreditCard}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/panel-info/:id"
                            component={PanelInfo}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/harmonizing-screen/:id"
                            component={HarmonizingScreen}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/harmonized-complete"
                            component={HarmonizedCompleted}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/about"
                            render={() => <About />}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/solfeggio-frequencies"
                            component={SolfeggioFrequencies}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/disclaimer"
                            component={Disclaimer}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/how-to"
                            component={HowToDo}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/messages"
                            component={Messages}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/support"
                            component={Support}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/help"
                            render={() => <Help />}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/terms-of-use"
                            component={TermsOfUse}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/send-mail"
                            component={SendMail}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/mail-sent"
                            component={SendMailSuccess}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact path="/user-details"
                            component={Aura}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/aura-scan"
                            component={AuraScan}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact path="/aura-screen"
                            component={AuraScreen}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact path="/aura-user-record"
                            component={UserRecord}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact path="/chakra-scan"
                            component={ChakraScan}
                            meta={{ auth: true }} />
                        <GuardedRoute
                            exact path="/chakra-screen"
                            component={ChakraScreen}
                            meta={{ auth: true }} />
                        <Route path="*" render={() => <NotFound />} />
                    </Switch>
                </GuardProvider>
            </BrowserRouter>

            <LoginTrigger />
        </>
    );
};

export default RenderRoutes;

import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    avatar: {
        margin: theme.spacing(1),
        width: '210px',
        height: '289px',
    },
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '49px',
        fontSize: '16px',
        width: '340px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },
    header: {
        color: 'inherit',
        fontFamily: ['Poppins', 'regular'].join(','),
        textTransform: 'uppercase',
        fontSize: '36px',
        textAlign: 'center',
    },
}));

export default function ResendConfirmationCode() {
    const classes = useStyles();
    const history = useHistory();

    const [email, setEmail] = React.useState<string>('');
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        Auth.resendSignUp(email)
            .then(() => {
                history.push('/signup-confirm', { username: email });
            })
            .catch((err) => {
                setOpen(true);
                setError(`${err.message}`);
            });
    };
    function Alert(props: AlertProps) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Button
                onClick={() => {
                    history.push('/signup');
                }}
                endIcon={<ArrowBackIcon style={{ fontSize: 48, color: '#005FB3' }} />}
            />
            <Container maxWidth="xs">
                <Snackbar
                    open={open}
                    key="topcenter"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar variant="square" className={classes.avatar} src="/logo.png" />
                    <Typography className={classes.header}>Send New Confirmation Code</Typography>
                    <form noValidate>
                        <FormGroup className={classes.form}>
                            <TextField
                                InputProps={{
                                    className: classes.input,
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value?.trim())}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.submit}
                            >
                                Send Code
                            </Button>
                        </FormGroup>
                    </form>
                </div>
            </Container>
        </>
    );
}

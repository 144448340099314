import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainLayout from '../../layout/MainLayout';

const useStyles = makeStyles(() => ({
    bg: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        color: 'white',
        cursor: 'pointer',
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 24,
        textAlign: 'center',
    },
    link: {
        color: 'black',
        textDecorationLine: 'underline',
        cursor: 'pointer',
        marginTop: 100,
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: 16,
    },
    margin: {
        margin: 'px 0px 40px 0px',
    },
    text: {
        color: '#383838',
        fontSize: '16px',
        fontFamily: ['Poppins', 'regular'].join(','),
    },
}));

export default () => {
    const classes = useStyles();
    const { t } = useTranslation(['termOfUse']);
    return (
        <MainLayout>
            <div className={classes.bg}>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    direction="column"
                    style={{ margin: 50 }}
                >
                    <Grid item lg={5} md={5} sm={12}>
                        <Grid item className={classes.margin}>
                            <Typography className={classes.title} variant="h4" color="initial">
                                {t('termOfUse:title')}
                            </Typography>
                        </Grid>
                        <br />
                        <Grid item>
                            <Typography className={classes.text} variant="h6">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </MainLayout>
    );
};

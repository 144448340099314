import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useClearCacheCtx } from 'react-clear-cache';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default () => {
    const classes = useStyles();
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    const [showAlert, setShowAlert] = useState(isLatestVersion);

    useEffect(() => {
        setShowAlert(!isLatestVersion);
    }, [isLatestVersion]);

    return showAlert ? (
        <div className={classes.root}>
            <Alert
                action={
                    <>
                        <Button color="inherit" size="small" onClick={() => emptyCacheStorage()}>
                            Update App
                        </Button>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setShowAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </>
                }
            >
                There is a new app version!
            </Alert>
        </div>
    ) : null;
};

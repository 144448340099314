import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from '../../layout/MainLayout';
import StartHarmonizer from '../../components/InfoPanel/StartHarmonizer';

const useStyles = makeStyles((theme) => ({
    rootStyles: {
        background: `url('../assets/bg.png')`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            minHeight: '100vh',
            height: 'auto',
        },
        width: '100vw',
        marginRight: '-20px !important',
        marginLeft: '-20px !important',
        opacity: 1,

        mixBlendMode: 'normal',
    },
    stop: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        color: 'white',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '150px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            '& .MuiButton-label': {
                color: '#00BBFF',
            },
        },
    },

    footer: {
        /*  backgroundColor: '#0368BF', */

        bottom: 0,
        backgroundColor: 'transparent !important',
        width: '100vw',
        height: 'auto',
        padding: 40,
        zIndex: 0,
    },
    footerText: {
        color: 'white',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: 30,
    },
}));

const HarmonizingScreen = () => {
    const classes = useStyles();

    return (
        <MainLayout>
            <div className={classes.rootStyles}>
                <StartHarmonizer />
            </div>
        </MainLayout>
    );
};

export default HarmonizingScreen;

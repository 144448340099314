export const COUNTRIES = [
    {
        ID: '1',
        ShortForm: 'AF',
        CountryTitle: 'Afghanistan',
    },
    {
        ID: '2',
        ShortForm: 'AX',
        CountryTitle: 'Aland Islands',
    },
    {
        ID: '3',
        ShortForm: 'AL',
        CountryTitle: 'Albania',
    },
    {
        ID: '4',
        ShortForm: 'DZ',
        CountryTitle: 'Algeria',
    },
    {
        ID: '5',
        ShortForm: 'AS',
        CountryTitle: 'American Samoa',
    },
    {
        ID: '6',
        ShortForm: 'AD',
        CountryTitle: 'Andorra',
    },
    {
        ID: '7',
        ShortForm: 'AO',
        CountryTitle: 'Angola',
    },
    {
        ID: '8',
        ShortForm: 'AI',
        CountryTitle: 'Anguilla',
    },
    {
        ID: '9',
        ShortForm: 'AQ',
        CountryTitle: 'Antarctica',
    },
    {
        ID: '10',
        ShortForm: 'AG',
        CountryTitle: 'Antigua and Barbuda',
    },
    {
        ID: '11',
        ShortForm: 'AR',
        CountryTitle: 'Argentina',
    },
    {
        ID: '12',
        ShortForm: 'AM',
        CountryTitle: 'Armenia',
    },
    {
        ID: '13',
        ShortForm: 'AW',
        CountryTitle: 'Aruba',
    },
    {
        ID: '14',
        ShortForm: 'AU',
        CountryTitle: 'Australia',
    },
    {
        ID: '15',
        ShortForm: 'AT',
        CountryTitle: 'Austria',
    },
    {
        ID: '16',
        ShortForm: 'AZ',
        CountryTitle: 'Azerbaijan',
    },
    {
        ID: '17',
        ShortForm: 'BS',
        CountryTitle: 'Bahamas',
    },
    {
        ID: '18',
        ShortForm: 'BH',
        CountryTitle: 'Bahrain',
    },
    {
        ID: '19',
        ShortForm: 'BD',
        CountryTitle: 'Bangladesh',
    },
    {
        ID: '20',
        ShortForm: 'BB',
        CountryTitle: 'Barbados',
    },
    {
        ID: '21',
        ShortForm: 'BY',
        CountryTitle: 'Belarus',
    },
    {
        ID: '22',
        ShortForm: 'BE',
        CountryTitle: 'Belgium',
    },
    {
        ID: '23',
        ShortForm: 'BZ',
        CountryTitle: 'Belize',
    },
    {
        ID: '24',
        ShortForm: 'BJ',
        CountryTitle: 'Benin',
    },
    {
        ID: '25',
        ShortForm: 'BM',
        CountryTitle: 'Bermuda',
    },
    {
        ID: '26',
        ShortForm: 'BT',
        CountryTitle: 'Bhutan',
    },
    {
        ID: '27',
        ShortForm: 'BO',
        CountryTitle: 'Bolivia',
    },
    {
        ID: '28',
        ShortForm: 'BA',
        CountryTitle: 'Bosnia and Herzegovina',
    },
    {
        ID: '29',
        ShortForm: 'BW',
        CountryTitle: 'Botswana',
    },
    {
        ID: '31',
        ShortForm: 'BR',
        CountryTitle: 'Brazil',
    },
    {
        ID: '32',
        ShortForm: 'IO',
        CountryTitle: 'British Indian Ocean Territory',
    },
    {
        ID: '33',
        ShortForm: 'BN',
        CountryTitle: 'Brunei Darussalam',
    },
    {
        ID: '34',
        ShortForm: 'BG',
        CountryTitle: 'Bulgaria',
    },
    {
        ID: '35',
        ShortForm: 'BF',
        CountryTitle: 'Burkina Faso',
    },
    {
        ID: '36',
        ShortForm: 'BI',
        CountryTitle: 'Burundi',
    },
    {
        ID: '37',
        ShortForm: 'KH',
        CountryTitle: 'Cambodia',
    },
    {
        ID: '38',
        ShortForm: 'CM',
        CountryTitle: 'Cameroon',
    },
    {
        ID: '39',
        ShortForm: 'CA',
        CountryTitle: 'Canada',
    },
    {
        ID: '40',
        ShortForm: 'CV',
        CountryTitle: 'Cape Verde',
    },
    {
        ID: '41',
        ShortForm: 'KY',
        CountryTitle: 'Cayman Islands',
    },
    {
        ID: '42',
        ShortForm: 'CF',
        CountryTitle: 'Central African Republic',
    },
    {
        ID: '43',
        ShortForm: 'TD',
        CountryTitle: 'Chad',
    },
    {
        ID: '44',
        ShortForm: 'CL',
        CountryTitle: 'Chile',
    },
    {
        ID: '45',
        ShortForm: 'CN',
        CountryTitle: 'China',
    },
    {
        ID: '46',
        ShortForm: 'CX',
        CountryTitle: 'Christmas Island',
    },
    {
        ID: '47',
        ShortForm: 'CC',
        CountryTitle: 'Cocos (Keeling) Islands',
    },
    {
        ID: '48',
        ShortForm: 'CO',
        CountryTitle: 'Colombia',
    },
    {
        ID: '49',
        ShortForm: 'KM',
        CountryTitle: 'Comoros',
    },
    {
        ID: '50',
        ShortForm: 'CG',
        CountryTitle: 'Congo',
    },
    {
        ID: '51',
        ShortForm: 'CD',
        CountryTitle: 'Congo, The Democratic Republic of the',
    },
    {
        ID: '52',
        ShortForm: 'CK',
        CountryTitle: 'Cook Islands',
    },
    {
        ID: '53',
        ShortForm: 'CR',
        CountryTitle: 'Costa Rica',
    },
    {
        ID: '54',
        ShortForm: 'CI',
        CountryTitle: "Cote d'Ivoire",
    },
    {
        ID: '55',
        ShortForm: 'HR',
        CountryTitle: 'Croatia',
    },
    {
        ID: '56',
        ShortForm: 'CU',
        CountryTitle: 'Cuba',
    },
    {
        ID: '57',
        ShortForm: 'CY',
        CountryTitle: 'Cyprus',
    },
    {
        ID: '58',
        ShortForm: 'CZ',
        CountryTitle: 'Czech Republic',
    },
    {
        ID: '59',
        ShortForm: 'DK',
        CountryTitle: 'Denmark',
    },
    {
        ID: '60',
        ShortForm: 'DJ',
        CountryTitle: 'Djibouti',
    },
    {
        ID: '61',
        ShortForm: 'DM',
        CountryTitle: 'Dominica',
    },
    {
        ID: '62',
        ShortForm: 'DO',
        CountryTitle: 'Dominican Republic',
    },
    {
        ID: '63',
        ShortForm: 'EC',
        CountryTitle: 'Ecuador',
    },
    {
        ID: '64',
        ShortForm: 'EG',
        CountryTitle: 'Egypt',
    },
    {
        ID: '65',
        ShortForm: 'SV',
        CountryTitle: 'El Salvador',
    },
    {
        ID: '66',
        ShortForm: 'GQ',
        CountryTitle: 'Equatorial Guinea',
    },
    {
        ID: '67',
        ShortForm: 'ER',
        CountryTitle: 'Eritrea',
    },
    {
        ID: '68',
        ShortForm: 'EE',
        CountryTitle: 'Estonia',
    },
    {
        ID: '69',
        ShortForm: 'ET',
        CountryTitle: 'Ethiopia',
    },
    {
        ID: '70',
        ShortForm: 'FK',
        CountryTitle: 'Falkland Islands (Malvinas)',
    },
    {
        ID: '71',
        ShortForm: 'FO',
        CountryTitle: 'Faroe Islands',
    },
    {
        ID: '72',
        ShortForm: 'FJ',
        CountryTitle: 'Fiji',
    },
    {
        ID: '73',
        ShortForm: 'FI',
        CountryTitle: 'Finland',
    },
    {
        ID: '74',
        ShortForm: 'FR',
        CountryTitle: 'France',
    },
    {
        ID: '75',
        ShortForm: 'GF',
        CountryTitle: 'French Guiana',
    },
    {
        ID: '76',
        ShortForm: 'PF',
        CountryTitle: 'French Polynesia',
    },
    {
        ID: '77',
        ShortForm: 'TF',
        CountryTitle: 'French Southern Territories',
    },
    {
        ID: '78',
        ShortForm: 'GA',
        CountryTitle: 'Gabon',
    },
    {
        ID: '79',
        ShortForm: 'GM',
        CountryTitle: 'Gambia',
    },
    {
        ID: '80',
        ShortForm: 'GE',
        CountryTitle: 'Georgia',
    },
    {
        ID: '81',
        ShortForm: 'DE',
        CountryTitle: 'Germany',
    },
    {
        ID: '82',
        ShortForm: 'GH',
        CountryTitle: 'Ghana',
    },
    {
        ID: '83',
        ShortForm: 'GI',
        CountryTitle: 'Gibraltar',
    },
    {
        ID: '84',
        ShortForm: 'GR',
        CountryTitle: 'Greece',
    },
    {
        ID: '85',
        ShortForm: 'GL',
        CountryTitle: 'Greenland',
    },
    {
        ID: '86',
        ShortForm: 'GD',
        CountryTitle: 'Grenada',
    },
    {
        ID: '87',
        ShortForm: 'GP',
        CountryTitle: 'Guadeloupe',
    },
    {
        ID: '88',
        ShortForm: 'GU',
        CountryTitle: 'Guam',
    },
    {
        ID: '89',
        ShortForm: 'GT',
        CountryTitle: 'Guatemala',
    },
    {
        ID: '90',
        ShortForm: 'GG',
        CountryTitle: 'Guernsey',
    },
    {
        ID: '91',
        ShortForm: 'GN',
        CountryTitle: 'Guinea',
    },
    {
        ID: '92',
        ShortForm: 'GW',
        CountryTitle: 'Guinea-Bissau',
    },
    {
        ID: '93',
        ShortForm: 'GY',
        CountryTitle: 'Guyana',
    },
    {
        ID: '94',
        ShortForm: 'HT',
        CountryTitle: 'Haiti',
    },
    {
        ID: '95',
        ShortForm: 'HM',
        CountryTitle: 'Heard Island and McDonald Islands',
    },
    {
        ID: '96',
        ShortForm: 'VA',
        CountryTitle: 'Holy See (Vatican City State)',
    },
    {
        ID: '97',
        ShortForm: 'HN',
        CountryTitle: 'Honduras',
    },
    {
        ID: '98',
        ShortForm: 'HK',
        CountryTitle: 'Hong Kong',
    },
    {
        ID: '99',
        ShortForm: 'HU',
        CountryTitle: 'Hungary',
    },
    {
        ID: '100',
        ShortForm: 'IS',
        CountryTitle: 'Iceland',
    },
    {
        ID: '101',
        ShortForm: 'IN',
        CountryTitle: 'India',
    },
    {
        ID: '102',
        ShortForm: 'ID',
        CountryTitle: 'Indonesia',
    },
    {
        ID: '103',
        ShortForm: 'IR',
        CountryTitle: 'Iran, Islamic Republic of',
    },
    {
        ID: '104',
        ShortForm: 'IQ',
        CountryTitle: 'Iraq',
    },
    {
        ID: '105',
        ShortForm: 'IE',
        CountryTitle: 'Ireland',
    },
    {
        ID: '106',
        ShortForm: 'IM',
        CountryTitle: 'Isle of Man',
    },
    {
        ID: '107',
        ShortForm: 'IL',
        CountryTitle: 'Israel',
    },
    {
        ID: '108',
        ShortForm: 'IT',
        CountryTitle: 'Italy',
    },
    {
        ID: '109',
        ShortForm: 'JM',
        CountryTitle: 'Jamaica',
    },
    {
        ID: '110',
        ShortForm: 'JP',
        CountryTitle: 'Japan',
    },
    {
        ID: '111',
        ShortForm: 'JE',
        CountryTitle: 'Jersey',
    },
    {
        ID: '112',
        ShortForm: 'JO',
        CountryTitle: 'Jordan',
    },
    {
        ID: '113',
        ShortForm: 'KZ',
        CountryTitle: 'Kazakhstan',
    },
    {
        ID: '114',
        ShortForm: 'KE',
        CountryTitle: 'Kenya',
    },
    {
        ID: '115',
        ShortForm: 'KI',
        CountryTitle: 'Kiribati',
    },
    {
        ID: '116',
        ShortForm: 'KP',
        CountryTitle: "Korea, Democratic People's Republic of",
    },
    {
        ID: '117',
        ShortForm: 'KR',
        CountryTitle: 'Korea, Republic of',
    },
    {
        ID: '118',
        ShortForm: 'KW',
        CountryTitle: 'Kuwait',
    },
    {
        ID: '119',
        ShortForm: 'KG',
        CountryTitle: 'Kyrgyzstan',
    },
    {
        ID: '120',
        ShortForm: 'LA',
        CountryTitle: "Lao People's Democratic Republic",
    },
    {
        ID: '121',
        ShortForm: 'LV',
        CountryTitle: 'Latvia',
    },
    {
        ID: '122',
        ShortForm: 'LB',
        CountryTitle: 'Lebanon',
    },
    {
        ID: '123',
        ShortForm: 'LS',
        CountryTitle: 'Lesotho',
    },
    {
        ID: '124',
        ShortForm: 'LR',
        CountryTitle: 'Liberia',
    },
    {
        ID: '125',
        ShortForm: 'LY',
        CountryTitle: 'Libyan Arab Jamahiriya',
    },
    {
        ID: '126',
        ShortForm: 'LI',
        CountryTitle: 'Liechtenstein',
    },
    {
        ID: '127',
        ShortForm: 'LT',
        CountryTitle: 'Lithuania',
    },
    {
        ID: '128',
        ShortForm: 'LU',
        CountryTitle: 'Luxembourg',
    },
    {
        ID: '129',
        ShortForm: 'MO',
        CountryTitle: 'Macao',
    },
    {
        ID: '130',
        ShortForm: 'MK',
        CountryTitle: 'Macedonia',
    },
    {
        ID: '131',
        ShortForm: 'MG',
        CountryTitle: 'Madagascar',
    },
    {
        ID: '132',
        ShortForm: 'MW',
        CountryTitle: 'Malawi',
    },
    {
        ID: '133',
        ShortForm: 'MY',
        CountryTitle: 'Malaysia',
    },
    {
        ID: '134',
        ShortForm: 'MV',
        CountryTitle: 'Maldives',
    },
    {
        ID: '135',
        ShortForm: 'ML',
        CountryTitle: 'Mali',
    },
    {
        ID: '136',
        ShortForm: 'MT',
        CountryTitle: 'Malta',
    },
    {
        ID: '137',
        ShortForm: 'MH',
        CountryTitle: 'Marshall Islands',
    },
    {
        ID: '138',
        ShortForm: 'MQ',
        CountryTitle: 'Martinique',
    },
    {
        ID: '139',
        ShortForm: 'MR',
        CountryTitle: 'Mauritania',
    },
    {
        ID: '140',
        ShortForm: 'MU',
        CountryTitle: 'Mauritius',
    },
    {
        ID: '141',
        ShortForm: 'YT',
        CountryTitle: 'Mayotte',
    },
    {
        ID: '142',
        ShortForm: 'MX',
        CountryTitle: 'Mexico',
    },
    {
        ID: '143',
        ShortForm: 'FM',
        CountryTitle: 'Micronesia, Federated States of',
    },
    {
        ID: '144',
        ShortForm: 'MD',
        CountryTitle: 'Moldova, Republic of',
    },
    {
        ID: '145',
        ShortForm: 'MC',
        CountryTitle: 'Monaco',
    },
    {
        ID: '146',
        ShortForm: 'MN',
        CountryTitle: 'Mongolia',
    },
    {
        ID: '147',
        ShortForm: 'ME',
        CountryTitle: 'Montenegro',
    },
    {
        ID: '148',
        ShortForm: 'MS',
        CountryTitle: 'Montserrat',
    },
    {
        ID: '149',
        ShortForm: 'MA',
        CountryTitle: 'Morocco',
    },
    {
        ID: '150',
        ShortForm: 'MZ',
        CountryTitle: 'Mozambique',
    },
    {
        ID: '151',
        ShortForm: 'MM',
        CountryTitle: 'Myanmar',
    },
    {
        ID: '152',
        ShortForm: 'NA',
        CountryTitle: 'Namibia',
    },
    {
        ID: '153',
        ShortForm: 'NR',
        CountryTitle: 'Nauru',
    },
    {
        ID: '154',
        ShortForm: 'NP',
        CountryTitle: 'Nepal',
    },
    {
        ID: '155',
        ShortForm: 'NL',
        CountryTitle: 'Netherlands',
    },
    {
        ID: '156',
        ShortForm: 'AN',
        CountryTitle: 'Netherlands Antilles',
    },
    {
        ID: '157',
        ShortForm: 'NC',
        CountryTitle: 'New Caledonia',
    },
    {
        ID: '158',
        ShortForm: 'NZ',
        CountryTitle: 'New Zealand',
    },
    {
        ID: '159',
        ShortForm: 'NI',
        CountryTitle: 'Nicaragua',
    },
    {
        ID: '160',
        ShortForm: 'NE',
        CountryTitle: 'Niger',
    },
    {
        ID: '161',
        ShortForm: 'NG',
        CountryTitle: 'Nigeria',
    },
    {
        ID: '162',
        ShortForm: 'NU',
        CountryTitle: 'Niue',
    },
    {
        ID: '163',
        ShortForm: 'NF',
        CountryTitle: 'Norfolk Island',
    },
    {
        ID: '164',
        ShortForm: 'MP',
        CountryTitle: 'Northern Mariana Islands',
    },
    {
        ID: '165',
        ShortForm: 'NO',
        CountryTitle: 'Norway',
    },
    {
        ID: '166',
        ShortForm: 'OM',
        CountryTitle: 'Oman',
    },
    {
        ID: '167',
        ShortForm: 'PK',
        CountryTitle: 'Pakistan',
    },
    {
        ID: '168',
        ShortForm: 'PW',
        CountryTitle: 'Palau',
    },
    {
        ID: '169',
        ShortForm: 'PS',
        CountryTitle: 'Palestinian Territory',
    },
    {
        ID: '170',
        ShortForm: 'PA',
        CountryTitle: 'Panama',
    },
    {
        ID: '171',
        ShortForm: 'PG',
        CountryTitle: 'Papua New Guinea',
    },
    {
        ID: '172',
        ShortForm: 'PY',
        CountryTitle: 'Paraguay',
    },
    {
        ID: '173',
        ShortForm: 'PE',
        CountryTitle: 'Peru',
    },
    {
        ID: '174',
        ShortForm: 'PH',
        CountryTitle: 'Philippines',
    },
    {
        ID: '175',
        ShortForm: 'PN',
        CountryTitle: 'Pitcairn',
    },
    {
        ID: '176',
        ShortForm: 'PL',
        CountryTitle: 'Poland',
    },
    {
        ID: '177',
        ShortForm: 'PT',
        CountryTitle: 'Portugal',
    },
    {
        ID: '178',
        ShortForm: 'PR',
        CountryTitle: 'Puerto Rico',
    },
    {
        ID: '179',
        ShortForm: 'QA',
        CountryTitle: 'Qatar',
    },
    {
        ID: '180',
        ShortForm: 'RE',
        CountryTitle: 'Reunion',
    },
    {
        ID: '181',
        ShortForm: 'RO',
        CountryTitle: 'Romania',
    },
    {
        ID: '182',
        ShortForm: 'RU',
        CountryTitle: 'Russian Federation',
    },
    {
        ID: '183',
        ShortForm: 'RW',
        CountryTitle: 'Rwanda',
    },
    {
        ID: '184',
        ShortForm: 'SH',
        CountryTitle: 'Saint Helena',
    },
    {
        ID: '185',
        ShortForm: 'KN',
        CountryTitle: 'Saint Kitts and Nevis',
    },
    {
        ID: '186',
        ShortForm: 'LC',
        CountryTitle: 'Saint Lucia',
    },
    {
        ID: '187',
        ShortForm: 'PM',
        CountryTitle: 'Saint Pierre and Miquelon',
    },
    {
        ID: '188',
        ShortForm: 'VC',
        CountryTitle: 'Saint Vincent and the Grenadines',
    },
    {
        ID: '189',
        ShortForm: 'WS',
        CountryTitle: 'Samoa',
    },
    {
        ID: '190',
        ShortForm: 'SM',
        CountryTitle: 'San Marino',
    },
    {
        ID: '191',
        ShortForm: 'ST',
        CountryTitle: 'Sao Tome and Principe',
    },
    {
        ID: '192',
        ShortForm: 'SA',
        CountryTitle: 'Saudi Arabia',
    },
    {
        ID: '193',
        ShortForm: 'SN',
        CountryTitle: 'Senegal',
    },
    {
        ID: '194',
        ShortForm: 'RS',
        CountryTitle: 'Serbia',
    },
    {
        ID: '195',
        ShortForm: 'SC',
        CountryTitle: 'Seychelles',
    },
    {
        ID: '196',
        ShortForm: 'SL',
        CountryTitle: 'Sierra Leone',
    },
    {
        ID: '197',
        ShortForm: 'SG',
        CountryTitle: 'Singapore',
    },
    {
        ID: '198',
        ShortForm: 'SK',
        CountryTitle: 'Slovakia',
    },
    {
        ID: '199',
        ShortForm: 'SI',
        CountryTitle: 'Slovenia',
    },
    {
        ID: '200',
        ShortForm: 'SB',
        CountryTitle: 'Solomon Islands',
    },
    {
        ID: '201',
        ShortForm: 'SO',
        CountryTitle: 'Somalia',
    },
    {
        ID: '202',
        ShortForm: 'ZA',
        CountryTitle: 'South Africa',
    },
    {
        ID: '203',
        ShortForm: 'GS',
        CountryTitle: 'South Georgia and the South Sandwich Islands',
    },
    {
        ID: '204',
        ShortForm: 'ES',
        CountryTitle: 'Spain',
    },
    {
        ID: '205',
        ShortForm: 'LK',
        CountryTitle: 'Sri Lanka',
    },
    {
        ID: '206',
        ShortForm: 'SD',
        CountryTitle: 'Sudan',
    },
    {
        ID: '207',
        ShortForm: 'SR',
        CountryTitle: 'Suriname',
    },
    {
        ID: '208',
        ShortForm: 'SJ',
        CountryTitle: 'Svalbard and Jan Mayen',
    },
    {
        ID: '209',
        ShortForm: 'SZ',
        CountryTitle: 'Swaziland',
    },
    {
        ID: '210',
        ShortForm: 'SE',
        CountryTitle: 'Sweden',
    },
    {
        ID: '211',
        ShortForm: 'CH',
        CountryTitle: 'Switzerland',
    },
    {
        ID: '212',
        ShortForm: 'SY',
        CountryTitle: 'Syrian Arab Republic',
    },
    {
        ID: '213',
        ShortForm: 'TW',
        CountryTitle: 'Taiwan',
    },
    {
        ID: '214',
        ShortForm: 'TJ',
        CountryTitle: 'Tajikistan',
    },
    {
        ID: '215',
        ShortForm: 'TZ',
        CountryTitle: 'Tanzania, United Republic of',
    },
    {
        ID: '216',
        ShortForm: 'TH',
        CountryTitle: 'Thailand',
    },
    {
        ID: '217',
        ShortForm: 'TL',
        CountryTitle: 'Timor-Leste',
    },
    {
        ID: '218',
        ShortForm: 'TG',
        CountryTitle: 'Togo',
    },
    {
        ID: '219',
        ShortForm: 'TK',
        CountryTitle: 'Tokelau',
    },
    {
        ID: '220',
        ShortForm: 'TO',
        CountryTitle: 'Tonga',
    },
    {
        ID: '221',
        ShortForm: 'TT',
        CountryTitle: 'Trinidad and Tobago',
    },
    {
        ID: '222',
        ShortForm: 'TN',
        CountryTitle: 'Tunisia',
    },
    {
        ID: '223',
        ShortForm: 'TR',
        CountryTitle: 'Turkey',
    },
    {
        ID: '224',
        ShortForm: 'TM',
        CountryTitle: 'Turkmenistan',
    },
    {
        ID: '225',
        ShortForm: 'TC',
        CountryTitle: 'Turks and Caicos Islands',
    },
    {
        ID: '226',
        ShortForm: 'TV',
        CountryTitle: 'Tuvalu',
    },
    {
        ID: '227',
        ShortForm: 'UG',
        CountryTitle: 'Uganda',
    },
    {
        ID: '228',
        ShortForm: 'UA',
        CountryTitle: 'Ukraine',
    },
    {
        ID: '229',
        ShortForm: 'AE',
        CountryTitle: 'United Arab Emirates',
    },
    {
        ID: '230',
        ShortForm: 'GB',
        CountryTitle: 'United Kingdom',
    },
    {
        ID: '231',
        ShortForm: 'US',
        CountryTitle: 'United States',
    },
    {
        ID: '232',
        ShortForm: 'UM',
        CountryTitle: 'United States Minor Outlying Islands',
    },
    {
        ID: '233',
        ShortForm: 'UY',
        CountryTitle: 'Uruguay',
    },
    {
        ID: '234',
        ShortForm: 'UZ',
        CountryTitle: 'Uzbekistan',
    },
    {
        ID: '235',
        ShortForm: 'VU',
        CountryTitle: 'Vanuatu',
    },
    {
        ID: '236',
        ShortForm: 'VE',
        CountryTitle: 'Venezuela',
    },
    {
        ID: '237',
        ShortForm: 'VN',
        CountryTitle: 'Vietnam',
    },
    {
        ID: '238',
        ShortForm: 'VG',
        CountryTitle: 'Virgin Islands, British',
    },
    {
        ID: '239',
        ShortForm: 'VI',
        CountryTitle: 'Virgin Islands, U.S.',
    },
    {
        ID: '240',
        ShortForm: 'WF',
        CountryTitle: 'Wallis and Futuna',
    },
    {
        ID: '241',
        ShortForm: 'EH',
        CountryTitle: 'Western Sahara',
    },
    {
        ID: '242',
        ShortForm: 'YE',
        CountryTitle: 'Yemen',
    },
    {
        ID: '243',
        ShortForm: 'ZM',
        CountryTitle: 'Zambia',
    },
    {
        ID: '244',
        ShortForm: 'ZW',
        CountryTitle: 'Zimbabwe',
    },
];

export const STATES = [
    {
        ID: '1',
        StateTitle: 'Canillo',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '2',
        StateTitle: 'Encamp',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '3',
        StateTitle: 'La Massana',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '4',
        StateTitle: 'Ordino',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '5',
        StateTitle: 'Sant Julia de Loria',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '6',
        StateTitle: 'Andorra la Vella',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '7',
        StateTitle: 'Escaldes-Engordany',
        ShortForm: 'NULL',
        CountryID: '6',
    },
    {
        ID: '8',
        StateTitle: 'Abu Dhabi',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '9',
        StateTitle: 'Ajman',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '10',
        StateTitle: 'Dubai',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '11',
        StateTitle: 'Fujairah',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '12',
        StateTitle: 'Ras Al Khaimah',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '13',
        StateTitle: 'Sharjah',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '14',
        StateTitle: 'Umm Al Quwain',
        ShortForm: 'NULL',
        CountryID: '229',
    },
    {
        ID: '15',
        StateTitle: 'Badakhshan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '16',
        StateTitle: 'Badghis',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '17',
        StateTitle: 'Baghlan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '18',
        StateTitle: 'Bamian',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '19',
        StateTitle: 'Farah',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '20',
        StateTitle: 'Faryab',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '21',
        StateTitle: 'Ghazni',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '22',
        StateTitle: 'Ghowr',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '23',
        StateTitle: 'Helmand',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '24',
        StateTitle: 'Herat',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '25',
        StateTitle: 'Kabol',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '26',
        StateTitle: 'Kapisa',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '27',
        StateTitle: 'Lowgar',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '28',
        StateTitle: 'Nangarhar',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '29',
        StateTitle: 'Nimruz',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '30',
        StateTitle: 'Kandahar',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '31',
        StateTitle: 'Kondoz',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '32',
        StateTitle: 'Takhar',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '33',
        StateTitle: 'Vardak',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '34',
        StateTitle: 'Zabol',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '35',
        StateTitle: 'Paktika',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '36',
        StateTitle: 'Balkh',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '37',
        StateTitle: 'Jowzjan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '38',
        StateTitle: 'Samangan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '39',
        StateTitle: 'Sar-e Pol',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '40',
        StateTitle: 'Konar',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '41',
        StateTitle: 'Laghman',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '42',
        StateTitle: 'Paktia',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '43',
        StateTitle: 'Khowst',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '44',
        StateTitle: 'Nurestan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '45',
        StateTitle: 'Oruzgan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '46',
        StateTitle: 'Parvan',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '47',
        StateTitle: 'Daykondi',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '48',
        StateTitle: 'Panjshir',
        ShortForm: 'NULL',
        CountryID: '1',
    },
    {
        ID: '49',
        StateTitle: 'Barbuda',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '50',
        StateTitle: 'Saint George',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '51',
        StateTitle: 'Saint John',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '52',
        StateTitle: 'Saint Mary',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '53',
        StateTitle: 'Saint Paul',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '54',
        StateTitle: 'Saint Peter',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '55',
        StateTitle: 'Saint Philip',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '56',
        StateTitle: 'Redonda',
        ShortForm: 'NULL',
        CountryID: '10',
    },
    {
        ID: '57',
        StateTitle: 'Berat',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '58',
        StateTitle: 'Diber',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '59',
        StateTitle: 'Durres',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '60',
        StateTitle: 'Elbasan',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '61',
        StateTitle: 'Fier',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '62',
        StateTitle: 'Gjirokaster',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '63',
        StateTitle: 'Korce',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '64',
        StateTitle: 'Kukes',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '65',
        StateTitle: 'Lezhe',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '66',
        StateTitle: 'Shkoder',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '67',
        StateTitle: 'Tirane',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '68',
        StateTitle: 'Vlore',
        ShortForm: 'NULL',
        CountryID: '3',
    },
    {
        ID: '69',
        StateTitle: 'Aragatsotn',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '70',
        StateTitle: 'Ararat',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '71',
        StateTitle: 'Armavir',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '72',
        StateTitle: "Geghark'unik'",
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '73',
        StateTitle: "Kotayk'",
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '74',
        StateTitle: 'Lorri',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '75',
        StateTitle: 'Shirak',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '76',
        StateTitle: "Syunik'",
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '77',
        StateTitle: 'Tavush',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '78',
        StateTitle: "Vayots' Dzor",
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '79',
        StateTitle: 'Yerevan',
        ShortForm: 'NULL',
        CountryID: '12',
    },
    {
        ID: '80',
        StateTitle: 'Benguela',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '81',
        StateTitle: 'Bie',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '82',
        StateTitle: 'Cabinda',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '83',
        StateTitle: 'Cuando Cubango',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '84',
        StateTitle: 'Cuanza Norte',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '85',
        StateTitle: 'Cuanza Sul',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '86',
        StateTitle: 'Cunene',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '87',
        StateTitle: 'Huambo',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '88',
        StateTitle: 'Huila',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '89',
        StateTitle: 'Malanje',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '90',
        StateTitle: 'Namibe',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '91',
        StateTitle: 'Moxico',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '92',
        StateTitle: 'Uige',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '93',
        StateTitle: 'Zaire',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '94',
        StateTitle: 'Lunda Norte',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '95',
        StateTitle: 'Lunda Sul',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '96',
        StateTitle: 'Bengo',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '97',
        StateTitle: 'Luanda',
        ShortForm: 'NULL',
        CountryID: '7',
    },
    {
        ID: '98',
        StateTitle: 'Buenos Aires',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '99',
        StateTitle: 'Catamarca',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '100',
        StateTitle: 'Chaco',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '101',
        StateTitle: 'Chubut',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '102',
        StateTitle: 'Cordoba',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '103',
        StateTitle: 'Corrientes',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '104',
        StateTitle: 'Distrito Federal',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '105',
        StateTitle: 'Entre Rios',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '106',
        StateTitle: 'Formosa',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '107',
        StateTitle: 'Jujuy',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '108',
        StateTitle: 'La Pampa',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '109',
        StateTitle: 'La Rioja',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '110',
        StateTitle: 'Mendoza',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '111',
        StateTitle: 'Misiones',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '112',
        StateTitle: 'Neuquen',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '113',
        StateTitle: 'Rio Negro',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '114',
        StateTitle: 'Salta',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '115',
        StateTitle: 'San Juan',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '116',
        StateTitle: 'San Luis',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '117',
        StateTitle: 'Santa Cruz',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '118',
        StateTitle: 'Santa Fe',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '119',
        StateTitle: 'Santiago del Estero',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '120',
        StateTitle: 'Tierra del Fuego',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '121',
        StateTitle: 'Tucuman',
        ShortForm: 'NULL',
        CountryID: '11',
    },
    {
        ID: '122',
        StateTitle: 'Burgenland',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '123',
        StateTitle: 'Karnten',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '124',
        StateTitle: 'Niederosterreich',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '125',
        StateTitle: 'Oberosterreich',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '126',
        StateTitle: 'Salzburg',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '127',
        StateTitle: 'Steiermark',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '128',
        StateTitle: 'Tirol',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '129',
        StateTitle: 'Vorarlberg',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '130',
        StateTitle: 'Wien',
        ShortForm: 'NULL',
        CountryID: '15',
    },
    {
        ID: '131',
        StateTitle: 'Australian Capital Territory',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '132',
        StateTitle: 'New South Wales',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '133',
        StateTitle: 'Northern Territory',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '134',
        StateTitle: 'Queensland',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '135',
        StateTitle: 'South Australia',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '136',
        StateTitle: 'Tasmania',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '137',
        StateTitle: 'Victoria',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '138',
        StateTitle: 'Western Australia',
        ShortForm: 'NULL',
        CountryID: '14',
    },
    {
        ID: '139',
        StateTitle: 'Abseron',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '140',
        StateTitle: 'Agcabadi',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '141',
        StateTitle: 'Agdam',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '142',
        StateTitle: 'Agdas',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '143',
        StateTitle: 'Agstafa',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '144',
        StateTitle: 'Agsu',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '145',
        StateTitle: 'Ali Bayramli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '146',
        StateTitle: 'Astara',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '147',
        StateTitle: 'Baki',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '148',
        StateTitle: 'Balakan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '149',
        StateTitle: 'Barda',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '150',
        StateTitle: 'Beylaqan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '151',
        StateTitle: 'Bilasuvar',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '152',
        StateTitle: 'Cabrayil',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '153',
        StateTitle: 'Calilabad',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '154',
        StateTitle: 'Daskasan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '155',
        StateTitle: 'Davaci',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '156',
        StateTitle: 'Fuzuli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '157',
        StateTitle: 'Gadabay',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '158',
        StateTitle: 'Ganca',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '159',
        StateTitle: 'Goranboy',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '160',
        StateTitle: 'Goycay',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '161',
        StateTitle: 'Haciqabul',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '162',
        StateTitle: 'Imisli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '163',
        StateTitle: 'Ismayilli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '164',
        StateTitle: 'Kalbacar',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '165',
        StateTitle: 'Kurdamir',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '166',
        StateTitle: 'Lacin',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '167',
        StateTitle: 'Lankaran',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '168',
        StateTitle: 'Lankaran',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '169',
        StateTitle: 'Lerik',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '170',
        StateTitle: 'Masalli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '171',
        StateTitle: 'Mingacevir',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '172',
        StateTitle: 'Naftalan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '173',
        StateTitle: 'Naxcivan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '174',
        StateTitle: 'Neftcala',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '175',
        StateTitle: 'Oguz',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '176',
        StateTitle: 'Qabala',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '177',
        StateTitle: 'Qax',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '178',
        StateTitle: 'Qazax',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '179',
        StateTitle: 'Qobustan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '180',
        StateTitle: 'Quba',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '181',
        StateTitle: 'Qubadli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '182',
        StateTitle: 'Qusar',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '183',
        StateTitle: 'Saatli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '184',
        StateTitle: 'Sabirabad',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '185',
        StateTitle: 'Saki',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '186',
        StateTitle: 'Saki',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '187',
        StateTitle: 'Salyan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '188',
        StateTitle: 'Samaxi',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '189',
        StateTitle: 'Samkir',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '190',
        StateTitle: 'Samux',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '191',
        StateTitle: 'Siyazan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '192',
        StateTitle: 'Sumqayit',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '193',
        StateTitle: 'Susa',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '194',
        StateTitle: 'Susa',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '195',
        StateTitle: 'Tartar',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '196',
        StateTitle: 'Tovuz',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '197',
        StateTitle: 'Ucar',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '198',
        StateTitle: 'Xacmaz',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '199',
        StateTitle: 'Xankandi',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '200',
        StateTitle: 'Xanlar',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '201',
        StateTitle: 'Xizi',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '202',
        StateTitle: 'Xocali',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '203',
        StateTitle: 'Xocavand',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '204',
        StateTitle: 'Yardimli',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '205',
        StateTitle: 'Yevlax',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '206',
        StateTitle: 'Yevlax',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '207',
        StateTitle: 'Zangilan',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '208',
        StateTitle: 'Zaqatala',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '209',
        StateTitle: 'Zardab',
        ShortForm: 'NULL',
        CountryID: '16',
    },
    {
        ID: '210',
        StateTitle: 'Federation of Bosnia and Herzegovina',
        ShortForm: 'NULL',
        CountryID: '28',
    },
    {
        ID: '211',
        StateTitle: 'Republika Srpska',
        ShortForm: 'NULL',
        CountryID: '28',
    },
    {
        ID: '212',
        StateTitle: 'Christ Church',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '213',
        StateTitle: 'Saint Andrew',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '214',
        StateTitle: 'Saint George',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '215',
        StateTitle: 'Saint James',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '216',
        StateTitle: 'Saint John',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '217',
        StateTitle: 'Saint Joseph',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '218',
        StateTitle: 'Saint Lucy',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '219',
        StateTitle: 'Saint Michael',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '220',
        StateTitle: 'Saint Peter',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '221',
        StateTitle: 'Saint Philip',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '222',
        StateTitle: 'Saint Thomas',
        ShortForm: 'NULL',
        CountryID: '20',
    },
    {
        ID: '223',
        StateTitle: 'Dhaka',
        ShortForm: 'NULL',
        CountryID: '19',
    },
    {
        ID: '224',
        StateTitle: 'Khulna',
        ShortForm: 'NULL',
        CountryID: '19',
    },
    {
        ID: '225',
        StateTitle: 'Rajshahi',
        ShortForm: 'NULL',
        CountryID: '19',
    },
    {
        ID: '226',
        StateTitle: 'Chittagong',
        ShortForm: 'NULL',
        CountryID: '19',
    },
    {
        ID: '227',
        StateTitle: 'Barisal',
        ShortForm: 'NULL',
        CountryID: '19',
    },
    {
        ID: '228',
        StateTitle: 'Sylhet',
        ShortForm: 'NULL',
        CountryID: '19',
    },
    {
        ID: '229',
        StateTitle: 'Antwerpen',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '230',
        StateTitle: 'Hainaut',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '231',
        StateTitle: 'Liege',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '232',
        StateTitle: 'Limburg',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '233',
        StateTitle: 'Luxembourg',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '234',
        StateTitle: 'Namur',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '235',
        StateTitle: 'Oost-Vlaanderen',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '236',
        StateTitle: 'West-Vlaanderen',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '237',
        StateTitle: 'Brabant Wallon',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '238',
        StateTitle: 'Brussels Hoofdstedelijk Gewest',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '239',
        StateTitle: 'Vlaams-Brabant',
        ShortForm: 'NULL',
        CountryID: '22',
    },
    {
        ID: '240',
        StateTitle: 'Bam',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '241',
        StateTitle: 'Boulkiemde',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '242',
        StateTitle: 'Ganzourgou',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '243',
        StateTitle: 'Gnagna',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '244',
        StateTitle: 'Kouritenga',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '245',
        StateTitle: 'Oudalan',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '246',
        StateTitle: 'Passore',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '247',
        StateTitle: 'Sanguie',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '248',
        StateTitle: 'Soum',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '249',
        StateTitle: 'Tapoa',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '250',
        StateTitle: 'Zoundweogo',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '251',
        StateTitle: 'Bale',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '252',
        StateTitle: 'Banwa',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '253',
        StateTitle: 'Bazega',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '254',
        StateTitle: 'Bougouriba',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '255',
        StateTitle: 'Boulgou',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '256',
        StateTitle: 'Gourma',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '257',
        StateTitle: 'Houet',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '258',
        StateTitle: 'Ioba',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '259',
        StateTitle: 'Kadiogo',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '260',
        StateTitle: 'Kenedougou',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '261',
        StateTitle: 'Komoe',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '262',
        StateTitle: 'Komondjari',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '263',
        StateTitle: 'Kompienga',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '264',
        StateTitle: 'Kossi',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '265',
        StateTitle: 'Koulpelogo',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '266',
        StateTitle: 'Kourweogo',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '267',
        StateTitle: 'Leraba',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '268',
        StateTitle: 'Loroum',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '269',
        StateTitle: 'Mouhoun',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '270',
        StateTitle: 'Namentenga',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '271',
        StateTitle: 'Naouri',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '272',
        StateTitle: 'Nayala',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '273',
        StateTitle: 'Noumbiel',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '274',
        StateTitle: 'Oubritenga',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '275',
        StateTitle: 'Poni',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '276',
        StateTitle: 'Sanmatenga',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '277',
        StateTitle: 'Seno',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '278',
        StateTitle: 'Sissili',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '279',
        StateTitle: 'Sourou',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '280',
        StateTitle: 'Tuy',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '281',
        StateTitle: 'Yagha',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '282',
        StateTitle: 'Yatenga',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '283',
        StateTitle: 'Ziro',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '284',
        StateTitle: 'Zondoma',
        ShortForm: 'NULL',
        CountryID: '35',
    },
    {
        ID: '285',
        StateTitle: 'Mikhaylovgrad',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '286',
        StateTitle: 'Blagoevgrad',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '287',
        StateTitle: 'Burgas',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '288',
        StateTitle: 'Dobrich',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '289',
        StateTitle: 'Gabrovo',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '290',
        StateTitle: 'Grad Sofiya',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '291',
        StateTitle: 'Khaskovo',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '292',
        StateTitle: 'Kurdzhali',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '293',
        StateTitle: 'Kyustendil',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '294',
        StateTitle: 'Lovech',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '295',
        StateTitle: 'Montana',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '296',
        StateTitle: 'Pazardzhik',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '297',
        StateTitle: 'Pernik',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '298',
        StateTitle: 'Pleven',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '299',
        StateTitle: 'Plovdiv',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '300',
        StateTitle: 'Razgrad',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '301',
        StateTitle: 'Ruse',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '302',
        StateTitle: 'Shumen',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '303',
        StateTitle: 'Silistra',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '304',
        StateTitle: 'Sliven',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '305',
        StateTitle: 'Smolyan',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '306',
        StateTitle: 'Sofiya',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '307',
        StateTitle: 'Stara Zagora',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '308',
        StateTitle: 'Turgovishte',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '309',
        StateTitle: 'Varna',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '310',
        StateTitle: 'Veliko Turnovo',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '311',
        StateTitle: 'Vidin',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '312',
        StateTitle: 'Vratsa',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '313',
        StateTitle: 'Yambol',
        ShortForm: 'NULL',
        CountryID: '34',
    },
    {
        ID: '314',
        StateTitle: 'Al Hadd',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '315',
        StateTitle: 'Al Manamah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '316',
        StateTitle: 'Jidd Hafs',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '317',
        StateTitle: 'Sitrah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '318',
        StateTitle: 'Al Mintaqah al Gharbiyah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '319',
        StateTitle: 'Mintaqat Juzur Hawar',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '320',
        StateTitle: 'Al Mintaqah ash Shamaliyah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '321',
        StateTitle: 'Al Mintaqah al Wusta',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '322',
        StateTitle: 'Madinat',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '323',
        StateTitle: 'Ar Rifa',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '324',
        StateTitle: 'Madinat Hamad',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '325',
        StateTitle: 'Al Muharraq',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '326',
        StateTitle: 'Al Asimah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '327',
        StateTitle: 'Al Janubiyah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '328',
        StateTitle: 'Ash Shamaliyah',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '329',
        StateTitle: 'Al Wusta',
        ShortForm: 'NULL',
        CountryID: '18',
    },
    {
        ID: '330',
        StateTitle: 'Bujumbura',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '331',
        StateTitle: 'Bubanza',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '332',
        StateTitle: 'Bururi',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '333',
        StateTitle: 'Cankuzo',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '334',
        StateTitle: 'Cibitoke',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '335',
        StateTitle: 'Gitega',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '336',
        StateTitle: 'Karuzi',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '337',
        StateTitle: 'Kayanza',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '338',
        StateTitle: 'Kirundo',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '339',
        StateTitle: 'Makamba',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '340',
        StateTitle: 'Muyinga',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '341',
        StateTitle: 'Ngozi',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '342',
        StateTitle: 'Rutana',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '343',
        StateTitle: 'Ruyigi',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '344',
        StateTitle: 'Muramvya',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '345',
        StateTitle: 'Mwaro',
        ShortForm: 'NULL',
        CountryID: '36',
    },
    {
        ID: '346',
        StateTitle: 'Alibori',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '347',
        StateTitle: 'Atakora',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '348',
        StateTitle: 'Atlanyique',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '349',
        StateTitle: 'Borgou',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '350',
        StateTitle: 'Collines',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '351',
        StateTitle: 'Kouffo',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '352',
        StateTitle: 'Donga',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '353',
        StateTitle: 'Littoral',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '354',
        StateTitle: 'Mono',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '355',
        StateTitle: 'Oueme',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '356',
        StateTitle: 'Plateau',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '357',
        StateTitle: 'Zou',
        ShortForm: 'NULL',
        CountryID: '24',
    },
    {
        ID: '358',
        StateTitle: 'Devonshire',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '359',
        StateTitle: 'Hamilton',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '360',
        StateTitle: 'Hamilton',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '361',
        StateTitle: 'Paget',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '362',
        StateTitle: 'Pembroke',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '363',
        StateTitle: 'Saint George',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '364',
        StateTitle: "Saint George's",
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '365',
        StateTitle: 'Sandys',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '366',
        StateTitle: 'Smiths',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '367',
        StateTitle: 'Southampton',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '368',
        StateTitle: 'Warwick',
        ShortForm: 'NULL',
        CountryID: '25',
    },
    {
        ID: '369',
        StateTitle: 'Alibori',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '370',
        StateTitle: 'Belait',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '371',
        StateTitle: 'Brunei and Muara',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '372',
        StateTitle: 'Temburong',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '373',
        StateTitle: 'Collines',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '374',
        StateTitle: 'Kouffo',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '375',
        StateTitle: 'Donga',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '376',
        StateTitle: 'Littoral',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '377',
        StateTitle: 'Tutong',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '378',
        StateTitle: 'Oueme',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '379',
        StateTitle: 'Plateau',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '380',
        StateTitle: 'Zou',
        ShortForm: 'NULL',
        CountryID: '33',
    },
    {
        ID: '381',
        StateTitle: 'Chuquisaca',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '382',
        StateTitle: 'Cochabamba',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '383',
        StateTitle: 'El Beni',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '384',
        StateTitle: 'La Paz',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '385',
        StateTitle: 'Oruro',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '386',
        StateTitle: 'Pando',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '387',
        StateTitle: 'Potosi',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '388',
        StateTitle: 'Santa Cruz',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '389',
        StateTitle: 'Tarija',
        ShortForm: 'NULL',
        CountryID: '27',
    },
    {
        ID: '390',
        StateTitle: 'Acre',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '391',
        StateTitle: 'Alagoas',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '392',
        StateTitle: 'Amapa',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '393',
        StateTitle: 'Amazonas',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '394',
        StateTitle: 'Bahia',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '395',
        StateTitle: 'Ceara',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '396',
        StateTitle: 'Distrito Federal',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '397',
        StateTitle: 'Espirito Santo',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '398',
        StateTitle: 'Mato Grosso do Sul',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '399',
        StateTitle: 'Maranhao',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '400',
        StateTitle: 'Mato Grosso',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '401',
        StateTitle: 'Minas Gerais',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '402',
        StateTitle: 'Para',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '403',
        StateTitle: 'Paraiba',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '404',
        StateTitle: 'Parana',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '405',
        StateTitle: 'Piaui',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '406',
        StateTitle: 'Rio de Janeiro',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '407',
        StateTitle: 'Rio Grande do Norte',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '408',
        StateTitle: 'Rio Grande do Sul',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '409',
        StateTitle: 'Rondonia',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '410',
        StateTitle: 'Roraima',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '411',
        StateTitle: 'Santa Catarina',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '412',
        StateTitle: 'Sao Paulo',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '413',
        StateTitle: 'Sergipe',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '414',
        StateTitle: 'Goias',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '415',
        StateTitle: 'Pernambuco',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '416',
        StateTitle: 'Tocantins',
        ShortForm: 'NULL',
        CountryID: '31',
    },
    {
        ID: '417',
        StateTitle: 'Bimini',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '418',
        StateTitle: 'Cat Island',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '419',
        StateTitle: 'Exuma',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '420',
        StateTitle: 'Inagua',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '421',
        StateTitle: 'Long Island',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '422',
        StateTitle: 'Mayaguana',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '423',
        StateTitle: 'Ragged Island',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '424',
        StateTitle: 'Harbour Island',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '425',
        StateTitle: 'New Providence',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '426',
        StateTitle: 'Acklins and Crooked Islands',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '427',
        StateTitle: 'Freeport',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '428',
        StateTitle: 'Fresh Creek',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '429',
        StateTitle: "Governor's Harbour",
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '430',
        StateTitle: 'Green Turtle Cay',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '431',
        StateTitle: 'High Rock',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '432',
        StateTitle: 'Kemps Bay',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '433',
        StateTitle: 'Marsh Harbour',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '434',
        StateTitle: 'Nichollstown and Berry Islands',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '435',
        StateTitle: 'Rock Sound',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '436',
        StateTitle: 'Sandy Point',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '437',
        StateTitle: 'San Salvador and Rum Cay',
        ShortForm: 'NULL',
        CountryID: '17',
    },
    {
        ID: '438',
        StateTitle: 'Bumthang',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '439',
        StateTitle: 'Chhukha',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '440',
        StateTitle: 'Chirang',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '441',
        StateTitle: 'Daga',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '442',
        StateTitle: 'Geylegphug',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '443',
        StateTitle: 'Ha',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '444',
        StateTitle: 'Lhuntshi',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '445',
        StateTitle: 'Mongar',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '446',
        StateTitle: 'Paro',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '447',
        StateTitle: 'Pemagatsel',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '448',
        StateTitle: 'Punakha',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '449',
        StateTitle: 'Samchi',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '450',
        StateTitle: 'Samdrup',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '451',
        StateTitle: 'Shemgang',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '452',
        StateTitle: 'Tashigang',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '453',
        StateTitle: 'Thimphu',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '454',
        StateTitle: 'Tongsa',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '455',
        StateTitle: 'Wangdi Phodrang',
        ShortForm: 'NULL',
        CountryID: '26',
    },
    {
        ID: '456',
        StateTitle: 'Central',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '457',
        StateTitle: 'Ghanzi',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '458',
        StateTitle: 'Kgalagadi',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '459',
        StateTitle: 'Kgatleng',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '460',
        StateTitle: 'Kweneng',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '461',
        StateTitle: 'North-East',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '462',
        StateTitle: 'South-East',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '463',
        StateTitle: 'Southern',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '464',
        StateTitle: 'North-West',
        ShortForm: 'NULL',
        CountryID: '29',
    },
    {
        ID: '465',
        StateTitle: "Brestskaya Voblasts'",
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '466',
        StateTitle: "Homyel'skaya Voblasts'",
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '467',
        StateTitle: "Hrodzyenskaya Voblasts'",
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '468',
        StateTitle: 'Minsk',
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '469',
        StateTitle: "Minskaya Voblasts'",
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '470',
        StateTitle: "Mahilyowskaya Voblasts'",
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '471',
        StateTitle: "Vitsyebskaya Voblasts'",
        ShortForm: 'NULL',
        CountryID: '21',
    },
    {
        ID: '472',
        StateTitle: 'Belize',
        ShortForm: 'NULL',
        CountryID: '23',
    },
    {
        ID: '473',
        StateTitle: 'Cayo',
        ShortForm: 'NULL',
        CountryID: '23',
    },
    {
        ID: '474',
        StateTitle: 'Corozal',
        ShortForm: 'NULL',
        CountryID: '23',
    },
    {
        ID: '475',
        StateTitle: 'Orange Walk',
        ShortForm: 'NULL',
        CountryID: '23',
    },
    {
        ID: '476',
        StateTitle: 'Stann Creek',
        ShortForm: 'NULL',
        CountryID: '23',
    },
    {
        ID: '477',
        StateTitle: 'Toledo',
        ShortForm: 'NULL',
        CountryID: '23',
    },
    {
        ID: '478',
        StateTitle: 'Alberta',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '479',
        StateTitle: 'British Columbia',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '480',
        StateTitle: 'Manitoba',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '481',
        StateTitle: 'New Brunswick',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '482',
        StateTitle: 'Newfoundland and Labrador',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '483',
        StateTitle: 'Nova Scotia',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '484',
        StateTitle: 'Ontario',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '485',
        StateTitle: 'Prince Edward Island',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '486',
        StateTitle: 'Quebec',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '487',
        StateTitle: 'Saskatchewan',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '488',
        StateTitle: 'Yukon Territory',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '489',
        StateTitle: 'Northwest Territories',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '490',
        StateTitle: 'Nunavut',
        ShortForm: 'NULL',
        CountryID: '39',
    },
    {
        ID: '491',
        StateTitle: 'Bandundu',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '492',
        StateTitle: 'Equateur',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '493',
        StateTitle: 'Kasai-Oriental',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '494',
        StateTitle: 'Katanga',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '495',
        StateTitle: 'Kinshasa',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '496',
        StateTitle: 'Bas-Congo',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '497',
        StateTitle: 'Orientale',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '498',
        StateTitle: 'Maniema',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '499',
        StateTitle: 'Nord-Kivu',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '500',
        StateTitle: 'Sud-Kivu',
        ShortForm: 'NULL',
        CountryID: '51',
    },
    {
        ID: '501',
        StateTitle: 'Bamingui-Bangoran',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '502',
        StateTitle: 'Basse-Kotto',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '503',
        StateTitle: 'Haute-Kotto',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '504',
        StateTitle: 'Mambere-Kadei',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '505',
        StateTitle: 'Haut-Mbomou',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '506',
        StateTitle: 'Kemo',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '507',
        StateTitle: 'Lobaye',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '508',
        StateTitle: 'Mbomou',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '509',
        StateTitle: 'Nana-Mambere',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '510',
        StateTitle: 'Ouaka',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '511',
        StateTitle: 'Ouham',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '512',
        StateTitle: 'Ouham-Pende',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '513',
        StateTitle: 'Cuvette-Ouest',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '514',
        StateTitle: 'Nana-Grebizi',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '515',
        StateTitle: 'Sangha-Mbaere',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '516',
        StateTitle: 'Ombella-Mpoko',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '517',
        StateTitle: 'Bangui',
        ShortForm: 'NULL',
        CountryID: '42',
    },
    {
        ID: '518',
        StateTitle: 'Bouenza',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '519',
        StateTitle: 'Kouilou',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '520',
        StateTitle: 'Lekoumou',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '521',
        StateTitle: 'Likouala',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '522',
        StateTitle: 'Niari',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '523',
        StateTitle: 'Plateaux',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '524',
        StateTitle: 'Sangha',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '525',
        StateTitle: 'Pool',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '526',
        StateTitle: 'Brazzaville',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '527',
        StateTitle: 'Cuvette',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '528',
        StateTitle: 'Cuvette-Ouest',
        ShortForm: 'NULL',
        CountryID: '50',
    },
    {
        ID: '529',
        StateTitle: 'Aargau',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '530',
        StateTitle: 'Ausser-Rhoden',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '531',
        StateTitle: 'Basel-Landschaft',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '532',
        StateTitle: 'Basel-Stadt',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '533',
        StateTitle: 'Bern',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '534',
        StateTitle: 'Fribourg',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '535',
        StateTitle: 'Geneve',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '536',
        StateTitle: 'Glarus',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '537',
        StateTitle: 'Graubunden',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '538',
        StateTitle: 'Inner-Rhoden',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '539',
        StateTitle: 'Luzern',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '540',
        StateTitle: 'Neuchatel',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '541',
        StateTitle: 'Nidwalden',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '542',
        StateTitle: 'Obwalden',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '543',
        StateTitle: 'Sankt Gallen',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '544',
        StateTitle: 'Schaffhausen',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '545',
        StateTitle: 'Schwyz',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '546',
        StateTitle: 'Solothurn',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '547',
        StateTitle: 'Thurgau',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '548',
        StateTitle: 'Ticino',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '549',
        StateTitle: 'Uri',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '550',
        StateTitle: 'Valais',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '551',
        StateTitle: 'Vaud',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '552',
        StateTitle: 'Zug',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '553',
        StateTitle: 'Zurich',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '554',
        StateTitle: 'Jura',
        ShortForm: 'NULL',
        CountryID: '211',
    },
    {
        ID: '555',
        StateTitle: 'Agneby',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '556',
        StateTitle: 'Bafing',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '557',
        StateTitle: 'Bas-Sassandra',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '558',
        StateTitle: 'Denguele',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '559',
        StateTitle: 'Dix-Huit Montagnes',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '560',
        StateTitle: 'Fromager',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '561',
        StateTitle: 'Haut-Sassandra',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '562',
        StateTitle: 'Lacs',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '563',
        StateTitle: 'Lagunes',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '564',
        StateTitle: 'Marahoue',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '565',
        StateTitle: 'Moyen-Cavally',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '566',
        StateTitle: 'Moyen-Comoe',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '567',
        StateTitle: "N'zi-Comoe",
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '568',
        StateTitle: 'Savanes',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '569',
        StateTitle: 'Sud-Bandama',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '570',
        StateTitle: 'Sud-Comoe',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '571',
        StateTitle: 'Vallee du Bandama',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '572',
        StateTitle: 'Worodougou',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '573',
        StateTitle: 'Zanzan',
        ShortForm: 'NULL',
        CountryID: '54',
    },
    {
        ID: '574',
        StateTitle: 'Valparaiso',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '575',
        StateTitle: 'Aisen del General Carlos Ibanez del Campo',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '576',
        StateTitle: 'Antofagasta',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '577',
        StateTitle: 'Araucania',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '578',
        StateTitle: 'Atacama',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '579',
        StateTitle: 'Bio-Bio',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '580',
        StateTitle: 'Coquimbo',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '581',
        StateTitle: "Libertador General Bernardo O'Higgins",
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '582',
        StateTitle: 'Los Lagos',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '583',
        StateTitle: 'Magallanes y de la Antartica Chilena',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '584',
        StateTitle: 'Maule',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '585',
        StateTitle: 'Region Metropolitana',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '586',
        StateTitle: 'Tarapaca',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '587',
        StateTitle: 'Los Lagos',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '588',
        StateTitle: 'Tarapaca',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '589',
        StateTitle: 'Arica y Parinacota',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '590',
        StateTitle: 'Los Rios',
        ShortForm: 'NULL',
        CountryID: '44',
    },
    {
        ID: '591',
        StateTitle: 'Est',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '592',
        StateTitle: 'Littoral',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '593',
        StateTitle: 'Nord-Ouest',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '594',
        StateTitle: 'Ouest',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '595',
        StateTitle: 'Sud-Ouest',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '596',
        StateTitle: 'Adamaoua',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '597',
        StateTitle: 'Centre',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '598',
        StateTitle: 'Extreme-Nord',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '599',
        StateTitle: 'Nord',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '600',
        StateTitle: 'Sud',
        ShortForm: 'NULL',
        CountryID: '38',
    },
    {
        ID: '601',
        StateTitle: 'Anhui',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '602',
        StateTitle: 'Zhejiang',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '603',
        StateTitle: 'Jiangxi',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '604',
        StateTitle: 'Jiangsu',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '605',
        StateTitle: 'Jilin',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '606',
        StateTitle: 'Qinghai',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '607',
        StateTitle: 'Fujian',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '608',
        StateTitle: 'Heilongjiang',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '609',
        StateTitle: 'Henan',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '610',
        StateTitle: 'Hebei',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '611',
        StateTitle: 'Hunan',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '612',
        StateTitle: 'Hubei',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '613',
        StateTitle: 'Xinjiang',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '614',
        StateTitle: 'Xizang',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '615',
        StateTitle: 'Gansu',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '616',
        StateTitle: 'Guangxi',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '617',
        StateTitle: 'Guizhou',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '618',
        StateTitle: 'Liaoning',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '619',
        StateTitle: 'Nei Mongol',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '620',
        StateTitle: 'Ningxia',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '621',
        StateTitle: 'Beijing',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '622',
        StateTitle: 'Shanghai',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '623',
        StateTitle: 'Shanxi',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '624',
        StateTitle: 'Shandong',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '625',
        StateTitle: 'Shaanxi',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '626',
        StateTitle: 'Tianjin',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '627',
        StateTitle: 'Yunnan',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '628',
        StateTitle: 'Guangdong',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '629',
        StateTitle: 'Hainan',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '630',
        StateTitle: 'Sichuan',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '631',
        StateTitle: 'Chongqing',
        ShortForm: 'NULL',
        CountryID: '45',
    },
    {
        ID: '632',
        StateTitle: 'Amazonas',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '633',
        StateTitle: 'Antioquia',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '634',
        StateTitle: 'Arauca',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '635',
        StateTitle: 'Atlantico',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '636',
        StateTitle: 'Bolivar Department',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '637',
        StateTitle: 'Boyaca Department',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '638',
        StateTitle: 'Caldas Department',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '639',
        StateTitle: 'Caqueta',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '640',
        StateTitle: 'Cauca',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '641',
        StateTitle: 'Cesar',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '642',
        StateTitle: 'Choco',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '643',
        StateTitle: 'Cordoba',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '644',
        StateTitle: 'Guaviare',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '645',
        StateTitle: 'Guainia',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '646',
        StateTitle: 'Huila',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '647',
        StateTitle: 'La Guajira',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '648',
        StateTitle: 'Magdalena Department',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '649',
        StateTitle: 'Meta',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '650',
        StateTitle: 'Narino',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '651',
        StateTitle: 'Norte de Santander',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '652',
        StateTitle: 'Putumayo',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '653',
        StateTitle: 'Quindio',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '654',
        StateTitle: 'Risaralda',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '655',
        StateTitle: 'San Andres y Providencia',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '656',
        StateTitle: 'Santander',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '657',
        StateTitle: 'Sucre',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '658',
        StateTitle: 'Tolima',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '659',
        StateTitle: 'Valle del Cauca',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '660',
        StateTitle: 'Vaupes',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '661',
        StateTitle: 'Vichada',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '662',
        StateTitle: 'Casanare',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '663',
        StateTitle: 'Cundinamarca',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '664',
        StateTitle: 'Distrito Especial',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '665',
        StateTitle: 'Bolivar',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '666',
        StateTitle: 'Boyaca',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '667',
        StateTitle: 'Caldas',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '668',
        StateTitle: 'Magdalena',
        ShortForm: 'NULL',
        CountryID: '48',
    },
    {
        ID: '669',
        StateTitle: 'Alajuela',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '670',
        StateTitle: 'Cartago',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '671',
        StateTitle: 'Guanacaste',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '672',
        StateTitle: 'Heredia',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '673',
        StateTitle: 'Limon',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '674',
        StateTitle: 'Puntarenas',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '675',
        StateTitle: 'San Jose',
        ShortForm: 'NULL',
        CountryID: '53',
    },
    {
        ID: '676',
        StateTitle: 'Pinar del Rio',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '677',
        StateTitle: 'Ciudad de la Habana',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '678',
        StateTitle: 'Matanzas',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '679',
        StateTitle: 'Isla de la Juventud',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '680',
        StateTitle: 'Camaguey',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '681',
        StateTitle: 'Ciego de Avila',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '682',
        StateTitle: 'Cienfuegos',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '683',
        StateTitle: 'Granma',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '684',
        StateTitle: 'Guantanamo',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '685',
        StateTitle: 'La Habana',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '686',
        StateTitle: 'Holguin',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '687',
        StateTitle: 'Las Tunas',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '688',
        StateTitle: 'Sancti Spiritus',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '689',
        StateTitle: 'Santiago de Cuba',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '690',
        StateTitle: 'Villa Clara',
        ShortForm: 'NULL',
        CountryID: '56',
    },
    {
        ID: '691',
        StateTitle: 'Boa Vista',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '692',
        StateTitle: 'Brava',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '693',
        StateTitle: 'Maio',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '694',
        StateTitle: 'Paul',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '695',
        StateTitle: 'Ribeira Grande',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '696',
        StateTitle: 'Sal',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '697',
        StateTitle: 'Sao Nicolau',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '698',
        StateTitle: 'Sao Vicente',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '699',
        StateTitle: 'Mosteiros',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '700',
        StateTitle: 'Praia',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '701',
        StateTitle: 'Santa Catarina',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '702',
        StateTitle: 'Santa Cruz',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '703',
        StateTitle: 'Sao Domingos',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '704',
        StateTitle: 'Sao Filipe',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '705',
        StateTitle: 'Sao Miguel',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '706',
        StateTitle: 'Tarrafal',
        ShortForm: 'NULL',
        CountryID: '40',
    },
    {
        ID: '707',
        StateTitle: 'Famagusta',
        ShortForm: 'NULL',
        CountryID: '57',
    },
    {
        ID: '708',
        StateTitle: 'Kyrenia',
        ShortForm: 'NULL',
        CountryID: '57',
    },
    {
        ID: '709',
        StateTitle: 'Larnaca',
        ShortForm: 'NULL',
        CountryID: '57',
    },
    {
        ID: '710',
        StateTitle: 'Nicosia',
        ShortForm: 'NULL',
        CountryID: '57',
    },
    {
        ID: '711',
        StateTitle: 'Limassol',
        ShortForm: 'NULL',
        CountryID: '57',
    },
    {
        ID: '712',
        StateTitle: 'Paphos',
        ShortForm: 'NULL',
        CountryID: '57',
    },
    {
        ID: '713',
        StateTitle: 'Hlavni mesto Praha',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '714',
        StateTitle: 'Jihomoravsky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '715',
        StateTitle: 'Jihocesky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '716',
        StateTitle: 'Vysocina',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '717',
        StateTitle: 'Karlovarsky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '718',
        StateTitle: 'Kralovehradecky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '719',
        StateTitle: 'Liberecky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '720',
        StateTitle: 'Olomoucky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '721',
        StateTitle: 'Moravskoslezsky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '722',
        StateTitle: 'Pardubicky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '723',
        StateTitle: 'Plzensky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '724',
        StateTitle: 'Stredocesky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '725',
        StateTitle: 'Ustecky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '726',
        StateTitle: 'Zlinsky kraj',
        ShortForm: 'NULL',
        CountryID: '58',
    },
    {
        ID: '727',
        StateTitle: 'Baden-Wurttemberg',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '728',
        StateTitle: 'Bayern',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '729',
        StateTitle: 'Bremen',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '730',
        StateTitle: 'Hamburg',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '731',
        StateTitle: 'Hessen',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '732',
        StateTitle: 'Niedersachsen',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '733',
        StateTitle: 'Nordrhein-Westfalen',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '734',
        StateTitle: 'Rheinland-Pfalz',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '735',
        StateTitle: 'Saarland',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '736',
        StateTitle: 'Schleswig-Holstein',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '737',
        StateTitle: 'Brandenburg',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '738',
        StateTitle: 'Mecklenburg-Vorpommern',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '739',
        StateTitle: 'Sachsen',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '740',
        StateTitle: 'Sachsen-Anhalt',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '741',
        StateTitle: 'Thuringen',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '742',
        StateTitle: 'Berlin',
        ShortForm: 'NULL',
        CountryID: '81',
    },
    {
        ID: '743',
        StateTitle: 'Ali Sabieh',
        ShortForm: 'NULL',
        CountryID: '60',
    },
    {
        ID: '744',
        StateTitle: 'Obock',
        ShortForm: 'NULL',
        CountryID: '60',
    },
    {
        ID: '745',
        StateTitle: 'Tadjoura',
        ShortForm: 'NULL',
        CountryID: '60',
    },
    {
        ID: '746',
        StateTitle: 'Dikhil',
        ShortForm: 'NULL',
        CountryID: '60',
    },
    {
        ID: '747',
        StateTitle: 'Djibouti',
        ShortForm: 'NULL',
        CountryID: '60',
    },
    {
        ID: '748',
        StateTitle: 'Arta',
        ShortForm: 'NULL',
        CountryID: '60',
    },
    {
        ID: '749',
        StateTitle: 'Hovedstaden',
        ShortForm: 'NULL',
        CountryID: '59',
    },
    {
        ID: '750',
        StateTitle: 'Midtjylland',
        ShortForm: 'NULL',
        CountryID: '59',
    },
    {
        ID: '751',
        StateTitle: 'Nordjylland',
        ShortForm: 'NULL',
        CountryID: '59',
    },
    {
        ID: '752',
        StateTitle: 'Sjelland',
        ShortForm: 'NULL',
        CountryID: '59',
    },
    {
        ID: '753',
        StateTitle: 'Syddanmark',
        ShortForm: 'NULL',
        CountryID: '59',
    },
    {
        ID: '754',
        StateTitle: 'Saint Andrew',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '755',
        StateTitle: 'Saint David',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '756',
        StateTitle: 'Saint George',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '757',
        StateTitle: 'Saint John',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '758',
        StateTitle: 'Saint Joseph',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '759',
        StateTitle: 'Saint Luke',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '760',
        StateTitle: 'Saint Mark',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '761',
        StateTitle: 'Saint Patrick',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '762',
        StateTitle: 'Saint Paul',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '763',
        StateTitle: 'Saint Peter',
        ShortForm: 'NULL',
        CountryID: '61',
    },
    {
        ID: '764',
        StateTitle: 'Azua',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '765',
        StateTitle: 'Baoruco',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '766',
        StateTitle: 'Barahona',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '767',
        StateTitle: 'Dajabon',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '768',
        StateTitle: 'Distrito Nacional',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '769',
        StateTitle: 'Duarte',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '770',
        StateTitle: 'Espaillat',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '771',
        StateTitle: 'Independencia',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '772',
        StateTitle: 'La Altagracia',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '773',
        StateTitle: 'Elias Pina',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '774',
        StateTitle: 'La Romana',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '775',
        StateTitle: 'Maria Trinidad Sanchez',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '776',
        StateTitle: 'Monte Cristi',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '777',
        StateTitle: 'Pedernales',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '778',
        StateTitle: 'Peravia',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '779',
        StateTitle: 'Puerto Plata',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '780',
        StateTitle: 'Salcedo',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '781',
        StateTitle: 'Samana',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '782',
        StateTitle: 'Sanchez Ramirez',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '783',
        StateTitle: 'San Juan',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '784',
        StateTitle: 'San Pedro De Macoris',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '785',
        StateTitle: 'Santiago',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '786',
        StateTitle: 'Santiago Rodriguez',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '787',
        StateTitle: 'Valverde',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '788',
        StateTitle: 'El Seibo',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '789',
        StateTitle: 'Hato Mayor',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '790',
        StateTitle: 'La Vega',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '791',
        StateTitle: 'Monsenor Nouel',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '792',
        StateTitle: 'Monte Plata',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '793',
        StateTitle: 'San Cristobal',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '794',
        StateTitle: 'Distrito Nacional',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '795',
        StateTitle: 'Peravia',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '796',
        StateTitle: 'San Jose de Ocoa',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '797',
        StateTitle: 'Santo Domingo',
        ShortForm: 'NULL',
        CountryID: '62',
    },
    {
        ID: '798',
        StateTitle: 'Alger',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '799',
        StateTitle: 'Batna',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '800',
        StateTitle: 'Constantine',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '801',
        StateTitle: 'Medea',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '802',
        StateTitle: 'Mostaganem',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '803',
        StateTitle: 'Oran',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '804',
        StateTitle: 'Saida',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '805',
        StateTitle: 'Setif',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '806',
        StateTitle: 'Tiaret',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '807',
        StateTitle: 'Tizi Ouzou',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '808',
        StateTitle: 'Tlemcen',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '809',
        StateTitle: 'Bejaia',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '810',
        StateTitle: 'Biskra',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '811',
        StateTitle: 'Blida',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '812',
        StateTitle: 'Bouira',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '813',
        StateTitle: 'Djelfa',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '814',
        StateTitle: 'Guelma',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '815',
        StateTitle: 'Jijel',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '816',
        StateTitle: 'Laghouat',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '817',
        StateTitle: 'Mascara',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '818',
        StateTitle: "M'sila",
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '819',
        StateTitle: 'Oum el Bouaghi',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '820',
        StateTitle: 'Sidi Bel Abbes',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '821',
        StateTitle: 'Skikda',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '822',
        StateTitle: 'Tebessa',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '823',
        StateTitle: 'Adrar',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '824',
        StateTitle: 'Ain Defla',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '825',
        StateTitle: 'Ain Temouchent',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '826',
        StateTitle: 'Annaba',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '827',
        StateTitle: 'Bechar',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '828',
        StateTitle: 'Bordj Bou Arreridj',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '829',
        StateTitle: 'Boumerdes',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '830',
        StateTitle: 'Chlef',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '831',
        StateTitle: 'El Bayadh',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '832',
        StateTitle: 'El Oued',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '833',
        StateTitle: 'El Tarf',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '834',
        StateTitle: 'Ghardaia',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '835',
        StateTitle: 'Illizi',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '836',
        StateTitle: 'Khenchela',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '837',
        StateTitle: 'Mila',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '838',
        StateTitle: 'Naama',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '839',
        StateTitle: 'Ouargla',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '840',
        StateTitle: 'Relizane',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '841',
        StateTitle: 'Souk Ahras',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '842',
        StateTitle: 'Tamanghasset',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '843',
        StateTitle: 'Tindouf',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '844',
        StateTitle: 'Tipaza',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '845',
        StateTitle: 'Tissemsilt',
        ShortForm: 'NULL',
        CountryID: '4',
    },
    {
        ID: '846',
        StateTitle: 'Galapagos',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '847',
        StateTitle: 'Azuay',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '848',
        StateTitle: 'Bolivar',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '849',
        StateTitle: 'Canar',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '850',
        StateTitle: 'Carchi',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '851',
        StateTitle: 'Chimborazo',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '852',
        StateTitle: 'Cotopaxi',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '853',
        StateTitle: 'El Oro',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '854',
        StateTitle: 'Esmeraldas',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '855',
        StateTitle: 'Guayas',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '856',
        StateTitle: 'Imbabura',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '857',
        StateTitle: 'Loja',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '858',
        StateTitle: 'Los Rios',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '859',
        StateTitle: 'Manabi',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '860',
        StateTitle: 'Morona-Santiago',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '861',
        StateTitle: 'Pastaza',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '862',
        StateTitle: 'Pichincha',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '863',
        StateTitle: 'Tungurahua',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '864',
        StateTitle: 'Zamora-Chinchipe',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '865',
        StateTitle: 'Sucumbios',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '866',
        StateTitle: 'Napo',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '867',
        StateTitle: 'Orellana',
        ShortForm: 'NULL',
        CountryID: '63',
    },
    {
        ID: '868',
        StateTitle: 'Harjumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '869',
        StateTitle: 'Hiiumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '870',
        StateTitle: 'Ida-Virumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '871',
        StateTitle: 'Jarvamaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '872',
        StateTitle: 'Jogevamaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '873',
        StateTitle: 'Kohtla-Jarve',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '874',
        StateTitle: 'Laanemaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '875',
        StateTitle: 'Laane-Virumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '876',
        StateTitle: 'Narva',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '877',
        StateTitle: 'Parnu',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '878',
        StateTitle: 'Parnumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '879',
        StateTitle: 'Polvamaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '880',
        StateTitle: 'Raplamaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '881',
        StateTitle: 'Saaremaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '882',
        StateTitle: 'Sillamae',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '883',
        StateTitle: 'Tallinn',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '884',
        StateTitle: 'Tartu',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '885',
        StateTitle: 'Tartumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '886',
        StateTitle: 'Valgamaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '887',
        StateTitle: 'Viljandimaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '888',
        StateTitle: 'Vorumaa',
        ShortForm: 'NULL',
        CountryID: '68',
    },
    {
        ID: '889',
        StateTitle: 'Ad Daqahliyah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '890',
        StateTitle: 'Al Bahr al Ahmar',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '891',
        StateTitle: 'Al Buhayrah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '892',
        StateTitle: 'Al Fayyum',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '893',
        StateTitle: 'Al Gharbiyah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '894',
        StateTitle: 'Al Iskandariyah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '895',
        StateTitle: "Al Isma'iliyah",
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '896',
        StateTitle: 'Al Jizah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '897',
        StateTitle: 'Al Minufiyah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '898',
        StateTitle: 'Al Minya',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '899',
        StateTitle: 'Al Qahirah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '900',
        StateTitle: 'Al Qalyubiyah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '901',
        StateTitle: 'Al Wadi al Jadid',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '902',
        StateTitle: 'Ash Sharqiyah',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '903',
        StateTitle: 'As Suways',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '904',
        StateTitle: 'Aswan',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '905',
        StateTitle: 'Asyut',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '906',
        StateTitle: 'Bani Suwayf',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '907',
        StateTitle: "Bur Sa'id",
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '908',
        StateTitle: 'Dumyat',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '909',
        StateTitle: 'Kafr ash Shaykh',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '910',
        StateTitle: 'Matruh',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '911',
        StateTitle: 'Qina',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '912',
        StateTitle: 'Suhaj',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '913',
        StateTitle: 'Janub Sina',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '914',
        StateTitle: 'Shamal Sina',
        ShortForm: 'NULL',
        CountryID: '64',
    },
    {
        ID: '915',
        StateTitle: 'Anseba',
        ShortForm: 'NULL',
        CountryID: '67',
    },
    {
        ID: '916',
        StateTitle: 'Debub',
        ShortForm: 'NULL',
        CountryID: '67',
    },
    {
        ID: '917',
        StateTitle: 'Debubawi Keyih Bahri',
        ShortForm: 'NULL',
        CountryID: '67',
    },
    {
        ID: '918',
        StateTitle: 'Gash Barka',
        ShortForm: 'NULL',
        CountryID: '67',
    },
    {
        ID: '919',
        StateTitle: "Ma'akel",
        ShortForm: 'NULL',
        CountryID: '67',
    },
    {
        ID: '920',
        StateTitle: "Semenawi K'eyih Bahri",
        ShortForm: 'NULL',
        CountryID: '67',
    },
    {
        ID: '921',
        StateTitle: 'Islas Baleares',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '922',
        StateTitle: 'La Rioja',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '923',
        StateTitle: 'Madrid',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '924',
        StateTitle: 'Murcia',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '925',
        StateTitle: 'Navarra',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '926',
        StateTitle: 'Asturias',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '927',
        StateTitle: 'Cantabria',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '928',
        StateTitle: 'Andalucia',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '929',
        StateTitle: 'Aragon',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '930',
        StateTitle: 'Canarias',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '931',
        StateTitle: 'Castilla-La Mancha',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '932',
        StateTitle: 'Castilla y Leon',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '933',
        StateTitle: 'Catalonia',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '934',
        StateTitle: 'Extremadura',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '935',
        StateTitle: 'Galicia',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '936',
        StateTitle: 'Pais Vasco',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '937',
        StateTitle: 'Comunidad Valenciana',
        ShortForm: 'NULL',
        CountryID: '204',
    },
    {
        ID: '938',
        StateTitle: 'Adis Abeba',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '939',
        StateTitle: 'Afar',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '940',
        StateTitle: 'Amara',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '941',
        StateTitle: 'Binshangul Gumuz',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '942',
        StateTitle: 'Dire Dawa',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '943',
        StateTitle: 'Gambela Hizboch',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '944',
        StateTitle: 'Hareri Hizb',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '945',
        StateTitle: 'Oromiya',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '946',
        StateTitle: 'Sumale',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '947',
        StateTitle: 'Tigray',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '948',
        StateTitle: 'YeDebub Biheroch Bihereseboch na Hizboch',
        ShortForm: 'NULL',
        CountryID: '69',
    },
    {
        ID: '949',
        StateTitle: 'Aland',
        ShortForm: 'NULL',
        CountryID: '73',
    },
    {
        ID: '950',
        StateTitle: 'Lapland',
        ShortForm: 'NULL',
        CountryID: '73',
    },
    {
        ID: '951',
        StateTitle: 'Oulu',
        ShortForm: 'NULL',
        CountryID: '73',
    },
    {
        ID: '952',
        StateTitle: 'Southern Finland',
        ShortForm: 'NULL',
        CountryID: '73',
    },
    {
        ID: '953',
        StateTitle: 'Eastern Finland',
        ShortForm: 'NULL',
        CountryID: '73',
    },
    {
        ID: '954',
        StateTitle: 'Western Finland',
        ShortForm: 'NULL',
        CountryID: '73',
    },
    {
        ID: '955',
        StateTitle: 'Central',
        ShortForm: 'NULL',
        CountryID: '72',
    },
    {
        ID: '956',
        StateTitle: 'Eastern',
        ShortForm: 'NULL',
        CountryID: '72',
    },
    {
        ID: '957',
        StateTitle: 'Northern',
        ShortForm: 'NULL',
        CountryID: '72',
    },
    {
        ID: '958',
        StateTitle: 'Rotuma',
        ShortForm: 'NULL',
        CountryID: '72',
    },
    {
        ID: '959',
        StateTitle: 'Western',
        ShortForm: 'NULL',
        CountryID: '72',
    },
    {
        ID: '960',
        StateTitle: 'Kosrae',
        ShortForm: 'NULL',
        CountryID: '143',
    },
    {
        ID: '961',
        StateTitle: 'Pohnpei',
        ShortForm: 'NULL',
        CountryID: '143',
    },
    {
        ID: '962',
        StateTitle: 'Chuuk',
        ShortForm: 'NULL',
        CountryID: '143',
    },
    {
        ID: '963',
        StateTitle: 'Yap',
        ShortForm: 'NULL',
        CountryID: '143',
    },
    {
        ID: '964',
        StateTitle: 'Aquitaine',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '965',
        StateTitle: 'Auvergne',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '966',
        StateTitle: 'Basse-Normandie',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '967',
        StateTitle: 'Bourgogne',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '968',
        StateTitle: 'Bretagne',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '969',
        StateTitle: 'Centre',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '970',
        StateTitle: 'Champagne-Ardenne',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '971',
        StateTitle: 'Corse',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '972',
        StateTitle: 'Franche-Comte',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '973',
        StateTitle: 'Haute-Normandie',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '974',
        StateTitle: 'Ile-de-France',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '975',
        StateTitle: 'Languedoc-Roussillon',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '976',
        StateTitle: 'Limousin',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '977',
        StateTitle: 'Lorraine',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '978',
        StateTitle: 'Midi-Pyrenees',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '979',
        StateTitle: 'Nord-Pas-de-Calais',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '980',
        StateTitle: 'Pays de la Loire',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '981',
        StateTitle: 'Picardie',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '982',
        StateTitle: 'Poitou-Charentes',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '983',
        StateTitle: "Provence-Alpes-Cote d'Azur",
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '984',
        StateTitle: 'Rhone-Alpes',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '985',
        StateTitle: 'Alsace',
        ShortForm: 'NULL',
        CountryID: '74',
    },
    {
        ID: '986',
        StateTitle: 'Estuaire',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '987',
        StateTitle: 'Haut-Ogooue',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '988',
        StateTitle: 'Moyen-Ogooue',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '989',
        StateTitle: 'Ngounie',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '990',
        StateTitle: 'Nyanga',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '991',
        StateTitle: 'Ogooue-Ivindo',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '992',
        StateTitle: 'Ogooue-Lolo',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '993',
        StateTitle: 'Ogooue-Maritime',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '994',
        StateTitle: 'Woleu-Ntem',
        ShortForm: 'NULL',
        CountryID: '78',
    },
    {
        ID: '995',
        StateTitle: 'Barking and Dagenham',
        ShortForm: 'NULL',
        CountryID: '230',
    },
    {
        ID: '996',
        StateTitle: 'Barnet',
        ShortForm: 'NULL',
        CountryID: '230',
    },
    {
        ID: '997',
        StateTitle: 'Barnsley',
        ShortForm: 'NULL',
        CountryID: '230',
    },
    {
        ID: '998',
        StateTitle: 'Bath and North East Somerset',
        ShortForm: 'NULL',
        CountryID: '230',
    },
    {
        ID: '999',
        StateTitle: 'Bedfordshire',
        ShortForm: 'NULL',
        CountryID: '230',
    },
    {
        ID: '1000',
        StateTitle: 'Bexley',
        ShortForm: 'NULL',
        CountryID: '230',
    },
    {
        ID: '1001',
        StateTitle: 'Alabama',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1002',
        StateTitle: 'Alaska',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1003',
        StateTitle: 'Arizona',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1004',
        StateTitle: 'Arkansas',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1005',
        StateTitle: 'California',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1006',
        StateTitle: 'Colorado',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1007',
        StateTitle: 'Connecticut',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1008',
        StateTitle: 'Delaware',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1009',
        StateTitle: 'District of Columbia',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1010',
        StateTitle: 'Florida',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1011',
        StateTitle: 'Georgia',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1012',
        StateTitle: 'Hawaii',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1013',
        StateTitle: 'Idaho',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1014',
        StateTitle: 'Illinois',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1015',
        StateTitle: 'Indiana',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1016',
        StateTitle: 'Iowa',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1017',
        StateTitle: 'Kansas',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1018',
        StateTitle: 'Kentucky',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1019',
        StateTitle: 'Louisiana',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1020',
        StateTitle: 'Maine',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1021',
        StateTitle: 'Maryland',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1022',
        StateTitle: 'Massachusetts',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1023',
        StateTitle: 'Michigan',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1024',
        StateTitle: 'Minnesota',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1025',
        StateTitle: 'Mississippi',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1026',
        StateTitle: 'Missouri',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1027',
        StateTitle: 'Montana',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1028',
        StateTitle: 'Nebraska',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1029',
        StateTitle: 'Nevada',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1030',
        StateTitle: 'New Hampshire',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1031',
        StateTitle: 'New Jersey',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1032',
        StateTitle: 'New Mexico',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1033',
        StateTitle: 'New York',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1034',
        StateTitle: 'North Carolina',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1035',
        StateTitle: 'North Dakota',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1036',
        StateTitle: 'Ohio',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1037',
        StateTitle: 'Oklahoma',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1038',
        StateTitle: 'Oregon',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1039',
        StateTitle: 'Pennsylvania',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1040',
        StateTitle: 'Rhode Island',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1041',
        StateTitle: 'South Carolina',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1042',
        StateTitle: 'South Dakota',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1043',
        StateTitle: 'Tennessee',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1044',
        StateTitle: 'Texas',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1045',
        StateTitle: 'Utah',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1046',
        StateTitle: 'Vermont',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1047',
        StateTitle: 'Virginia',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1048',
        StateTitle: 'West Virginia',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1049',
        StateTitle: 'Wisconsin',
        ShortForm: 'NULL',
        CountryID: '231',
    },
    {
        ID: '1050',
        StateTitle: 'Wyoming',
        ShortForm: 'NULL',
        CountryID: '231',
    },
];

import { call, put, takeLatest } from 'redux-saga/effects';
import { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import { AnyAction } from 'redux';
import {
    setDefaultPaymentsMethod,
    listPaymentsMethods,
    getDefaultPaymentsMethod,
    listPaymentIntents,
    updatePaymentIntent,
} from './apis';
import {
    GET_DEFAULT_PAYMENT_METHOD,
    SET_DEFAULT_PAYMENT_METHOD,
    LIST_PAYMENT_METHOD,
    LIST_PAYMENT_INTENT,
    SET_CUSTOMER_TO_PAYMENT_INTENT,
} from './types';
import {
    getDefaultPaymentMethodFailedAction,
    getDefaultPaymentMethodSuccessAction,
    listPaymentIntentFailedAction,
    listPaymentIntentSuccessAction,
    listPaymentMethodFailedAction,
    listPaymentMethodSuccessAction,
    paymentLoadingAction,
    setDefaultPaymentMethodFailedAction,
    setDefaultPaymentMethodSuccessAction,
    setCustomerToPaymentIntentSuccessAction,
    setCustomerToPaymentIntentFailedAction,
} from './actions';
import { updatePaymentsFromStripe } from '../user/apis';
import { updateUserSuccessAction } from '../user/actions';
import { UserType } from '../user/types';

function* setCustomerToPayimentIntentSaga(action: AnyAction) {
    try {
        yield put(paymentLoadingAction(true));
        const response: { paymentIntent: PaymentIntent } = yield call(
            updatePaymentIntent,
            action.payload,
        );
        yield put(paymentLoadingAction(false));
        yield put(setCustomerToPaymentIntentSuccessAction(response));
    } catch (error) {
        yield put(setCustomerToPaymentIntentFailedAction(error));
    }
}

export function* setCustomerToPayimentIntentWatcher() {
    yield takeLatest(SET_CUSTOMER_TO_PAYMENT_INTENT, setCustomerToPayimentIntentSaga);
}

function* setDefaultPaymentMethodSaga(action: AnyAction) {
    try {
        yield put(paymentLoadingAction(true));
        const response: { paymentMethod: PaymentMethod } = yield call(
            setDefaultPaymentsMethod,
            action.payload,
        );
        yield put(paymentLoadingAction(false));
        yield put(setDefaultPaymentMethodSuccessAction(response));
    } catch (error) {
        yield put(setDefaultPaymentMethodFailedAction(error));
    }
}

export function* setDefaultPaymentMethodWatcher() {
    yield takeLatest(SET_DEFAULT_PAYMENT_METHOD, setDefaultPaymentMethodSaga);
}

function* getDefaultPaymentMethodSaga(action: AnyAction) {
    try {
        yield put(paymentLoadingAction(true));
        const response: { paymentMethod: PaymentMethod } = yield call(
            getDefaultPaymentsMethod,
            action.payload,
        );
        yield put(paymentLoadingAction(false));
        yield put(getDefaultPaymentMethodSuccessAction(response));
    } catch (error) {
        yield put(getDefaultPaymentMethodFailedAction(error));
    }
}

export function* getDefaultPaymentMethodWatcher() {
    yield takeLatest(GET_DEFAULT_PAYMENT_METHOD, getDefaultPaymentMethodSaga);
}

function* fetchPaymentMethodsListSaga(action: AnyAction) {
    try {
        yield put(paymentLoadingAction(true));
        const response: { paymentMethods: PaymentMethod[] } = yield call(
            listPaymentsMethods,
            action.payload.customerID,
        );
        // console.log('fetchPaymentMethodsListSaga response: ', response);
        yield put(listPaymentMethodSuccessAction(response));
        yield put(paymentLoadingAction(false));
    } catch (error) {
        yield put(listPaymentMethodFailedAction(error));
    }
}

export function* listPaymentMethodWatcher() {
    yield takeLatest(LIST_PAYMENT_METHOD, fetchPaymentMethodsListSaga);
}

function* fetchPaymentIntentsSaga(action: AnyAction) {
    try {
        yield put(paymentLoadingAction(true));
        // console.log('fetchPaymentIntentsSaga...');
        const response: { paymentIntents: PaymentIntent[] } = yield call(
            listPaymentIntents,
            action.payload.customerID,
        );
        // console.log('saga paymentlist: ', response);
        const user: UserType = yield call(updatePaymentsFromStripe, response.paymentIntents);
        if (user) yield put(updateUserSuccessAction(user));
        yield put(listPaymentIntentSuccessAction(response));
        yield put(paymentLoadingAction(false));
    } catch (error) {
        yield put(listPaymentIntentFailedAction(error));
    }
}

export function* listPaymentIntentWatcher() {
    yield takeLatest(LIST_PAYMENT_INTENT, fetchPaymentIntentsSaga);
}

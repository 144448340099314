import { PaymentType } from '../payments/types';
import { SubscriptionType } from '../subscription/types';

// Error type
export interface ErrorType {
    code?: number;
    message?: string;
    name?: string;
}

// Crenditals type
export interface Credentials {
    sub: string;
    email_verified: boolean;
    name: string;
    email: string;
}

// user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const REMEMBER_CREDENTIALS = 'REMEMBER_CREDENTIALS';
export const USER_CHANGE = 'USER_CHANGE';

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export interface Code {
    status: string;
    code: string;
}

export interface UserType {
    authenticated?: string;
    signing?: boolean;
    remember?: boolean;
    loading?: boolean;
    errors?: Error;
    sub: string;
    email: string;
    name: string;
    userLanguage?: string;
    changePassword?: ChangePasswordType;
    subscription?: SubscriptionType;
    payment?: PaymentType;
    avatar?: string;
    hasAvatar?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    file?: any;
    stripeCustomerID?: string;
    paymentFlow?: string;
    code?: Code;
    defaultPaymentMethodID?: string;
}

export const SHOW_INFO = 'SHOW_INFO';
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE_PASSWORD_RESPONSE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_LOADER = 'CHANGE_PASSWORD_LOADER';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export interface Error {
    message: string;
}

export interface ChangePasswordType {
    type?: string;
    error?: ErrorType | null;
    success?: string | null;
    loading?: boolean;
}

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCEED = 'UPDATE_USER_SUCCEED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const USER_KEY = 'USER_KEY';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCEEDED = 'GET_USER_SUCCEEDED';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const UPDATE_PAYMENT_FLOW_STATUS = 'UPDATE_PAYMENT_FLOW_STATUS';
export const UPDATE_PAYMENT_FLOW_STATUS_SUCCESS = 'UPDATE_PAYMENT_FLOW_STATUS_SUCCESS';
export const UPDATE_PAYMENT_FLOW_STATUS_FAILED = 'UPDATE_PAYMENT_FLOW_STATUS_FAILED';

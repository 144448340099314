import React from 'react';
import ReactPlayer from 'react-player';

interface BackgroundComponenteType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mediaFile: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaReady: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPause: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleMediaPlay: any;
    playing: boolean;
}

const Background = ({
    handleError,
    mediaFile,
    playing,
    handleMediaReady,
    handleMediaPause,
    handleMediaPlay,
}: BackgroundComponenteType) => {
    const onReady = () => {
        // eslint-disable-next-line no-console
        console.log('background ready to play...');
        handleMediaReady('background');
    };

    return (
        <>
            {mediaFile && (
                <ReactPlayer
                    loop
                    url={mediaFile.uri}
                    playing={playing}
                    onReady={onReady}
                    onPlay={() => {
                        // eslint-disable-next-line no-console
                        console.log('background on play..');
                        handleMediaPlay();
                    }}
                    playsinline
                    height={0}
                    style={{ zIndex: -1, position: 'absolute' }}
                    onPause={() => {
                        // eslint-disable-next-line no-console
                        console.log('background paused ...');
                        handleMediaPause();
                    }}
                    onError={(e) => handleError(e)}
                />
            )}
        </>
    );
};
export default Background;

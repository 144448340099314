import { getItem } from '../../utils/localStorage';
import {
    ActionType,
    GET_PRODUCT_SUCCEEDED,
    GET_PRODUCT_FAILED,
    ProductType,
    ErrorType,
    GET_PRODUCT,
    LIST_PRODUCT_SUCCEEDED,
    LIST_PRODUCT_FAILED,
    LOADING_PRODUCT,
} from './types';

let product = getItem('product');
product = Array.isArray(product) ? product : [];

// product = [];

const initialState: { data: ProductType[]; error: ErrorType } = {
    data: [...product],
    error: {},
};

export default function reducer(state = initialState, action: ActionType) {
    switch (action.type) {
        case GET_PRODUCT:
            if (!action.payload?.product)
                return {
                    ...state,
                };
            return {
                ...state,
                data: action.payload.product,
            };
        case GET_PRODUCT_SUCCEEDED:
            if (!action.payload?.product)
                return {
                    ...state,
                };
            return {
                ...state,
                data: [action.payload.product],
            };
        case GET_PRODUCT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case LIST_PRODUCT_SUCCEEDED:
            return {
                ...state,
                data: action.payload?.products,
                error: {},
            };

        case LIST_PRODUCT_FAILED:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            };
        case LOADING_PRODUCT:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
}

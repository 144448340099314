import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    TextField,
    useMediaQuery,
    useTheme,
    InputAdornment,
    CircularProgress,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStateType } from '../../redux/rootTypes';
import { changePasswordAction, changePasswordError, clearMessages } from '../../redux/user/actions';
import useOnlineDispatch from '../OnlineDispatch';

const useStyles = makeStyles((theme) => ({
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
        height: '49px',
        fontSize: '16px',
        width: '340px',
        '& #password': {
            color: '#000 !important ',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },

    label: {
        color: 'inherit',
        marginTop: theme.spacing(2),
        fontFamily: ['Poppins', 'regular'].join(','),
        fontSize: '17px',
        fontWeight: 400,
    },

}));

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

interface Props {
    open: boolean;
    handleCloseDialog: () => void;
}

export default function ChangeEmail({ open, handleCloseDialog }: Props) {
    const classes = useStyles();
    const [oldPassword, setOldPassword] = React.useState('');
    const [newEmail, setNewEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [errors, setError] = React.useState<string | null>(null);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    const onlineDispatch = useOnlineDispatch();
    const dispatch = useDispatch();
    const [success, setSuccess] = React.useState<string | null>(null);
    const { sub } = useSelector(
        (state: GlobalStateType) => state.user || {},
    );
    const { email } = useSelector(
        (state: GlobalStateType) => state.user || {},
    );
    /// console.log('======: ', sub)
    const handleClose = () => {
        handleCloseDialog();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (newEmail === '') {
            setError('Please enter your email address');
        } else {
            try {
                setLoading(true);
                const apiUrl = 'https://z77j2nhgo2oo4fkqkrpdpesjx40swvqt.lambda-url.us-east-1.on.aws';
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    body: JSON.stringify({
                        "sub": sub,
                        "newEmail": newEmail,
                        "username": email
                    }),
                });
                const data = await response.json();
                if (response.ok) {
                    // dispatch(changePasswordAction(data));
                    setError(null);
                    setSuccess('Success.');
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    setSuccess(null);
                    setError(data.error || 'An error occurred');
                }
            } catch (error) {
                setError('An error occurred while communicating with the server');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div>
            <Dialog
                fullScreen={isMobile}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Change Email
                </DialogTitle>
                <DialogContent dividers>
                    {errors && <Alert severity="error">{errors}</Alert>}
                    {success && (
                        <Alert severity="success">
                            Your email has been changed successfully.
                        </Alert>
                    )}
                    {loading && <CircularProgress />}
                    <form noValidate>
                        <FormGroup className={classes.form}>
                            <TextField
                                InputProps={{
                                    className: classes.input,
                                    endAdornment: '',
                                }}
                                variant="outlined"
                                margin="normal"
                                required
                                id="email"
                                placeholder="Enter your email"
                                name="Current Password"
                                autoFocus
                                onChange={(e) => setNewEmail(e.target.value)}
                                type='email'
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.submit}
                            >
                                Change Email
                            </Button>
                        </FormGroup>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { getProduct, listProducts } from './apis';
import { GET_PRODUCT, LIST_PRODUCT, ProductType } from './types';

import {
    listProductFailedAction,
    listProductSuccessAction,
    getProductSuccessAction,
    getProductFailedAction,
    productLoadingAction,
} from './actions';

function* fetchProduct() {
    try {
        yield put(productLoadingAction(true));
        const response: { product: ProductType } = yield call(getProduct);
        yield put(productLoadingAction(false));
        yield put(getProductSuccessAction(response));
    } catch (error) {
        yield put(getProductFailedAction(error));
    }
}

export function* getProductWatcher() {
    yield takeLatest(GET_PRODUCT, fetchProduct);
}

function* fetchProductListSaga() {
    try {
        yield put(productLoadingAction(true));
        const response: { products: ProductType[] } = yield call(listProducts);
        yield put(productLoadingAction(false));
        yield put(listProductSuccessAction(response));
    } catch (error) {
        yield put(listProductFailedAction(error));
    }
}

export function* listProductWatcher() {
    yield takeLatest(LIST_PRODUCT, fetchProductListSaga);
}

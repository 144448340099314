import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import useOnlineDispatch from '../OnlineDispatch';

import { ReactComponent as EditIcon } from '../../assets/svg_icons/editButton.svg';
import { GlobalStateType } from '../../redux/rootTypes';
import ChangeEmail from '../ChangeEmail';
import ChangePassword from '../ChangePassword';
import Line from '../Line';
import BillingFlow from './BillingFlow';
import { ColorCircularProgress } from '../Loader';
import BillingPending from './BillingPending';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnPadding: {
            padding: '20px 0px',
            width: '266px',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                justifyContent: 'center',
                padding: '25px',
            },
        },
        linkBtn: {
            border: 'none',
            textDecoration: 'underline',
            color: 'white',
            backgroundColor: 'transparent',
            cursor: 'pointer',
        },
        editButton: {
            width: '20px',
            height: '20px',
            fill: 'white',
            transition: '300ms',
            '&:hover': {
                fill: '#0468BF',
                cursor: 'pointer',
            },
            [theme.breakpoints.down('xs')]: {
                fill: 'white',
            },
        },
    }),
);

const StartPay = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const onlineDispatch = useOnlineDispatch();
    const [count, setCount] = useState(0);
    const {
        subscription: { loading: subscriptionLoading },
        payments: { loading: paymentLoading },
        user: { code, email, loading: userLoading, paymentFlow, sub, stripeCustomerID, subscription },
    } = useSelector((state: GlobalStateType) => state);
    useEffect(() => {
        if (subscription && subscription.subscriptionID) {
            setCount(1);
        }
    }, [subscription]);
    // console.log('+++++++++++++:', subscription);
    // console.log('333333333:', count);
    return (
        <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="flex-end"
            style={{ maxWidth: '660px' }}
        >
            {(paymentFlow === 'pending' && (
                <>
                    {(count === 1
                        ? ''
                        :
                        <>
                            <BillingPending />
                            <ColorCircularProgress />
                        </>
                    )}
                </>

            )) ||
                (subscriptionLoading || paymentLoading || userLoading ? (
                    <ColorCircularProgress />
                ) : (
                    (code && null) ||
                    (count === 1 ?
                        ''
                        :
                        <BillingFlow />
                    )

                ))}

            <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                style={{ width: '320px' }}
            >
                {(count === 1
                    ? ''
                    :
                    <Line />
                )}
                <p>
                    <strong> Email:</strong> {email}
                </p>
                <EditIcon
                    className={classes.editButton}
                    onClick={() => {
                        setOpenEmail(true);
                    }}
                />
                <ChangeEmail
                    open={openEmail}
                    handleCloseDialog={() => {
                        setOpenEmail(false);
                    }}
                />
            </Grid>
            <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                style={{ width: '300px' }}
            >
                <p>
                    <strong> Change Password:</strong>
                </p>
                <EditIcon
                    className={classes.editButton}
                    onClick={() => {
                        setOpen(true);
                    }}
                />
                <ChangePassword
                    open={open}
                    handleCloseDialog={() => {
                        setOpen(false);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default StartPay;

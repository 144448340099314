import React, { useState } from 'react';
import { Button, Container, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { SUPPORT_MAIL } from '../../constants/sendmail';
import { sendEmailAction } from '../../redux/emails/actions';
import { GlobalStateType } from '../../redux/rootTypes';
import useOnlineDispatch from '../OnlineDispatch';

const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: theme.spacing(4),
    },
    input: {
        fontFamily: ['Poppins', 'regular'].join(','),
        backgroundColor: 'white',
        disableUnderline: true,
        borderRadius: '4px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '12px',
        backgroundColor: '#00BBFF',
        textTransform: 'none',
        fontFamily: ['Poppins', 'medium'].join(','),
        fontSize: '26px',
        width: '289px',
        height: '64px',
        '&:hover': {
            backgroundColor: 'white',
            color: '#00BBFF',
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function SendMailForm() {
    const classes = useStyles();
    const onlineDispatch = useOnlineDispatch();
    const user = useSelector((state: GlobalStateType) => state.user);
    const isLoading = useSelector((state: GlobalStateType) => state.loader.data);
    const [message, setMessage] = useState({
        to: [SUPPORT_MAIL],
        from: SUPPORT_MAIL,
        subject: ``,
        text: ``,
    });

    const sendMail = () => {
        if (message.subject && message.text)
            onlineDispatch(
                sendEmailAction({
                    ...message,
                    from: SUPPORT_MAIL,
                    subject: `${message.subject}`,
                    // eslint-disable-next-line no-useless-escape
                    text: `${user?.name}\n${user?.email}\n\n\n${message.text}`,
                }),
            );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleInputChange = (event: any) => {
        setMessage({
            ...message,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Container maxWidth="md">
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <form className={classes.form} noValidate={false}>
                <TextField
                    InputProps={{
                        className: classes.input,
                    }}
                    id="subject"
                    name="subject"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Mail subject"
                    autoFocus
                    onChange={handleInputChange}
                />
                <TextField
                    InputProps={{
                        className: classes.input,
                    }}
                    id="text"
                    multiline
                    rows={4}
                    name="text"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Mail body"
                    autoFocus
                    onChange={handleInputChange}
                />
                <Button
                    disabled={!(message.subject && message.text)}
                    variant="contained"
                    color="primary"
                    onClick={sendMail}
                    className={classes.submit}
                >
                    Send Mail
                </Button>
            </form>
        </Container>
    );
}

export default SendMailForm;

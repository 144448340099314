import React, { ReactNode } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import TocIcon from '@material-ui/icons/Toc';
import { ReactComponent as LifeSaverIcon } from '../assets/svg_icons/LiveSaverIcon.svg';
import { ReactComponent as SnowIcon } from '../assets/svg_icons/MySubscriptionIcon.svg';
import { ReactComponent as SignOutIcon } from '../assets/svg_icons/sign-out-icon.svg';

export type RouteType = {
    title: string;
    href: string;
    icon: ReactNode;
};

export const routes: RouteType[] = [
    { title: 'Home', href: 'home', icon: <HomeIcon fontSize="large" style={{ color: 'white' }} /> },
    { title: 'User Records', href: 'user-details', icon: <TocIcon fontSize="large" style={{ color: 'white' }} /> },
    { title: 'Aura', href: 'aura-scan', icon: <SignOutIcon /> },
    { title: 'Chakra', href: 'chakra-screen', icon: <SignOutIcon /> },
    { title: 'My Account', href: 'my-account', icon: <SnowIcon /> },
    { title: 'Support', href: 'support', icon: <LifeSaverIcon /> },
    { title: 'Log Out', href: 'logout', icon: <SignOutIcon /> },

];
export const auraRoutes: RouteType[] = [
    { title: 'Home', href: 'home', icon: <HomeIcon fontSize="large" style={{ color: 'white' }} /> },
    { title: 'User Records', href: 'user-details', icon: <TocIcon fontSize="large" style={{ color: 'white' }} /> },
    { title: 'Aura', href: 'aura-scan', icon: <SignOutIcon /> },
    { title: 'Chakra', href: 'chakra-screen', icon: <SignOutIcon /> },
    { title: 'My Account', href: 'my-account', icon: <SnowIcon /> },
    { title: 'Support', href: 'support', icon: <LifeSaverIcon /> },
    { title: 'Log Out', href: 'logout', icon: <SignOutIcon /> },
];

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';
import watchSagas from './sagas';

const saga = createSagaMiddleware();

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composedEnhancer = composeWithDevTools(applyMiddleware(saga));
const store = createStore(reducers, {}, composedEnhancer);

saga.run(watchSagas);
export default store;

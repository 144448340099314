import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        zIndex: 1030,
    },
}));

const affirmations = [
    'I Am Divine Love',
    'I Am Divine Light',
    'I Am Divine Health',
    'I Am Divine Peace',
    'I Am Divine Truth',
    'I Easily Manifest Divine Abundance For Myself And Others',
    'The Water I Drink Is Harmonized Perfectly For My Body',
    'I Live In Infinite Love & Gratitude',
    'I Am Divine Joy',
];

const Center = (props: { ingredients: any }) => {
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [value, setValue] = useState(0);
    const { ingredients } = props;
    const [affirmation, setAffirmation] = useState(affirmations[index]);
    const [ingredient, setIngredient] = useState(ingredients[value]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            setIndex((index) => (index < affirmations.length - 1 ? index + 1 : 0));
        }, 7000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAffirmation(affirmations[index]);
    }, [index, affirmation]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setValue((Value) => (Value < ingredients.length - 1 ? Value + 1 : 0));
        }, 4000);

        return () => clearInterval(intervalId);
    }, [ingredients]);

    useEffect(() => {
        setIngredient(ingredients[value]);
    }, [value, ingredients]);
    return (
        <div className={classes.overlay}>
            <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                color="#ecda96"
            >
                <Typography
                    variant="h6"
                    component="h1"
                    align="center"
                    style={{ wordWrap: 'break-word', zIndex: 1030 }}
                >
                    {affirmation}
                </Typography>
            </Box>
            <Box
                height="75%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                color="#ecda96"
                className="scroll-container"
                bottom="0"
                position="absolute"
                width="100%"
                left="0"
            >
                <Typography
                    variant="h6"
                    component="h1"
                    align="center"
                    style={{ wordWrap: 'break-word', zIndex: 1030 }}
                    className="scroll-text"
                >
                    {ingredient}
                </Typography>
            </Box>
        </div>
    );
};
export default Center;

import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import React from 'react';
import { useTranslation } from 'react-i18next';
import BigButton from '../utils/Button/BigButton';

const useStyle = makeStyles<Theme>((theme) => ({
    glassPlaceHolder: {
        height: '324px',
        width: '324px',
        marginTop: '26px',
        marginBottom: '21px',
        backgroundColor: '#f0f5fd',
        borderRadius: '50%',

        // opacity: '32%',
        // background: `url('../assets/images/Ellipse43.svg') no-repeat center fixed;`,
        [theme.breakpoints.down(800)]: {
            height: '324px',
        },
    },
    insidePlaceholder: {
        height: '280px',
        width: '280px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        // background: `url('../assets/images/Ellipse42.svg') no-repeat center fixed;`,
        [theme.breakpoints.down(800)]: {
            height: '280px',
        },
    },
    infoName: {
        margin: '30px',
        fontWeight: 900,
        color: '#0468BF',
        fontSize: 36,
    },

    infoDescription: {
        fontFamily: ['Poppins', 'regular'].join(','),
        marginBottom: '30px',
        color: '#383838',
        fontSize: '16px',
    },
}));

const InfoPanel = ({
    panel,
    validRedirectOnClick,
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    panel: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validRedirectOnClick: any;
}) => {
    const classes = useStyle();
    const { t } = useTranslation(['infoPanel']);

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
        >
            <Grid
                container
                justify="center"
                alignContent="center"
                alignItems="center"
                className={classes.glassPlaceHolder}
            >
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.insidePlaceholder}
                >
                    <Grid item container justify="center" alignContent="center" alignItems="center">
                        <img
                            alt="glass-of-water"
                            style={{
                                height: 73,
                                width: 55,
                                marginTop: '40px',
                                marginBottom: '19px',
                            }}
                            src="../assets/icons/water-glass.svg"
                        />
                        <Typography
                            variant="body1"
                            align="center"
                            className={classes.infoDescription}
                        >
                            {t(`infoPanel:glassPlaceholder`)}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {panel && (
                <Grid container spacing={2} justify="center" direction="column" alignItems="center">
                    <Grid item>
                        {' '}
                        <BigButton
                            title={t('infoPanel:Button')}
                            type="primary"
                            job="button"
                            onClick={validRedirectOnClick}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default InfoPanel;

import { AnyAction } from 'redux';
import * as types from './types';

const INITIAL_STATE = {
    data: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action: AnyAction) => {
    // console.log('reducer network: ', state, action);
    switch (action.type) {
        case types.NETWORK_ONLINE:
            return {
                data: true,
            };
        case types.NETWORK_OFFLINE:
            return {
                data: false,
            };
        default:
            return state;
    }
};

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router'
import { relative } from 'path';
import Panel from './panel';
import { PanelType } from '../../redux/panels/types';

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        padding: 10,
        flexGrow: 1,
        marginTop: 20,
        [theme.breakpoints.up('md')]: {
            marginTop: 35,
            marginLeft: 0,
        },
    },
    card: {
        height: 146,
        width: 146,
        borderRadius: 5,
        cursor: 'pointer',
    },
    name: {
        color: '#0059A6',

        fontSize: 16,
    },
    customGridCon: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 1200,
    }
}));

interface Props {
    panels: PanelType[];
}

const Panels = ({ panels = [] }: Props) => {
    const theme = useTheme();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    panels.sort((n1, n2) => {
        if (n1.id > n2.id) {
            return 1;
        }

        if (n1.id < n2.id) {
            return -1;
        }

        return 0;
    });

    const handleClickAura = () => {
        history.push('/aura-scan');
        console.log('Aura card clicked!');
    };
    const handleClickChakra = () => {
        history.push('/chakra-scan');
        console.log('Aura card clicked!');
    };

    return (
        <>
            <div className={classes.customGridCon}>

                <Grid
                    container
                    className={classes.root}
                    alignContent="center"
                    justify={isMobile ? 'center' : 'flex-start'}
                    spacing={2}
                    wrap="wrap"
                    style={{ marginBottom: 0 }}
                >
                    {panels
                        ?.filter((e) => !!e)
                        .map(({ name, description, logo, id, media, ingredients }: PanelType) => (
                            <Grid item key={id}>
                                {/* <h3>{`${name}:${id}`}</h3> */}
                                <Panel
                                    media={media}
                                    ingredients={ingredients}
                                    name={name}
                                    description={description}
                                    logo={logo}
                                    key={id}
                                    id={id}
                                />{' '}
                            </Grid>
                        ))}
                </Grid>
            </div>
        </>
    );
};

export default Panels;

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_oz3buFTRnFFhfykl9CSnoVw000TPrssaqN',
);

type Props = {
    children: React.ReactNode;
};

const StripeElementsProvider = ({ children }: Props) => {
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeElementsProvider;

import React, { useState } from 'react';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import ForgotPassword from '../../components/Auth/ForgotPassword';
import Background from '../../assets/bg.png';

export default () => {
    const [showPage, setShowPage] = useState(false);
    return (
        <div
            style={{
                minHeight: '100vh',
                height: 'auto',
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
            }}
        >
            <ForgotPassword setShowPage={setShowPage} />
        </div>
    );
};

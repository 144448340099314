import {
    NEW_ERROR_NOTIFICATION,
    CLEAR_ERROR_NOTIFICATION,
    NotificationType,
    ActionType,
} from './types';

const initialState: NotificationType = {};

export default function HeaderReducer(state = initialState, action: ActionType) {
    switch (action.type) {
        case NEW_ERROR_NOTIFICATION:
            return {
                ...action.payload,
            };
        case CLEAR_ERROR_NOTIFICATION:
            return {};
        default:
            return { ...state };
    }
}

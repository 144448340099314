import React, { useState, useEffect, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Button, Grid, makeStyles, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme,
  TextareaAutosize, CircularProgress
} from '@material-ui/core';
import { useHistory } from 'react-router';
import scanImg from '../../assets/scanImg.jpg';
import MainLayout from '../../layout/MainLayout';
import { GlobalStateType } from '../../redux/rootTypes';
import awsconfig from '../../aws-exports';
import { getItem, setItem } from '../../utils/localStorage';
import { USER_KEY } from '../../redux/user/types';
import Background from '../../assets/scanRound.png'
import img1 from '../../assets/aurascreenimg/Aura-Egg__01.png';
import img2 from '../../assets/aurascreenimg/Aura-Egg__02.png';
import img3 from '../../assets/aurascreenimg/Aura-Egg__03.png';
import img4 from '../../assets/aurascreenimg/Aura-Egg__04.png';
import img5 from '../../assets/aurascreenimg/Aura-Egg__05.png';
import img6 from '../../assets/aurascreenimg/Aura-Egg__06.png';
import img7 from '../../assets/aurascreenimg/Aura-Egg__07.png';
import img8 from '../../assets/aurascreenimg/Aura-Egg__08.png';
import img9 from '../../assets/aurascreenimg/Aura-Egg__09.png';
import img10 from '../../assets/aurascreenimg/Aura-Egg__10.png';
import img11 from '../../assets/aurascreenimg/Aura-Egg__11.png';
import img12 from '../../assets/aurascreenimg/Aura-Egg__12.png';
import img13 from '../../assets/aurascreenimg/Aura-Egg__13.png';
import img14 from '../../assets/aurascreenimg/Aura-Egg__14.png';
import img15 from '../../assets/aurascreenimg/Aura-Egg__15.png';
import img16 from '../../assets/aurascreenimg/Aura-Egg__16.png';
import img17 from '../../assets/aurascreenimg/Aura-Egg__17.png';
import img18 from '../../assets/aurascreenimg/Aura-Egg__18.png';
import img19 from '../../assets/aurascreenimg/Aura-Egg__19.png';
import img20 from '../../assets/aurascreenimg/Aura-Egg__20.png';
import img21 from '../../assets/aurascreenimg/Aura-Egg__21.png';
import img22 from '../../assets/aurascreenimg/Aura-Egg__22.png';
import img23 from '../../assets/aurascreenimg/Aura-Egg__23.png';
import img24 from '../../assets/aurascreenimg/Aura-Egg__24.png';
import img25 from '../../assets/aurascreenimg/Aura-Egg__25.png';
import img26 from '../../assets/aurascreenimg/Aura-Egg__26.png';
import img27 from '../../assets/aurascreenimg/Aura-Egg__27.png';
import img28 from '../../assets/aurascreenimg/Aura-Egg__28.png';
import img29 from '../../assets/aurascreenimg/Aura-Egg__29.png';
import img30 from '../../assets/aurascreenimg/Aura-Egg__30.png';
import img31 from '../../assets/aurascreenimg/Aura-Egg__31.png';
import img32 from '../../assets/aurascreenimg/Aura-Egg__32.png';
import img33 from '../../assets/aurascreenimg/Aura-Egg__33.png';
import img34 from '../../assets/aurascreenimg/Aura-Egg__34.png';
import img35 from '../../assets/aurascreenimg/Aura-Egg__35.png';
import img36 from '../../assets/aurascreenimg/Aura-Egg__36.png';
import img37 from '../../assets/aurascreenimg/Aura-Egg__37.png';
import img38 from '../../assets/aurascreenimg/Aura-Egg__38.png';
import img39 from '../../assets/aurascreenimg/Aura-Egg__39.png';
import img40 from '../../assets/aurascreenimg/Aura-Egg__40.png';
import img41 from '../../assets/aurascreenimg/Aura-Egg__41.png';
import img42 from '../../assets/aurascreenimg/Aura-Egg__42.png';
import img43 from '../../assets/aurascreenimg/Aura-Egg__43.png';
import img44 from '../../assets/aurascreenimg/Aura-Egg__44.png';
import img45 from '../../assets/aurascreenimg/Aura-Egg__45.png';
import img46 from '../../assets/aurascreenimg/Aura-Egg__46.png';
import img47 from '../../assets/aurascreenimg/Aura-Egg__47.png';
import img48 from '../../assets/aurascreenimg/Aura-Egg__48.png';
import img49 from '../../assets/aurascreenimg/Aura-Egg__49.png';
import img50 from '../../assets/aurascreenimg/Aura-Egg__50.png';
import img51 from '../../assets/aurascreenimg/Aura-Egg__51.png';
import img52 from '../../assets/aurascreenimg/Aura-Egg__52.png';
import img53 from '../../assets/aurascreenimg/Aura-Egg__53.png';
import img54 from '../../assets/aurascreenimg/Aura-Egg__54.png';
import img55 from '../../assets/aurascreenimg/Aura-Egg__55.png';
import img56 from '../../assets/aurascreenimg/Aura-Egg__56.png';
import img57 from '../../assets/aurascreenimg/Aura-Egg__57.png';
import img58 from '../../assets/aurascreenimg/Aura-Egg__58.png';
import img59 from '../../assets/aurascreenimg/Aura-Egg__59.png';
import img60 from '../../assets/aurascreenimg/Aura-Egg__60.png';
import img61 from '../../assets/aurascreenimg/Aura-Egg__61.png';
import img62 from '../../assets/aurascreenimg/Aura-Egg__62.png';
import img63 from '../../assets/aurascreenimg/Aura-Egg__63.png';
import img64 from '../../assets/aurascreenimg/Aura-Egg__64.png';
import img65 from '../../assets/aurascreenimg/Aura-Egg__65.png';
import img66 from '../../assets/aurascreenimg/Aura-Egg__66.png';
import img67 from '../../assets/aurascreenimg/Aura-Egg__67.png';
import img68 from '../../assets/aurascreenimg/Aura-Egg__68.png';
import img69 from '../../assets/aurascreenimg/Aura-Egg__69.png';
import img70 from '../../assets/aurascreenimg/Aura-Egg__70.png';
import img71 from '../../assets/aurascreenimg/Aura-Egg__71.png';
import img72 from '../../assets/aurascreenimg/Aura-Egg__72.png';
import img73 from '../../assets/aurascreenimg/Aura-Egg__73.png';
import img74 from '../../assets/aurascreenimg/Aura-Egg__74.png';
import img75 from '../../assets/aurascreenimg/Aura-Egg__75.png';
import img76 from '../../assets/aurascreenimg/Aura-Egg__76.png';
import img77 from '../../assets/aurascreenimg/Aura-Egg__77.png';
import img78 from '../../assets/aurascreenimg/Aura-Egg__78.png';
import img79 from '../../assets/aurascreenimg/Aura-Egg__79.png';
import img80 from '../../assets/aurascreenimg/Aura-Egg__80.png';
import img81 from '../../assets/aurascreenimg/Aura-Egg__81.png';
import img82 from '../../assets/aurascreenimg/Aura-Egg__82.png';
import img83 from '../../assets/aurascreenimg/Aura-Egg__83.png';
import img84 from '../../assets/aurascreenimg/Aura-Egg__84.png';
import img85 from '../../assets/aurascreenimg/Aura-Egg__85.png';
import img86 from '../../assets/aurascreenimg/Aura-Egg__86.png';
import img87 from '../../assets/aurascreenimg/Aura-Egg__87.png';
import img88 from '../../assets/aurascreenimg/Aura-Egg__88.png';
import img89 from '../../assets/aurascreenimg/Aura-Egg__89.png';
import img90 from '../../assets/aurascreenimg/Aura-Egg__90.png';
import img91 from '../../assets/aurascreenimg/Aura-Egg__91.png';
import img92 from '../../assets/aurascreenimg/Aura-Egg__92.png';
import img93 from '../../assets/aurascreenimg/Aura-Egg__93.png';
import img94 from '../../assets/aurascreenimg/Aura-Egg__94.png';
import img95 from '../../assets/aurascreenimg/Aura-Egg__95.png';
import img96 from '../../assets/aurascreenimg/Aura-Egg__96.png';
import img97 from '../../assets/aurascreenimg/Aura-Egg__97.png';
import img98 from '../../assets/aurascreenimg/Aura-Egg__98.png';

// import useOnlineDispatch from '../OnlineDispatch.js';
Storage.configure(awsconfig);
const useStyle = makeStyles<Theme>((theme) => ({
  rootLinear: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  bg: {
    background: `url('../../assets/demoImg/yoga.jpg')`,
    backgroundSize: 'cover',
    minHeight: 'calc(100vh - 200px)',
    height: 'auto',
    width: '100vw',
    marginRight: '-20px !important',
    marginLeft: '-20px !important',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  chakraScan: {
    background: "url(assets/bg.png)",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scanButton: {
    backgroundColor: '#f10 !important',
    fontWeight: 'bold',
    minWidth: '100% !important',
    margin: '20px auto !important',
    display: 'block !important',
    color: '#fff !important',
    height: 'auto !important',
    borderRadius: '5px !important',
    padding: '10px 20px 10px 20px !important',
    border: '1px solid #747474 !important',
  },
  wrap: {
    margin: '0px auto',
    width: '50%',
  },
  scanHidden: {
    display: 'none'
  },
  pageTitle: {
    color: '#ffffff',
    position: 'absolute',
    left: '0',
    top: '10px',
    width: '100%',
    backgroundColor: '#000a',
    margin: '0',
    borderTop: '1px solid #fff',
    borderBottom: '2px solid #fff',
    padding: '10px',
    textAlign: 'center',
    boxSizing: 'border-box',
    zIndex: '99',
    textTransform: 'uppercase',
  }
}));

function getCookie(name) {
  const cookieValue = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(`${name}=`));
  let myCookie = '';
  if (cookieValue) {
    myCookie = cookieValue.replace('ScanImage=', '');
  }
  return myCookie;
};

const AuraScreen = () => {
  const historty = useHistory();
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [successClass, setSuccessClass] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  // const [pic, setPic] = useState();
  const [ks, setKs] = useState(0);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Assuming your images are in the public folder
  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const user = useSelector((state: GlobalStateType) => state.user);
  const [localImage, setLocalImage] = useState<any>(null);
  useEffect(() => {
    const getUserAvatar = async () => {
      const localUser = getItem(USER_KEY);
      if (user.hasAvatar)
        try {
          const avatarList = await Storage.list(`avatars/${user.sub}`);
          const sortedAvatars = avatarList.sort((a, b) => b.lastModified - a.lastModified);
          if (sortedAvatars.length > 0) {
            const mostRecentAvatarKey = sortedAvatars[0].key;
            const mostRecentAvatar = await Storage.get(mostRecentAvatarKey);
            console.log('Most recent avatarooooo:', mostRecentAvatar);
            setLocalImage(mostRecentAvatar);
          } else {
            console.log('No avatars found.');
          }
        } catch (error) {
          console.info('Not avatar found yet', error);
        }
    };
    getUserAvatar();
    setOpen(true);
  }, [user]);
  let pic = getCookie('ScanImage') ? getCookie('ScanImage') : null;
  if (!pic) {
    console.log('myCookie value:', pic);
    pic = localImage;
  }
  const [images] = useState([img1, img2, img3, img4,
    img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42, img43, img44, img45, img46, img47, img48, img49, img50, img51, img52, img53, img54, img55, img56, img57, img58, img59, img60, img61, img62, img63, img64, img65, img66, img67, img68, img69, img70, img71, img72, img73, img74, img75, img76, img77, img78, img79, img80, img81, img82, img83, img84, img85, img86, img87, img88, img89, img90, img91, img92, img93, img94, img95, img96, img97, img98]);
  const MAX_RANDOM_NUMBERS = 98;
  const MIN_NUMBER = 55;
  const MAX_NUMBER = 85;
  const currentDate = new Date();
  const timestamp = currentDate.getTime();

  const getRandomImageIndex = useCallback(() => {
    return Math.floor(Math.random((new Date().getSeconds() * new Date().getMinutes()), MAX_NUMBER) * images.length);
  }, [images]);
  const [currentImageIndex, setCurrentImageIndex] = useState(getRandomImageIndex());
  useEffect(() => {
    if (successClass && !openLoader) {
      setCurrentImageIndex(getRandomImageIndex());
    }
  }, [successClass, openLoader, images, getRandomImageIndex]);

  const [randomNumbers, setRandomNumbers] = useState([]);
  const generateUniqueRandomNumber = () => {
    let newRandomNumber;
    do {
      const ksn = Math.random() * MAX_RANDOM_NUMBERS
      newRandomNumber = Math.floor(Math.random(ksn) * MAX_RANDOM_NUMBERS);
    } while (randomNumbers.includes(newRandomNumber));
    setRandomNumbers((prevNumbers) => [...prevNumbers, newRandomNumber]);
  };
  const handleClickScan = () => {
    setOpenLoader(true);
    generateUniqueRandomNumber();
    setTimeout(() => {
      setCurrentImageIndex(randomNumbers[randomNumbers.length - 1]);
      setOpenLoader(false);
      setSuccessClass(true);
    }, 6000);
  };
  if (randomNumbers.length === MAX_RANDOM_NUMBERS) {
    setRandomNumbers([]);
  }
  const handleClickPDF = () => {
    setOpenPDF(true);
  };
  const handleClosePDF = () => {
    setOpenPDF(false);
  };
  const handleOpenUserRecorde = () => {
    historty.push('/home');
  }
  return (
    <div>
      <MainLayout>
        <div className={classes.chakraScan} style={{ width: "100vw", margin: "0 -20px" }}>
          <div style={{
            width: '400px', maxWidth: '100%',
            backgroundImage: successClass && !openLoader
              ? `url(${process.env.PUBLIC_URL}${images[currentImageIndex]})`
              : `url(${Background})`,
          }} className='bg_screen' >
            <h2 className={classes.pageTitle}>Aura<br />Screen</h2>
            <div className='scan-img-wrap'>
              {pic
                ? (<img src={pic} alt="" className={`${openLoader && "fadeinout"}`} />)
                : (<p className={`${openLoader ? '' : classes.scanHidden} ${openLoader && "fadeinout"}`}>Scanning...</p>)
              }
            </div>
            <div className='scan-btn-wrap'>
              {!successClass ?
                <Button className={classes.scanButton} onClick={handleClickScan}> Scan </Button>
                :
                // <Button className={classes.scanButton} onClick={handleClickScan}> Scan </Button>
                <Button className={classes.scanButton} onClick={handleOpenUserRecorde}> Done </Button>
              }
            </div>
            <div className='pdf-btn-wrap'>
              <Button className={classes.pdf} onClick={handleClickPDF}> ? </Button>
            </div>
          </div>
        </div>
      </MainLayout>
      <Dialog
        open={openLoader}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description1"
      >
        <DialogContent style={{ backgroundColor: "#0d3c61" }}>
          <DialogContentText id="alert-dialog-description1" style={{ color: "#fff" }}>
            EnergyScan in progress
            <div className='loader-wrap'>
              <CircularProgress />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={openPDF} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description2" className='custom-pop-up'>
        <DialogContent style={{ backgroundColor: "#0d3c61" }}>
          <DialogContentText id="alert-dialog-description2" style={{ color: "#fff" }}>
            <iframe title='Aura Scaner' src="../assets/Aura_Scan.pdf" width="100%" height="500px" />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "#0d3c61" }}>
          <Button onClick={handleClosePDF} variant="contained"> Ok </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AuraScreen;

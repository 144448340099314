import {
    LIST_MEDIA,
    LIST_MEDIA_SUCCEEDED,
    LIST_MEDIA_FAILED,
    GET_MEDIA,
    SET_MEDIA_DOWNLOADING,
    GET_MEDIA_SUCCEEDED,
    GET_MEDIA_FAILED,
    ErrorType,
    MediaType,
    GET_MEDIA_URL,
    GET_MEDIA_URL_SUCCEEDED,
    GET_MEDIA_URL_FAILED,
} from './types';

export const getMediaAction = (payload: MediaType) => ({
    type: GET_MEDIA,
    payload,
});

export const setMediaDownloadingAction = (payload: { media: MediaType }) => ({
    type: SET_MEDIA_DOWNLOADING,
    payload,
});

export const getMediaSuccessAction = (payload: { media: MediaType }) => ({
    type: GET_MEDIA_SUCCEEDED,
    payload,
});

export const getMediaFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_MEDIA_FAILED,
    payload,
});

export const getSignedURLAction = (payload: { media: MediaType }) => ({
    type: GET_MEDIA_URL,
    payload,
});

export const getSignedURLSuccessAction = (payload: { media: MediaType }) => ({
    type: GET_MEDIA_URL_SUCCEEDED,
    payload,
});

export const getSignedURLFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_MEDIA_URL_FAILED,
    payload,
});

export const listMediaAction = () => ({
    type: LIST_MEDIA,
});

export const listMediaSuccessAction = (payload: { medias: MediaType[] }) => ({
    type: LIST_MEDIA_SUCCEEDED,
    payload,
});

export const listMediaFailedAction = (payload: { error: ErrorType }) => ({
    type: LIST_MEDIA_FAILED,
    payload,
});

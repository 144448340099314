import {
    GET_PRICE,
    GET_PRICE_SUCCEEDED,
    GET_PRICE_FAILED,
    ErrorType,
    PriceType,
    PricesType,
    LIST_PRICE_SUCCEEDED,
    LIST_PRICE_FAILED,
    LIST_PRICE,
    LOADING_PRICES,
    SET_SELECTED_PRICE,
    SET_SELECTED_PRICE_ERROR,
} from './types';

export const getPriceAction = (payload: { productID?: string; priceID?: string }) => ({
    type: GET_PRICE,
    payload,
});

export const getPriceSuccessAction = (payload: { price: PriceType }) => ({
    type: GET_PRICE_SUCCEEDED,
    payload,
});

export const getPriceFailedAction = (payload: { error: ErrorType }) => ({
    type: GET_PRICE_FAILED,
    payload,
});

export const listPriceAction = (payload: { productID: string }) => ({
    type: LIST_PRICE,
    payload,
});

export const listPriceSuccessAction = (payload: PricesType) => ({
    type: LIST_PRICE_SUCCEEDED,
    payload,
});

export const listPriceFailedAction = (payload: { error: ErrorType }) => ({
    type: LIST_PRICE_FAILED,
    payload,
});

export const pricesLoadingAction = (payload: boolean) => ({
    type: LOADING_PRICES,
    payload,
});

export const setSelectedPriceAction = (payload: { price: PriceType }) => ({
    type: SET_SELECTED_PRICE,
    payload,
});

export const setSelectedPriceErrorAction = (payload: { error: ErrorType }) => ({
    type: SET_SELECTED_PRICE_ERROR,
    payload,
});

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import es from './es';

export const resources = {
    en,
    es,
} as const;

i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'es'],
    ns: ['header', 'test', 'infoPanel', 'sidemenu', 'translation'],
    resources,
});

export default i18n;

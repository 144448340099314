export interface ErrorType {
    code?: number;
    message?: string;
}

export interface ActionType {
    type: string;
    payload: {
        prices?: PriceType[];
        price?: PriceType;
        error?: ErrorType;
        loading: boolean;
    };
}

export interface Recurring {
    aggregate_usage: string | null;
    interval: string;
    interval_count: number;
    usage_type: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PriceType {
    id: string;
    object?: string;
    active?: boolean;
    billing_scheme?: string;
    created?: number;
    currency?: string;
    livemode?: boolean;
    lookup_key?: string | null;
    metadata?: Record<string, unknown>;
    nickname?: string | null;
    product?: string;
    recurring?: Recurring;
    tiers_mode?: string | null;
    transform_quantity?: string | null;
    type?: string;
    unit_amount?: number;
    unit_amount_decimal?: string;
}

export interface PricesType {
    prices: PriceType[];
}
// Replace with your PRICE types
export const GET_PRICE = 'GET_PRICE';
export const GET_PRICE_SUCCEEDED = 'GET_PRICE_SUCCEEDED';
export const GET_PRICE_FAILED = 'GET_PRICE_FAILED';

export const LIST_PRICE = 'LIST_PRICE';
export const LIST_PRICE_SUCCEEDED = 'LIST_PRICE_SUCCEEDED';
export const LIST_PRICE_FAILED = 'LIST_PRICE_FAILED';
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY';

export const LOADING_PRICES = 'LOADING_PRICES';
export const SET_SELECTED_PRICE = 'SET_SELECTED_PRICE';
export const SET_SELECTED_PRICE_ERROR = 'SET_SELECTED_PRICE_ERROR';

export const PRICES_KEY = 'PRICES_KEY';

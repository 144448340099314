import {
    SEND_EMAIL,
    SEND_EMAIL_SUCCEEDED,
    SEND_EMAIL_FAILED,
    ErrorType,
    MailMessageType,
    SET_EMAIL_SENDING_STATUS,
} from './types';

export const sendEmailAction = (payload: MailMessageType) => ({
    type: SEND_EMAIL,
    payload,
});

export const sendEmailSuccessAction = (payload: { response: string }) => ({
    type: SEND_EMAIL_SUCCEEDED,
    payload,
});

export const sendEmailFailedAction = (payload: { error: ErrorType }) => ({
    type: SEND_EMAIL_FAILED,
    payload,
});

export const setEmailSendingStatusAction = (payload: { email: MailMessageType }) => ({
    type: SET_EMAIL_SENDING_STATUS,
    payload,
});
